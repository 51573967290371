import { GroupMembership, GroupMembershipRole } from '../store/chat-threads/chat-threads.props'

export const getGroupMembership = (
    groupMembers: GroupMembership[] | undefined,
    sessionUsername: string | undefined
): GroupMembership | null => {
    if (!sessionUsername || !groupMembers) return null

    return groupMembers.find((membership) => membership.member.username === sessionUsername) || null
}

export const getGroupMembershipRole = (
    groupMembers: GroupMembership[] | undefined,
    sessionUsername: string | undefined
): GroupMembershipRole | null => {
    if (!sessionUsername || !groupMembers) return null

    const role = groupMembers.find((membership) => membership.member.username === sessionUsername)

    return role ? role.state : null
}

export const getReadableOwnGroupMembershipRole = (
    membershipRole: GroupMembershipRole | null
): string => {
    switch (membershipRole) {
        case 'admin':
            return 'Du bist Gründer:in'
        case 'member':
            return 'Du bist Mitglied'
        case 'requested':
            return 'Du hast angefragt'
        case 'invited':
            return 'Du bist eingeladen'
        default:
            return 'Nicht Mitglied'
    }
}

export const getReadableGroupMembershipRole = (
    membershipRole: GroupMembershipRole | null
): string => {
    switch (membershipRole) {
        case 'admin':
            return 'Gründer:in'
        case 'member':
            return 'Mitglied'
        case 'requested':
            return 'Angefragt'
        case 'invited':
            return 'Eingeladen'
        default:
            return 'Nicht Mitglied'
    }
}
