import React, { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import useCaptcha from '../../hooks/use-captcha'
import useSeo from '../../hooks/use-seo'
import useSetParentLink from '../../hooks/use-set-parent-link'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { defaultInputFieldAttributes, getUnsanitizedPageText } from '../../utils/configs'
import { SLUG_DATENSCHUTZ } from '../../utils/pages'
import { scrollToTop } from '../../utils/pages'
import { postSchoolboxForm } from './schoolbox-form.actions'

const SchoolboxForm: FC = () => {
    useSetParentLink('/weiteres')
    const [captchaToken, captchaImageUrl, fetchCaptcha] = useCaptcha()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_SCHOOLBOX_FORM)
    )
    const textBlockSuccess = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_SCHOOLBOX_FORM_SUCCESS)
    )

    const [formState, setFormState] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [notes, setNotes] = useState<string>(' ')
    const [institution, setInstitution] = useState<string>('')
    const [street, setStreet] = useState<string>('')
    const [zip, setZip] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [datenschutz, setDatenschutz] = useState<boolean>(false)
    const [captchaCode, setCaptchaCode] = useState<string>('')

    // Update canonical link
    useSeo({
        title: 'Schulbox Depression bestellen',
        metaTags: [],
        slug: '/schule/bestellung-schulbox',
    })

    const reloadCaptcha = () =>
        fetchCaptcha().then(() => {
            setCaptchaCode('')
        })

    const onChangeFirstName = (ev: ChangeEvent<HTMLInputElement>) => {
        setFirstName(ev.currentTarget.value)
    }

    const onChangeLastName = (ev: ChangeEvent<HTMLInputElement>) => {
        setLastName(ev.currentTarget.value)
    }

    const onChangeStreet = (ev: ChangeEvent<HTMLInputElement>) => {
        setStreet(ev.currentTarget.value)
    }

    const onChangeZip = (ev: ChangeEvent<HTMLInputElement>) => {
        setZip(ev.currentTarget.value)
    }

    const onChangeCity = (ev: ChangeEvent<HTMLInputElement>) => {
        setCity(ev.currentTarget.value)
    }

    const onChangeEmail = (ev: ChangeEvent<HTMLInputElement>) => {
        setEmail(ev.currentTarget.value)
    }

    const onChangeNotes = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(ev.currentTarget.value)
    }

    const onChangeInstitution = (ev: ChangeEvent<HTMLInputElement>) => {
        setInstitution(ev.currentTarget.value)
    }

    const onChangeDatenschutz = (ev: ChangeEvent<HTMLInputElement>) => {
        setDatenschutz(ev.currentTarget.checked)
    }

    const onChangeCaptchaCode = (ev: ChangeEvent<HTMLInputElement>) => {
        setCaptchaCode(ev.target.value)
    }

    const onClickReloadCaptcha = (ev: MouseEvent) => {
        reloadCaptcha()
        ev.preventDefault()
    }

    const onSubmit = (ev: MouseEvent) => {
        if (email === '' || email.indexOf('@') === -1) {
            setFormState('Das Feld "E-Mail" muss im richtigen Format ausgefüllt sein!')
        } else if (captchaCode === '') {
            setFormState('Bitte löse das Captcha!')
        } else if (!datenschutz) {
            setFormState('Bitte akzeptiere die Datenschutzerklärung!')
        } else if (
            firstName !== '' &&
            lastName !== '' &&
            email !== '' &&
            street !== '' &&
            city !== '' &&
            captchaCode !== ''
        ) {
            postSchoolboxForm({
                firstName,
                lastName,
                email,
                notes,
                institution,
                streetAndNumber: street,
                zip,
                city,
                captcha: {
                    token: captchaToken as string,
                    phrase: captchaCode as string,
                },
            })
                .then(() => {
                    setFormState('__DONE__')
                    scrollToTop()
                })
                .catch((error) => {
                    if (error.error && error.error.code && error.error.code === 400) {
                        reloadCaptcha()
                        setFormState(
                            `Der eingegebene Sicherheitscode ist ungültig. Bitte erneut probieren.`
                        )
                    } else {
                        setFormState(`Konnte Anfrage nicht versenden. Fehler: ${error.message}`)
                    }
                })
        } else {
            setFormState('Alle Pflichtfelder (*) müssen ausgefüllt sein!')
        }

        ev.preventDefault()
    }

    if (formState === '__DONE__') {
        return (
            <div className="page page--contact-form">
                <div className="page-header">
                    <h1 className="title-black">Anfrage für Schulbox Depression versendet.</h1>
                    <p
                        className="text-black"
                        dangerouslySetInnerHTML={getUnsanitizedPageText(textBlockSuccess?.text)}
                    ></p>
                    <br />
                    <p>
                        <Link to="/" className="link-blue">
                            Zurück zur Startseite
                        </Link>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="page page--schoolbox-form">
            <div className="page-header">
                <h1 className="title-black">Schulbox Depression bestellen</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <form className="schoolbox-form page-header" method="POST" action="">
                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__institution">
                        Name der Institution, Schule oder Verein
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__institution"
                        name="schoolbox-form__institution"
                        required
                        value={institution}
                        onChange={onChangeInstitution}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__street">
                        Straße & Hausnummer*
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__street"
                        name="schoolbox-form__street"
                        required
                        value={street}
                        onChange={onChangeStreet}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__zip">
                        PLZ*
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__zip"
                        name="schoolbox-form__zip"
                        required
                        value={zip}
                        onChange={onChangeZip}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__city">
                        Ort*
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__city"
                        name="schoolbox-form__city"
                        required
                        value={city}
                        onChange={onChangeCity}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__firstname">
                        Vorname*
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__firstname"
                        name="schoolbox-form__firstname"
                        required
                        value={firstName}
                        onChange={onChangeFirstName}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__lastname">
                        Nachname*
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__lastname"
                        name="schoolbox-form__lastame"
                        required
                        value={lastName}
                        onChange={onChangeLastName}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="schoolbox-form__email">
                        E-Mail*
                    </label>
                    <input
                        type="text"
                        id="schoolbox-form__email"
                        name="schoolbox-form__email"
                        value={email}
                        onChange={onChangeEmail}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="chat-thread-form__text">
                        Anmerkungen
                    </label>
                    <textarea
                        id="chat-thread-form__text"
                        name="chat-thread-form__text"
                        value={notes}
                        onChange={onChangeNotes}
                    ></textarea>
                </div>

                <label className="checkbox-wrapper">
                    <input
                        value={datenschutz ? 'true' : 'false'}
                        onChange={onChangeDatenschutz}
                        type="checkbox"
                        required
                    />
                    <div className="checkmark"></div>
                    <span className="checkbox-text">
                        Ich habe die{' '}
                        <Link to={SLUG_DATENSCHUTZ} className="text-underline">
                            Datenschutzerklärung
                        </Link>{' '}
                        gelesen und bin damit einverstanden.*
                    </span>
                </label>

                <div className="input-wrapper captcha-wrapper">
                    <div className="captcha-field">
                        <img
                            alt="Lade Captcha..."
                            src={captchaImageUrl || ''}
                            width="800"
                            height="200"
                        />

                        <button
                            title="Neues Captcha laden"
                            onClick={onClickReloadCaptcha}
                            type="button"
                        ></button>
                    </div>

                    <input
                        type="text"
                        id="contact-form__captcha"
                        name="contact-form__captcha"
                        value={captchaCode}
                        required={true}
                        placeholder={`Code`}
                        onChange={onChangeCaptchaCode}
                    />
                </div>

                {formState !== '' && <p className="form-error input-wrapper">{formState}</p>}

                <div className="form__buttons">
                    <button type="submit" onClick={onSubmit} className="button button-submit">
                        Schulbox Depression bestellen
                    </button>
                </div>

                <p>Felder mit einem * müssen ausgefüllt werden.</p>
            </form>
        </div>
    )
}

export default SchoolboxForm
