import { Captcha } from '../../hooks/use-captcha'
import { getApiUrl, handleFetchErrorAsObject } from '../../utils/api'

export interface ContactFormRequest {
    firstName: string
    lastName: string
    email: string
    text: string
    captcha: Captcha
}

export const postContactMessage = (body: ContactFormRequest): Promise<any> =>
    fetch(`${getApiUrl()}/contact-messages`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleFetchErrorAsObject)
        .then((json) => json)
        .catch((error) => Promise.reject(error))
