import React, { FC } from 'react'

import { IEmergencyKitItem } from '../../../store/emergency-kit/emergency-kit.props'
import EmergencyKitListItem from '../emergency-kit-list-item'

interface IEmergencyKitList {
    isEditMode: boolean
    emergencyKitItems: IEmergencyKitItem[]
}

const EmergencyKitList: FC<IEmergencyKitList> = ({ isEditMode, emergencyKitItems }) => (
    <div className="component--emergency-kit-list">
        {emergencyKitItems.map((emergencyKitItem) => (
            <EmergencyKitListItem
                key={emergencyKitItem.id}
                item={emergencyKitItem}
                isEditMode={isEditMode}
            />
        ))}
    </div>
)

export default EmergencyKitList
