import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react'

import { defaultInputFieldAttributes } from '../../../utils/configs'
import { isDesktop } from '../../../utils/viewport'
import { ChatbotTextInputProps } from './chatbot-text-input.props'

const ChatbotTextInput: FC<ChatbotTextInputProps> = ({
    isPasswordItem = false,
    submitAction = undefined,
}) => {
    const inputField = useRef<HTMLInputElement>(null)
    const [answer, setAnswer] = useState<string>('')

    const onChangeAnswer = (ev: ChangeEvent<HTMLInputElement>) => {
        setAnswer(ev.currentTarget.value)
    }

    useEffect(() => {
        if (isDesktop() && inputField.current) {
            // Autofocus
            inputField.current.focus({ preventScroll: true })
        }
    }, [])

    const onSubmit = (ev: FormEvent) => {
        if (submitAction) {
            submitAction(answer)
        }

        // @todo On mobile, adding an item e.g. via "setInvalidAnswer"
        // should unfocus this field so that it is visible all the time
        // if (inputField.current) {
        //     inputField.current.blur()
        // }

        setAnswer('')
        ev.preventDefault()
    }

    return (
        <div className="component--chatbot-text-input chat-input">
            <form method="POST" action="#" onSubmit={onSubmit}>
                <input
                    type={isPasswordItem ? 'password' : 'text'}
                    className="input"
                    id="chatbot-text-input"
                    name="chatbot-text-input"
                    ref={inputField}
                    value={answer}
                    onChange={onChangeAnswer}
                    placeholder="wählen oder eingeben"
                    {...defaultInputFieldAttributes}
                />
                <button
                    className="button-send"
                    type="submit"
                    title="Eingabe senden (enter)"
                    aria-label="Eingabe senden"
                ></button>
            </form>
        </div>
    )
}

export default ChatbotTextInput
