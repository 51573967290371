import React, { FC, MouseEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SmoothScroll from 'smooth-scroll'

import { IMenuPage, IMenuType } from '../../../store/menu-pages/menu-pages.props'
import { getMenuByName } from '../../../store/menu-pages/menu-pages.selectors'
import { GlobalState } from '../../../store/state.props'
import { showKlaroSettings } from '../../../utils/consent-management'
import { getMenuPageLink } from '../../../utils/pages'
import InstaBanner from '../insta-banner'

const Footer: FC = () => {
    const menuLinks = useSelector((state: GlobalState) => getMenuByName(state, IMenuType.FOOTER))

    useEffect(() => {
        const scroll = new SmoothScroll('a[href*="#root"],a[href*="#main-content"]')
    }, [])

    const onClickPrivacySettings = (ev: MouseEvent) => {
        ev.preventDefault()
        showKlaroSettings()
    }

    return (
        <footer className="footer">
            <InstaBanner />
            <nav className="footer__menu" aria-label="nav-footer">
                <ul>
                    {menuLinks?.pages.map((page: IMenuPage, key: number) => (
                        <li key={key}>
                            <Link
                                to={getMenuPageLink(page)}
                                title={`Zur Inhaltsseite "${page.linkTitle}"`}
                            >
                                {page.linkTitle}
                            </Link>
                        </li>
                    ))}
                    <li>
                        <a
                            href="#"
                            title="Datenschutzeinstellungen ändern"
                            onClick={onClickPrivacySettings}
                        >
                            Datenschutzeinstellungen
                        </a>
                    </li>
                </ul>
                <a
                    href="/"
                    className="footer__logo"
                    title="FIDEO Startseite anzeigen"
                    aria-label="FIDEO Startseite anzeigen"
                ></a>
                <a href="#root" className="to-top-button" title="Zum Seitenanfang navigieren"></a>
            </nav>
            <div className="footer__sponsors">
                <p>FIDEO wird unterstützt von:</p>
                <ul>
                    <li>
                        <div className="logo-barmer" title="Barmer">
                            Barmer
                        </div>
                    </li>
                    <li>
                        <a
                            href="https://www.deutsche-depressionshilfe.de/"
                            target="_blank"
                            className="logo-ddh"
                            rel="noreferrer"
                            title="Stiftung Deutsche Depressionshilfe und Suizidprävention besuchen (externer Link)"
                        >
                            Stiftung Deutsche Depressionshilfe und Suizidprävention
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
