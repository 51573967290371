import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSeo from '../../hooks/use-seo'
import useSetParentLink from '../../hooks/use-set-parent-link'
import { fetchPage } from '../../store/menu-pages/menu-pages.actions'
import { getPage } from '../../store/menu-pages/menu-pages.selectors'
import { GlobalState } from '../../store/state.props'
import { getUnsanitizedPageText } from '../../utils/configs'

// Page with dynamic content from backend
// either called via URL-slug or "seiten/:id"-ID-param
const ContentPage: FC = () => {
    document.body.setAttribute('data-page', 'seiten/')
    useSetParentLink('/')
    const params: any = useParams()
    const needle: number | string =
        typeof params.id !== 'undefined'
            ? Number(params.id)
            : typeof params['*'] !== 'undefined'
            ? '/' + params['*']
            : params[0]

    const navigate = useNavigate()
    const dispatch = useFideoDispatch()
    const page = useSelector((state: GlobalState) => getPage(state, needle))

    useEffect(() => {
        if (!page) {
            dispatch(fetchPage(needle)).catch(() => {
                navigate('/404')
            })
        }
    }, [needle])

    useEffect(() => {
        const title = document.querySelector('.titlebar__title')
        if (title && page) {
            title.innerHTML = page.title
        }
    }, [page])

    useSeo(page?.seo)

    if (!needle) {
        navigate('/404')
        return null
    }

    return (
        <div className="page page--content-page">
            <div className="page-header">
                <h1 className="title-black">{page?.title}</h1>
                {!page && <Loading />}
                <div dangerouslySetInnerHTML={getUnsanitizedPageText(page?.text)}></div>
            </div>
        </div>
    )
}

export default ContentPage
