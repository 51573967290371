import { AnyAction } from 'redux'

import { RESET_SESSION } from '../user/user.actions'
import {
    ADD_CHAT_POST,
    ADD_CHAT_POSTS,
    FETCH_CHAT_POSTS_ERROR,
    FETCH_CHAT_POSTS_PENDING,
    PATCH_CHAT_POST,
    RESET_CHAT_POSTS,
    UPDATE_POSTS_PAGINATION,
} from './chat-posts.actions'
import { ChatPost, ChatPostsState } from './chat-posts.props'

const initialState: ChatPostsState = {
    data: [],
    isPending: false,
    pagination: {
        nextPage: 0,
    },
}

const ChatPostsReducer = (
    state: ChatPostsState = initialState,
    action: AnyAction
): ChatPostsState => {
    switch (action.type) {
        case FETCH_CHAT_POSTS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case FETCH_CHAT_POSTS_ERROR:
            return {
                ...state,
                isPending: false,
            }
        case ADD_CHAT_POST:
            return {
                ...state,
                isPending: false,
                data: [...state.data, action.chatPost],
            }
        case ADD_CHAT_POSTS:
            return {
                ...state,
                isPending: false,
                data: [
                    // Merge or add data to the store
                    ...state.data.map(
                        (chatPost) =>
                            action.chatPosts.data.find(
                                (_chatPost: ChatPost) => _chatPost.id === chatPost.id
                            ) || chatPost
                    ),
                    ...action.chatPosts.data.filter(
                        (chatPost: ChatPost) =>
                            state.data.find(
                                (_chatPost: ChatPost) => _chatPost.id === chatPost.id
                            ) === undefined
                    ),
                ],
            }
        case RESET_CHAT_POSTS:
            return {
                ...state,
                isPending: false,
                data: action.chatPosts.data,
            }
        case UPDATE_POSTS_PAGINATION:
            return {
                ...state,
                pagination: {
                    // nextPage may be undefined!
                    nextPage: action.pagination.next,
                },
            }
        case PATCH_CHAT_POST:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatPost) =>
                    chatPost.id === action.chatPost.data.id
                        ? { ...chatPost, ...action.chatPost.data }
                        : chatPost
                ),
            }
        case RESET_SESSION:
            return initialState
    }

    return state
}

export default ChatPostsReducer
