import React, { FC } from 'react'

import { Image } from '../../store/state.props'

interface PictureProps {
    image: Image
    className?: string
    alt?: string
    onClick?: () => void
}

const Picture: FC<PictureProps> = ({
    image,
    className = undefined,
    alt = undefined,
    onClick = undefined,
}) => (
    <picture>
        <source type="image/webp" srcSet={image.src.webp} />
        <source type={image.mimeType} srcSet={image.src.fallback} />
        <img
            src={image.src.fallback}
            width={image.dim.width}
            height={image.dim.height}
            alt={alt}
            className={className}
            onClick={onClick}
        />
    </picture>
)

export default Picture
