import { Captcha } from '../../../hooks/use-captcha'
import { APIError, getApiUrl, handleFetchErrorAsObject } from '../../../utils/api'

export const submitPasswordReset = (
    username: string,
    password: string,
    captcha: Captcha
): Promise<any> =>
    fetch(`${getApiUrl()}/user/password-reset`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username,
            newPassword: password,
            newPasswordConfirm: password,
            captcha: captcha,
        }),
    })
        .then(handleFetchErrorAsObject)
        .then((json) => json)
        .catch((error: APIError) => Promise.reject(error))
