import { Location, NavigateFunction } from 'react-router-dom'

import { ChatbotDialogueAnswer, ChatbotDialogueItem } from '../../store/chatbot/chatbot.props'
import { FideoThunkDispatch } from '../../store/state.props'

export enum ChatbotDialogueItemType {
    LEFT,
    CENTER,
    RIGHT,
}

export enum ChatbotDialogueItemActionType {
    __, // @solves https://github.com/Microsoft/TypeScript/issues/29204
    ROUTE,
    CLICK,
    ANSWER_SELECT,
    TEXT_INPUT,
}

export enum ChatbotContextEnum {
    HOME,
    WIDGET,
    SELF_TEST,
    AUTHENTICATION,
    WEBAPP_INSTALL,
}

type DispatchChatbotActionArgs = null | string | ChatbotDialogueAnswer

export type DispatchChatbotAction = (
    item: ChatbotDialogueItem,
    args?: DispatchChatbotActionArgs
) => void

export type AddChatbotItems = (items: ChatbotDialogueItem[]) => void

export type ChatbotSetInvalidAnswer = (itemIndex: number | null, content: string) => void

export type ChatbotSetUserAnswer = (content: string, type?: string) => void

export type ChatbotOnClickClose = (ev?: MouseEvent | any) => void

interface UseChatbotDispatchResult {
    addChatbotItems: AddChatbotItems
    dispatchChatbotAction: DispatchChatbotAction
    setInvalidAnswer: ChatbotSetInvalidAnswer
    setUserAnswer: ChatbotSetUserAnswer
}

export type UseChatbotDispatch = (
    context: ChatbotContextEnum,
    onClickClose?: ChatbotOnClickClose
) => UseChatbotDispatchResult

export interface ChatbotAction {
    addChatbotItems: (items: ChatbotDialogueItem[]) => void
    closeWidget: () => void
    dispatch: FideoThunkDispatch
    navigate: NavigateFunction
    location: Location
    setInvalidAnswer: ChatbotSetInvalidAnswer
    setUserAnswer: ChatbotSetUserAnswer
}
