import { APIError, APIErrorFieldsIterators, ActualErrorFields } from './api'

/**
 * This (quite unsexy) error-check became mandatory after upgrading
 * the API to Symfony 6. This function is meant to be downstream-compatible.
 */
export const isInvalidCaptchaError = (error: APIError): boolean =>
    (error.error &&
        error.error.fields &&
        (error.error.fields as ActualErrorFields).detail &&
        (error.error.fields as ActualErrorFields).detail.indexOf('captcha') >= 0) ||
    (error.error &&
        error.error.fields &&
        (error.error.fields as APIErrorFieldsIterators).iterator &&
        (error.error.fields as APIErrorFieldsIterators).iterator[0]?.message === 'Wrong phrase.') ||
    (error.error &&
        error.error.fields &&
        (error.error.fields as APIErrorFieldsIterators).iterator &&
        (error.error.fields as APIErrorFieldsIterators).iterator[0]?.message === 'Wrong token.') ||
    (error.error &&
        error.error.fields &&
        (error.error.fields as APIErrorFieldsIterators).iterator &&
        (error.error.fields as APIErrorFieldsIterators).iterator[0]?.message ===
            'Token expired.') ||
    false

export const isAuthenticationError = (error: APIError): boolean =>
    (error.error && error.error.code === 401) || false
