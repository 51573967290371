import { AnyAction } from 'redux'

import { RESET_SESSION } from '../user/user.actions'
import {
    ADD_CHAT_NEWS,
    ADD_CHAT_THREAD,
    ADD_CHAT_THREADS,
    ADD_GROUP_MEMBER,
    ADD_PRIVATE_CHAT_THREAD,
    DELETE_CHAT_NEWS,
    DELETE_GROUP_MEMBER,
    FETCH_CHAT_THREADS_ERROR,
    FETCH_CHAT_THREADS_PENDING,
    PATCH_CHAT_THREAD,
    PATCH_GROUP_MEMBER,
    RESET_CHAT_THREADS,
    UNMARK_GROUP_MEMBER,
    UPDATE_THREADS_PAGINATION,
} from './chat-threads.actions'
import {
    ChatThread,
    ChatThreadsState,
    DeleteGroupMemberPayload,
    PatchGroupMemberPayload,
} from './chat-threads.props'

const initialState: ChatThreadsState = {
    data: [],
    chatNews: [],
    privateChatThread: undefined,
    isPending: false,
    pagination: {
        nextPage: 0,
    },
}

const chatThreadsReducer = (
    state: ChatThreadsState = initialState,
    action: AnyAction
): ChatThreadsState => {
    switch (action.type) {
        case FETCH_CHAT_THREADS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case FETCH_CHAT_THREADS_ERROR:
            // @todo Implement error codes/handling
            return {
                ...state,
                isPending: false,
            }
        case ADD_CHAT_THREAD:
            return {
                ...state,
                isPending: false,
                data: [...state.data, action.chatThread.data],
            }
        case ADD_CHAT_THREADS:
            return {
                ...state,
                isPending: false,
                data: [
                    // Merge or add data to the store
                    ...state.data.map(
                        (chatThread) =>
                            action.chatThreads.data.find(
                                (_chatThread: ChatThread) => _chatThread.id === chatThread.id
                            ) || chatThread
                    ),
                    ...action.chatThreads.data.filter(
                        (chatThread: ChatThread) =>
                            state.data.find(
                                (_chatThread: ChatThread) => _chatThread.id === chatThread.id
                            ) === undefined
                    ),
                ],
            }
        case ADD_CHAT_NEWS:
            return {
                ...state,
                isPending: false,
                chatNews: action.chatNews,
            }
        case DELETE_CHAT_NEWS:
            return {
                ...state,
                isPending: false,
                chatNews: state.chatNews.filter(
                    (chatNews) => chatNews.chatThreadId !== action.threadId
                ),
            }
        case ADD_PRIVATE_CHAT_THREAD:
            return {
                ...state,
                privateChatThread: action.chatThread.data[0],
            }
        case RESET_CHAT_THREADS:
            return {
                ...state,
                isPending: false,
                data: [],
            }
        case UPDATE_THREADS_PAGINATION:
            return {
                ...state,
                pagination: {
                    // nextPage may be undefined!
                    nextPage: action.pagination.next,
                },
            }
        case PATCH_CHAT_THREAD:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatThread) =>
                    chatThread.id === action.chatThread.data.id
                        ? { ...chatThread, ...action.chatThread.data }
                        : chatThread
                ),
            }
        case ADD_GROUP_MEMBER:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatThread) =>
                    chatThread.id === action.threadId
                        ? {
                              ...chatThread,
                              groupMembers: [...(chatThread.groupMembers || []), action.data],
                          }
                        : chatThread
                ),
            }
        case DELETE_GROUP_MEMBER:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatThread) =>
                    chatThread.id === (action as DeleteGroupMemberPayload).threadId
                        ? {
                              ...chatThread,
                              groupMembers: (chatThread.groupMembers || []).filter(
                                  (membership) => membership.id !== action.memberId
                              ),
                          }
                        : chatThread
                ),
            }
        case UNMARK_GROUP_MEMBER:
            return {
                ...state,
                // @todo
            }
        case PATCH_GROUP_MEMBER:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatThread) =>
                    chatThread.id === (action as PatchGroupMemberPayload).threadId
                        ? {
                              ...chatThread,
                              groupMembers: (chatThread.groupMembers || []).map((membership) =>
                                  membership.id ===
                                  (action as PatchGroupMemberPayload).membership.id
                                      ? {
                                            ...membership,
                                            ...(action as PatchGroupMemberPayload).membership,
                                        }
                                      : membership
                              ),
                          }
                        : chatThread
                ),
            }
        case RESET_SESSION:
            return initialState
    }

    return state
}

export default chatThreadsReducer
