import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useNoIndex from '../../../hooks/use-no-index'
import useSessionUser from '../../../hooks/use-session-user'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchChatPostsByChatThreadId } from '../../../store/chat-posts/chat-posts.actions'
import { getChatPostsByThreadId } from '../../../store/chat-posts/chat-posts.selectors'
import { fetchChatThread } from '../../../store/chat-threads/chat-threads.actions'
import { getChatThreadById } from '../../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../../store/state.props'
import { calendarFormat } from '../../../utils/format'
import { getGroupMembershipRole } from '../../../utils/group-memberships'
import { ChatThreadDetailParams } from './chat-thread-detail.props'
import { ChatThreadDetailView } from './chat-thread-detail.view'

/**
 * Chat thread detail page
 * @route /chat/beitrag/:id
 *
 * @todo onCatch fetchChatThread throw error 404 (instead of redirect to /chat)
 */
const ChatThreadDetailContainer: FC = () => {
    useNoIndex()
    const { setParentLink } = useSetParentLink('/chat')
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const sessionUser = useSessionUser()
    const { id } = useParams<ChatThreadDetailParams>()
    const chatThread = useSelector((state: GlobalState) => getChatThreadById(state, Number(id)))
    const chatPosts = useSelector((state: GlobalState) => getChatPostsByThreadId(state, Number(id)))

    const lastReactionSince = useMemo(
        () =>
            chatPosts.length > 1
                ? moment(chatPosts[chatPosts.length - 1].createdAt).calendar(
                      undefined,
                      calendarFormat
                  )
                : null,
        [chatPosts]
    )

    const isGroupChat = useMemo(() => chatThread && chatThread.type === 'group', [chatThread])
    const selfMembershipRole = getGroupMembershipRole(
        chatThread?.groupMembers || [],
        sessionUser?.username
    )
    const isGroupChatMember =
        isGroupChat &&
        selfMembershipRole &&
        (selfMembershipRole === 'admin' || selfMembershipRole === 'member')
    const isMod = sessionUser?.isMod

    useEffect(() => {
        if (!chatThread) {
            dispatch(fetchChatThread(Number(id))).catch(() => {
                // Not found... or no connection...
                navigate('/chat')
            })
        } else {
            setParentLink(`/chat/thema/${chatThread.chatTopicId}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatThread])

    useEffect(() => {
        if (chatThread && (chatThread.type === 'group' ? isGroupChatMember || isMod : true)) {
            dispatch(fetchChatPostsByChatThreadId(Number(id), true)).catch((err) => {
                console.log('Failed to fetch chat-posts for chat-thread', id)
            })
        }
    }, [isGroupChatMember, isMod])

    if (!chatThread) {
        return <Loading />
    }

    return (
        <ChatThreadDetailView
            chatThread={chatThread}
            chatPosts={chatPosts}
            lastReactionSince={lastReactionSince}
            selfMembershipRole={selfMembershipRole}
        />
    )
}

export default ChatThreadDetailContainer
