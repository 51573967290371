import React, { FC } from 'react'

import useSessionUser from '../../../hooks/use-session-user'
import ChatPostListItem from '../chat-post-list-item'
import { ChatPostListProps } from './chat-post-list.props'

const ChatPostList: FC<ChatPostListProps> = ({ posts }) => {
    const sessionUser = useSessionUser()

    return (
        <div className="component--chat-post-list">
            {posts?.map((chatPost) => (
                <ChatPostListItem
                    chatPost={chatPost}
                    sessionUserName={sessionUser?.username}
                    key={chatPost.id}
                />
            ))}
        </div>
    )
}

export default ChatPostList
