import React, { FC } from 'react'

import ChatbotDialogueListItem from '../chatbot-dialogue-list-item'
import { ChatbotDialogueListProps } from './chatbot-dialogue-list.props'

const ChatbotDialogueList: FC<ChatbotDialogueListProps> = ({ items, dispatchChatbotAction }) => (
    <>
        {items?.map((item, index) => (
            <ChatbotDialogueListItem
                item={item}
                key={index}
                dispatchChatbotAction={dispatchChatbotAction}
            />
        ))}
    </>
)

export default ChatbotDialogueList
