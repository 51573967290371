import { connect } from 'react-redux'

import { toggleNavbarVisibility } from '../../../store/layout/layout.actions'
import { GlobalState } from '../../../store/state.props'
import Header from './header.view'

const mapStateToProps = (state: GlobalState) => ({
    navbarIsVisible: state.layout.navbarIsVisible,
})

const dispatchToProps = {
    toggleNavbarVisibility,
}

export default connect(mapStateToProps, dispatchToProps)(Header)
