export interface TextBlock {
    code: string
    text: string
}

export interface TextBlockState {
    data: TextBlock[]
}

export enum TextBlockCode {
    // @see TextBlockCodes.php
    INTRO_BLOG_OVERVIEW = 'intro-blog-overview',
    INTRO_CHAT_GROUP_NEW = 'intro-chat-group-new',
    INTRO_CHAT_GROUP_SUCCESS = 'intro-chat-group-success',
    INTRO_CHAT_GROUP_EDIT = 'intro-chat-group-edit',
    INTRO_CHAT_THREAD_NEW = 'intro-chat-thread-new',
    INTRO_CHAT_THREAD_SUCCESS = 'intro-chat-thread-success',
    INTRO_CHAT_THREAD_EDIT = 'intro-chat-thread-edit',
    INTRO_CHAT_POST_EDIT = 'intro-chat-post-edit',
    INTRO_CHAT_POST_EDIT_SUCCESS = 'intro-chat-post-edit-success',
    INTRO_CHAT_TOPIC_OVERVIEW = 'intro-chat-topic-overview',
    INTRO_CONTACT_FORM = 'intro-contact-form',
    INTRO_CONTACT_FORM_SUCCESS = 'intro-contact-form-success',
    INTRO_EMERGENCY_KIT = 'intro-emergency-kit',
    INTRO_EMERGENCY_KIT_NEW = 'intro-emergency-kit-new',
    INTRO_FAQ_TOPIC_OVERVIEW = 'intro-faq-topic-overview',
    INTRO_FLYER_FORM = 'intro-flyer-form',
    INTRO_FLYER_FORM_SUCCESS = 'intro-flyer-form-success',
    INTRO_SCHOOLBOX_FORM = 'intro-schoolbox-form',
    INTRO_SCHOOLBOX_FORM_SUCCESS = 'intro-schoolbox-form-success',
    INTRO_PROFILE = 'intro-profile',
    INTRO_SEARCH_RESULTS = 'intro-search-results',
    DELETE_ACCOUNT = 'delete-account',
    INTRO_RESET_PASSWORD = 'intro-reset-password',
    INTRO_SETTINGS_CONTACT_DETAILS = 'intro-settings-contact-details',
    INTRO_SETTINGS_PASSWORD = 'intro-settings-password',
    INTRO_SETTINGS_PHONENBR = 'intro-settings-phone-number',
    INTRO_USER_CHAT_GROUPS = 'intro-user-chat-groups',
    INTRO_USER_CHAT_THREADS = 'intro-user-chat-threads',
}
