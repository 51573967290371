import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ButtonLoadMore from '../../components/button-load-more'
import ChatThreadListItem from '../../components/chat/chat-thread-list-item'
import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSessionUser from '../../hooks/use-session-user'
import { fetchChatPostsByCreatorName } from '../../store/chat-posts/chat-posts.actions'
import {
    getChatPostPagination,
    getChatPostsByCreatorName,
} from '../../store/chat-posts/chat-posts.selectors'
import {
    fetchChatThreadsByCreatorName,
    resetChatThreads,
} from '../../store/chat-threads/chat-threads.actions'
import { ChatThread } from '../../store/chat-threads/chat-threads.props'
import {
    getChatNews,
    getChatThreadsByCreatorName,
    getChatThreadsPagination,
} from '../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../utils/configs'
import { NoItemFound } from '../search/search.view'

let fetchingInProgress = false

// @todo container-switch as ul li; ARIA semantic
// @todo Show loading indicator whilst fetching data
const UserChatThreads: FC = () => {
    const navigate = useNavigate()
    const dispatch = useFideoDispatch()
    const [filter, setFilter] = useState('all')
    const sessionUser = useSessionUser()

    const textBlock = useSelector(
        (state: GlobalState) =>
            getTextBlockByPageCode(state, TextBlockCode.INTRO_USER_CHAT_THREADS),
        shallowEqual
    )
    const threads = useSelector(
        (state: GlobalState) => getChatThreadsByCreatorName(state, sessionUser?.username),
        shallowEqual
    )
    const threadsPagination = useSelector(
        (state: GlobalState) => getChatThreadsPagination(state),
        shallowEqual
    )
    const posts = useSelector(
        (state: GlobalState) => getChatPostsByCreatorName(state, sessionUser?.username),
        shallowEqual
    )
    const postsPagination = useSelector(
        (state: GlobalState) => getChatPostPagination(state),
        shallowEqual
    )
    const chatNews = useSelector((state: GlobalState) => getChatNews(state), shallowEqual)

    const loadMoreAction = () => () => {
        // Dispatch any, or all, of the fetch-next-page functions
        if (typeof threadsPagination.nextPage !== 'undefined' && sessionUser) {
            dispatch(fetchChatThreadsByCreatorName(sessionUser.username))
        }
        if (typeof postsPagination.nextPage !== 'undefined' && sessionUser) {
            dispatch(fetchChatPostsByCreatorName(sessionUser.username))
        }
    }

    useEffect(() => {
        // Once chatNews updates, refetch local posts to show accurate amount of numPosts
        if (sessionUser && sessionUser.username && !fetchingInProgress) {
            fetchingInProgress = true
            Promise.all([
                dispatch(resetChatThreads()),
                dispatch(fetchChatPostsByCreatorName(sessionUser.username, true)),
                dispatch(fetchChatThreadsByCreatorName(sessionUser.username, true)),
            ]).then(() => {
                fetchingInProgress = false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatNews])

    if (!sessionUser) {
        navigate('/auth')
        return null
    }

    if (posts.length >= 1 && typeof posts[0].chatThread === 'undefined') {
        // Initial render: Dispatched actions not yet complete i.e. stores not yet flushed
        return <Loading />
    }

    return (
        <div className="page page--user-chat-threads">
            <div className="page-header">
                <h1 className="title-black">Eigene Beiträge und Reaktionen</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <div className="flex-container-switch">
                <div
                    className={cn('flex-item-switch', {
                        'flex-item-switch--active': filter === 'all',
                    })}
                    onClick={() => setFilter('all')}
                >
                    Alle
                </div>
                <div
                    className={cn('flex-item-switch', {
                        'flex-item-switch--active': filter === 'threads',
                    })}
                    onClick={() => setFilter('threads')}
                >
                    Beiträge
                </div>
                <div
                    className={cn('flex-item-switch', {
                        'flex-item-switch--active': filter === 'posts',
                    })}
                    onClick={() => setFilter('posts')}
                >
                    Reaktionen
                </div>
            </div>

            <div className="search-result-list">
                {(filter === 'all' || filter === 'threads') && (
                    <>
                        {threads.map((thread) => (
                            <div
                                key={thread.id}
                                className={cn(
                                    'search-result-list-item',
                                    'full-width',
                                    'search-result-list-item--thread'
                                )}
                            >
                                <ChatThreadListItem
                                    chatThread={thread}
                                    sessionUserName={sessionUser?.username}
                                />
                            </div>
                        ))}

                        {filter !== 'all' && threads.length === 0 && <NoItemFound />}

                        {filter === 'all' && threads.length === 0 && posts.length === 0 && (
                            <NoItemFound />
                        )}

                        {filter !== 'all' && typeof threadsPagination.nextPage !== 'undefined' && (
                            <ButtonLoadMore
                                dispatchAction={() =>
                                    fetchChatThreadsByCreatorName(sessionUser.username)
                                }
                            />
                        )}
                    </>
                )}
                {(filter === 'all' || filter === 'posts') && (
                    <>
                        {posts.map((post) => (
                            <div
                                key={post.id}
                                className={cn(
                                    'search-result-list-item',
                                    'full-width',
                                    'search-result-list-item--post'
                                )}
                            >
                                <ChatThreadListItem
                                    chatThread={post.chatThread as ChatThread}
                                    chatPost={post}
                                    sessionUserName={sessionUser?.username}
                                />
                            </div>
                        ))}

                        {filter !== 'all' && posts.length === 0 && <NoItemFound />}

                        {/* {(filter !== 'all' && typeof postsPagination.nextPage !== 'undefined') &&
                        <ButtonLoadMore dispatchAction={() => fetchChatPostsByCreatorName(sessionUser.username)} />
                        } */}
                    </>
                )}
                {filter === 'all' && typeof threadsPagination.nextPage !== 'undefined' && (
                    <ButtonLoadMore dispatchAction={() => loadMoreAction()} />
                )}
            </div>
        </div>
    )
}

export default UserChatThreads
