import { ReactElement } from 'react'

import { replaceItemContent, setNextItemActive } from '../../../store/chatbot/chatbot.actions'
import { ChatbotDialogueItem } from '../../../store/chatbot/chatbot.props'
import {
    checkPhonenumberForExistence,
    checkUsernameForExistence,
    editUser,
    loginUser,
    registerUser,
    verifyUser,
} from '../../../store/user/user.actions'
import {
    CheckUsernameExistsResponse,
    LoginStatus,
    VerificationTarget,
} from '../../../store/user/user.props'
import { APIError } from '../../../utils/api'
import { getCookie, setOneDayCookie } from '../../../utils/cookies'
import { isInvalidCaptchaError } from '../../../utils/errors'
import { SLUG_AGB, SLUG_DATENSCHUTZ } from '../../../utils/pages'
import ChatbotCaptchaItem from '../chatbot-captcha-item'
import ChatbotCounterItem from '../chatbot-counter-item'
import {
    ChatbotDialogueItemActionType as ActionType,
    ChatbotAction,
    ChatbotContextEnum,
} from '../chatbot.props'

/**
 * In order to subscribe to dynamically rendered "contentComponent"
 * (e.g. ChatbotCaptchaItem) we leak a set of mutable functions
 */
interface ChatbotFCSubscribers {
    getCounter: () => Promise<any> | undefined
    freezeCounter: () => void
    reloadCaptcha: () => void
    freezeCaptcha: () => void
}

export var chatbotFCSubscribers: ChatbotFCSubscribers = {
    getCounter: () => undefined,
    freezeCounter: () => undefined,
    reloadCaptcha: () => undefined,
    freezeCaptcha: () => undefined,
}

const AuthenticationDialogueData = {
    // Immutable blueprint:
    // Data for login- & registration-chatbot & -submission
    username: '',
    password: '',
    phoneNbr: '',
    registrationValidationInProgress: false,
    failedLoginAttempts: 0,
    failedRegistrationAttempts: 0,
    captcha: {
        token: '',
        phrase: '',
    },
}

let data = { ...AuthenticationDialogueData }

const COOKIE_REGISTRATION_LOCKED = 'registration_locked'

export const isValidUsername = (username: string) =>
    username.length >= 5 && username.match(/^[a-züäö]{1}[a-z0-9üäö]{4,15}$/i)
export const isValidPassword = (password: string) => password.length >= 8 && password.length <= 30
export const isValidPhoneNbr = (phoneNbr: string) =>
    phoneNbr.length >= 8 && phoneNbr.match('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$')
export const isValidCode = (code: string) => code.match('^[A-Za-z0-9]{6}$') !== null
export const isValidCaptcha = (phrase: string) => phrase.length >= 6

const registrationAction = ({ dispatch, setInvalidAnswer }: ChatbotAction, phrase: string) => {
    data.captcha.phrase = phrase

    if (!isValidCaptcha(data.captcha.phrase)) {
        setInvalidAnswer(null, `Der Code besteht aus mind. sechs Zeichen`)
    } else {
        if (!data.registrationValidationInProgress) {
            data.registrationValidationInProgress = true

            dispatch(registerUser(data.username, data.password, data.phoneNbr, data.captcha))
                .then(() => {
                    dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 9))
                    dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 10))
                    dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 11))
                    chatbotFCSubscribers.freezeCaptcha()
                })
                .catch((error: APIError) => {
                    chatbotFCSubscribers.reloadCaptcha()

                    let errorMsg = error.error?.message

                    if (isInvalidCaptchaError(error)) {
                        errorMsg = `Der eingegebene "Captcha"-Code ist falsch`
                    } else {
                        // Possible other errors: Username or phone number already given; invalid phone number or password
                        // @todo Scan via error.error.fields.violations[]
                        if ((!errorMsg || errorMsg === 'Form errors') && error.error) {
                            errorMsg = `Telefonnummer bereits vergeben`
                        }
                    }

                    setInvalidAnswer(
                        null,
                        `Fehler: ${errorMsg?.replace('.', '')}. Bitte probiere es erneut.`
                    )
                })
                .finally(() => (data.registrationValidationInProgress = false))
        }
    }
}

const ChatbotRegistrationDialogues: ChatbotDialogueItem[] = [
    {
        id: 0,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: true,
        content: `Schön, dass Du Dich bei FIDEO registrieren möchtest. Wähle zunächst einen Benutzernamen. Dieser muss aus 5-16 Zeichen bestehen, darf nur Buchstaben und Zahlen enthalten (keine Sonderzeichen) und beginnt mit einem Buchstaben:`,
        action: ({ dispatch, setInvalidAnswer }, username: string) => {
            data = { ...AuthenticationDialogueData }

            if (!isValidUsername(username)) {
                setInvalidAnswer(
                    null,
                    `Der Benutzername muss aus 5-16 Zeichen bestehen, darf nur Buchstaben und Zahlen enthalten (keine Sonderzeichen) und beginnt mit einem Buchstaben. Bitte wähle einen anderen Benutzernamen:`
                )
            } else {
                dispatch(checkUsernameForExistence(username))
                    .then((json: CheckUsernameExistsResponse) => {
                        if (!json.data.freeForRegistration) {
                            setInvalidAnswer(
                                null,
                                `Dieser Benutzername ist leider bereits vergeben. Bitte wähle einen anderen Benutzernamen:`
                            )
                        } else if (!json.data.valid) {
                            setInvalidAnswer(
                                null,
                                `Der Benutzername muss aus 5-16 Zeichen bestehen, darf nur Buchstaben und Zahlen enthalten (keine Sonderzeichen) und beginnt mit einem Buchstaben. Bitte wähle einen anderen Benutzernamen:`
                            )
                        } else {
                            data.username = username
                            dispatch(
                                replaceItemContent(ChatbotContextEnum.AUTHENTICATION, 1, username)
                            )
                            dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 0))
                        }
                    })
                    .catch(() => {
                        setInvalidAnswer(
                            null,
                            `Unbekannter Fehler. Bitte überprüfe Deine Internetverbindung und versuche es erneut:`
                        )
                    })
            }
        },
    },
    {
        id: 1,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: false,
        content: `Super __X__, setze nun ein Passwort aus mind. acht Zeichen:`,
        action: ({ dispatch, setInvalidAnswer }, password: string) => {
            if (!isValidPassword(password)) {
                setInvalidAnswer(null, `Ein Passwort muss aus 8-30 Zeichen bestehen`)
            } else {
                data.password = password
                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 1))
            }
        },
    },
    {
        id: 2,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: false,
        content: `Bitte wiederhole Dein Passwort:`,
        action: ({ dispatch, setInvalidAnswer }, secondPassword: string) => {
            if (!isValidPassword(secondPassword)) {
                setInvalidAnswer(null, `Ein Passwort muss aus 8-30 Zeichen bestehen`)
            } else if (secondPassword !== data.password) {
                setInvalidAnswer(null, `Diese Passwörter stimmen nicht überein`)
                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 0))
            } else {
                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 2))
            }
        },
    },
    {
        id: 3,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: false,
        content: `Um Dich zu verifizieren benötigen wir Deine deutsche Mobilnummer ohne Leerzeichen und Landesvorwahl (z.B. 01711234567):`,
        action: ({ dispatch, setInvalidAnswer }, phoneNbr: string) => {
            if (!isValidPhoneNbr(phoneNbr)) {
                setInvalidAnswer(
                    null,
                    `Bitte gebe eine gültige Mobilnummer mit deutscher Vorwahl an:`
                )
            } else {
                dispatch(checkPhonenumberForExistence(phoneNbr))
                    .then((json: CheckUsernameExistsResponse) => {
                        if (!json.data.valid) {
                            setInvalidAnswer(
                                null,
                                `Diese Nummer entspricht leider nicht dem gewünschten Format. Bitte probiere eine andere Nummer:`
                            )
                        } else if (!json.data.freeForRegistration) {
                            setInvalidAnswer(
                                null,
                                `Diese Nummer ist leider bereits vergeben. Bitte probiere eine andere Nummer:`
                            )
                        } else {
                            data.phoneNbr = phoneNbr

                            if (data.captcha.phrase !== '') {
                                // User came from step "Nummer ändern"; skip AGB-confirmation & captcha, goto captcha.action
                                // registrationAction(args, data.captcha.phrase)
                                ;[7, 8].forEach((actionId) =>
                                    dispatch(
                                        setNextItemActive(
                                            ChatbotContextEnum.AUTHENTICATION,
                                            actionId
                                        )
                                    )
                                )
                            } else {
                                ;[3, 4, 5, 6].forEach((actionId) =>
                                    dispatch(
                                        setNextItemActive(
                                            ChatbotContextEnum.AUTHENTICATION,
                                            actionId
                                        )
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: APIError) => {
                        if (error.error && error.error?.code === 400) {
                            setInvalidAnswer(
                                null,
                                `Diese Nummer scheint nicht dem gewünschten Format zu entsprechen. Bitte probiere eine andere Nummer:`
                            )
                        } else {
                            setInvalidAnswer(
                                null,
                                `Unbekannter Fehler. Bitte überprüfe Deine Internetverbindung und versuche es erneut:`
                            )
                        }
                    })
            }
        },
    },
    {
        id: 4,
        type: 'left',
        active: false,
        content: `Bestätige, dass Du die Nutzungsbedingungen und Datenschutzerklärung gelesen und verstanden hast`,
    },
    {
        id: 5,
        type: 'right',
        actionType: ActionType.CLICK,
        active: false,
        content: `Datenschutz lesen`,
        action: () => {
            if (!data.registrationValidationInProgress) {
                window.setTimeout(() => window.open(`https://fideo.de${SLUG_DATENSCHUTZ}`), 1000)
            }
        },
    },
    {
        id: 6,
        type: 'right',
        actionType: ActionType.CLICK,
        active: false,
        content: `Nutzungsbedingungen lesen`,
        action: () => {
            if (!data.registrationValidationInProgress) {
                window.setTimeout(() => window.open(`https://fideo.de${SLUG_AGB}`), 1000)
            }
        },
    },
    {
        id: 7,
        type: 'right',
        actionType: ActionType.CLICK,
        active: false,
        content: `Bestätigen`,
        action: ({ dispatch }) => {
            dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 7))
            dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 8))
        },
    },
    {
        id: 8,
        type: 'left',
        active: false,
        content: `Gleich geschafft! Bitte gebe den folgenden Sicherheitscode ein:`,
    },
    {
        id: 9,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: false,
        renderWithoutMargin: true,
        content: '',
        contentComponent: () => ChatbotCaptchaItem(data) as ReactElement<any, any>,
        action: registrationAction,
    },
    {
        id: 10,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: false,
        content: `Super. Zuletzt solltest Du eine SMS zur Freigabe erhalten haben. Gib den Code hier ein:`,
    },
    {
        id: 11,
        type: 'right',
        active: false,
        actionType: ActionType.CLICK,
        content: 'Code erneut senden',
        contentComponent: () => ChatbotCounterItem({}) as ReactElement<any, any>,
        action: ({ dispatch, setInvalidAnswer }) => {
            if (typeof chatbotFCSubscribers.getCounter === 'undefined') {
                setInvalidAnswer(
                    null,
                    `Es ist ein unbekannter Fehler aufgetreten. Bitte lade die Seite erneut und probiere es noch einmal.`
                )
                return
            }

            ;(chatbotFCSubscribers.getCounter() as any).then((counter: number) => {
                if (counter > 0 && counter !== 99) {
                    // 99 would indicate an inactive counter
                    setInvalidAnswer(
                        null,
                        `Es kann einige Minuten dauern bis die SMS ankommt. Bitte warte noch ein bisschen.`
                    )
                } else if (counter === 99) {
                    // Inactive, "freezed" counter
                } else {
                    // Fill out captcha again to resend SMS
                    chatbotFCSubscribers.freezeCounter()
                    dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 7))
                    dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 8))
                }
            })
        },
    },
    {
        id: 12,
        type: 'right',
        active: false,
        actionType: ActionType.CLICK, // Naah, actually it has CLICK & TEXT :/
        content: `Nummer ändern`, // Any changes made here have to be done on chatbot-text-input.view ~l86 as well
        action: ({ dispatch, setInvalidAnswer, navigate, location }, code: string | undefined) => {
            if (code && code !== '') {
                // Received text input
                if (!isValidCode(code)) {
                    setInvalidAnswer(null, `Der Code besteht aus sechs Zeichen`)
                } else {
                    dispatch(verifyUser(VerificationTarget.REGISTER, data.username, code))
                        .then((json) => {
                            dispatch(editUser(json.data))
                            dispatch(
                                replaceItemContent(
                                    ChatbotContextEnum.AUTHENTICATION,
                                    13,
                                    data.username
                                )
                            )
                            dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 12))

                            window.setTimeout(() => {
                                dispatch(loginUser(data.username, data.password)).then(() => {
                                    navigate(location.state?.destination ?? '/profil')
                                })
                            }, 2000)
                        })
                        .catch(() => {
                            if (++data.failedRegistrationAttempts >= 3) {
                                setInvalidAnswer(
                                    null,
                                    `Du hast den Bestätigungscode leider zu oft eingegeben. Versuche es morgen wieder.`
                                )
                                setOneDayCookie(COOKIE_REGISTRATION_LOCKED, 'true')
                                window.setTimeout(() => {
                                    navigate('/')
                                }, 2000)
                            } else {
                                setInvalidAnswer(null, `Falscher Code. Bitte probiere es erneut:`)
                            }
                        })
                }
            } else {
                // Received click input
                chatbotFCSubscribers.freezeCounter()
                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 2))
            }
        },
    },
    {
        id: 13,
        type: 'left',
        actionType: ActionType.ROUTE,
        active: false,
        navigationTarget: '/profil',
        content: `Perfekt! Du hast Dich erfolgreich als __X__ registriert und bist jetzt eingeloggt`,
    },
]

const ChatbotLoginDialogues: ChatbotDialogueItem[] = [
    {
        id: 0,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: true,
        content: `Okay, Du möchtest Dich einloggen. Wie lautet Dein Benutzername?`,
        action: ({ dispatch, setInvalidAnswer }, username: string) => {
            data = { ...AuthenticationDialogueData }

            if (!isValidUsername(username)) {
                setInvalidAnswer(null, `Ein Benutzername muss aus 5-30 Zeichen bestehen`)
            } else {
                data.failedLoginAttempts = 0
                data.username = username
                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 0))
            }
        },
    },
    {
        id: 1,
        type: 'left',
        actionType: ActionType.TEXT_INPUT,
        active: false,
        content: `Dein Passwort:`,
        action: ({ dispatch, setInvalidAnswer, navigate, location }, password: string) => {
            if (!isValidPassword(password)) {
                setInvalidAnswer(null, `Dieses Passwort ist zu kurz (8-30 Zeichen)`)
            } else {
                data.password = password

                dispatch(loginUser(data.username, data.password))
                    .then(() => {
                        dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 4))

                        window.setTimeout(() => {
                            navigate(location.state?.destination ?? '/profil')
                        }, 2000)
                    })
                    .catch((error: LoginStatus) => {
                        if (error === LoginStatus.CREDENTIALS_ERROR) {
                            setInvalidAnswer(null, `Diese Daten stimmen leider nicht.`)

                            if (++data.failedLoginAttempts >= 2) {
                                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 1))
                                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 2))
                                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 3))
                            } else {
                                setInvalidAnswer(
                                    null,
                                    `Bitte probiere es erneut oder gehe zurück, um Dein Passwort zu ändern`
                                )
                                dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 0))
                            }
                        } else {
                            setInvalidAnswer(
                                null,
                                `Unbekannter Fehler. Bitte überprüfe Deine Internetverbindung und versuche es erneut`
                            )
                            dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, -1))
                        }
                    })
            }
        },
    },
    {
        id: 2,
        type: 'left',
        active: false,
        content: `Hast Du Dein Passwort vergessen?`,
    },
    {
        id: 3,
        type: 'right',
        active: false,
        actionType: ActionType.ROUTE,
        content: `Ja, ich habe mein Passwort vergessen`,
        navigationTarget: '/passwort-vergessen',
        transitionTime: 0.4,
    },
    {
        id: 4,
        type: 'right',
        active: false,
        actionType: ActionType.CLICK,
        content: `Nein`,
        action: ({ dispatch }) => {
            data.failedLoginAttempts = 0
            dispatch(setNextItemActive(ChatbotContextEnum.AUTHENTICATION, 0))
        },
        transitionTime: 0.8,
    },
    {
        id: 5,
        type: 'left',
        actionType: ActionType.ROUTE,
        active: false,
        content: `Du hast Dich erfolgreich eingeloggt!`,
        navigationTarget: '/profil',
    },
]

export const ChatbotAuthenticationDialogues: ChatbotDialogueItem[] = [
    {
        id: 0,
        type: 'left',
        active: true,
        content: `Um FIDEO voll nutzen zu können, musst du Dich erst anmelden. Was möchtest Du tun?`,
        transitionTime: 0,
    },
    {
        id: 1,
        type: 'right',
        active: true,
        actionType: ActionType.CLICK,
        content: `Login`,
        action: ({ addChatbotItems }) => addChatbotItems(ChatbotLoginDialogues),
    },
    {
        id: 2,
        type: 'right',
        active: true,
        actionType: ActionType.CLICK,
        content: `Registrieren`,
        action: ({ addChatbotItems, setInvalidAnswer }) => {
            const isLocked = getCookie(COOKIE_REGISTRATION_LOCKED)
            if (isLocked) {
                setInvalidAnswer(
                    null,
                    `Du bist leider 24 Stunden lang für das Registrierungsformular gesperrt. Bitte probiere es später wieder.`
                )
                return
            }
            addChatbotItems(ChatbotRegistrationDialogues)
        },
    },
    {
        id: 3,
        type: 'right',
        active: true,
        actionType: ActionType.ROUTE,
        content: `Passwort vergessen`,
        navigationTarget: '/passwort-vergessen',
    },
]
