import React, { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import useCaptcha from '../../hooks/use-captcha'
import useFideoNavigate from '../../hooks/use-fideo-navigate'
import useSeo from '../../hooks/use-seo'
import useSessionUser from '../../hooks/use-session-user'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { defaultInputFieldAttributes, getUnsanitizedPageText } from '../../utils/configs'
import { SLUG_DATENSCHUTZ, scrollToTop } from '../../utils/pages'
import { postContactMessage } from './contact-form.actions'

const ContactForm: FC = () => {
    const [captchaToken, captchaImageUrl, fetchCaptcha] = useCaptcha()
    const { gotoPrevPage } = useFideoNavigate()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_CONTACT_FORM)
    )
    const textBlockSuccess = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_CONTACT_FORM_SUCCESS)
    )
    const sessionUser = useSessionUser()

    const [formState, setFormState] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [notes, setNotes] = useState<string>('')
    const [captchaCode, setCaptchaCode] = useState<string>('')
    const [datenschutz, setDatenschutz] = useState<boolean>(false)

    // Update canonical link
    useSeo({
        title: 'Kontakt',
        metaTags: [],
        slug: '/kontakt',
    })

    const reloadCaptcha = () =>
        fetchCaptcha().then(() => {
            setCaptchaCode('')
        })

    const onChangeFirstName = (ev: ChangeEvent<HTMLInputElement>) => {
        setFirstName(ev.currentTarget.value)
    }

    const onChangeLastName = (ev: ChangeEvent<HTMLInputElement>) => {
        setLastName(ev.currentTarget.value)
    }

    const onChangeEmail = (ev: ChangeEvent<HTMLInputElement>) => {
        setEmail(ev.currentTarget.value)
    }

    const onChangeNotes = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(ev.currentTarget.value)
    }

    const onChangeDatenschutz = (ev: ChangeEvent<HTMLInputElement>) => {
        setDatenschutz(ev.currentTarget.checked)
    }

    const onChangeCaptchaCode = (ev: ChangeEvent<HTMLInputElement>) => {
        setCaptchaCode(ev.target.value)
    }

    const onClickReloadCaptcha = (ev: MouseEvent) => {
        reloadCaptcha()
        ev.preventDefault()
    }

    const onSubmit = (ev: MouseEvent) => {
        if (email === '' || email.indexOf('@') === -1) {
            setFormState('Deine E-Mail Adresse muss im richtigen Format vorliegen!')
        } else if (captchaCode === '') {
            setFormState('Bitte löse das Captcha!')
        } else if (!datenschutz) {
            setFormState('Bitte akzeptiere die Datenschutzerklärung!')
        } else if (firstName !== '' && lastName !== '' && notes !== '') {
            postContactMessage({
                firstName,
                lastName,
                email,
                text: notes,
                captcha: {
                    token: captchaToken as string,
                    phrase: captchaCode as string,
                },
            })
                .then(() => {
                    setFormState('__DONE__')
                    scrollToTop()
                })
                .catch((error) => {
                    if (error.error && error.error.code && error.error.code === 400) {
                        reloadCaptcha()
                        setFormState(
                            `Der eingegebene Sicherheitscode ist ungültig. Bitte erneut probieren.`
                        )
                    } else {
                        setFormState(`Konnte Anfrage nicht versenden. Fehler: ${error.message}`)
                    }
                })
        } else {
            setFormState('Alle Pflichtfelder (*) müssen ausgefüllt sein!')
        }

        ev.preventDefault()
    }

    if (formState === '__DONE__') {
        return (
            <div className="page page--contact-form">
                <div className="page-header">
                    <h1 className="title-black">Kontaktanfrage versendet</h1>
                    <p
                        className="text-black"
                        dangerouslySetInnerHTML={getUnsanitizedPageText(textBlockSuccess?.text)}
                    ></p>
                    <br />
                    {sessionUser ? (
                        <p>
                            <Link to="/profil" className="link-blue">
                                Zurück zu "Dein Bereich"
                            </Link>
                        </p>
                    ) : (
                        <p>
                            <Link to="/" className="link-blue">
                                Zurück zur Startseite
                            </Link>
                        </p>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="page page--contact-form">
            <div className="page-header">
                <h1 className="title-black">Kontakt</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <form className="contact-form page-header" method="POST" action="">
                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-form__firstname">
                        Vorname*
                    </label>
                    <input
                        type="text"
                        id="contact-form__firstname"
                        name="contact-form__firstname"
                        value={firstName}
                        required={true}
                        onChange={onChangeFirstName}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-form__lastname">
                        Nachname*
                    </label>
                    <input
                        type="text"
                        id="contact-form__lastname"
                        name="contact-form__lastname"
                        value={lastName}
                        required={true}
                        onChange={onChangeLastName}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-form__email">
                        E-Mail Adresse*
                    </label>
                    <input
                        type="email"
                        id="contact-form__email"
                        name="contact-form__email"
                        value={email}
                        required={true}
                        onChange={onChangeEmail}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="chat-thread-form__notes">
                        Nachricht*
                    </label>
                    <textarea
                        id="chat-thread-form__notes"
                        name="chat-thread-form__notes"
                        value={notes}
                        required={true}
                        onChange={onChangeNotes}
                    ></textarea>
                </div>

                <label className="checkbox-wrapper">
                    <input
                        value={datenschutz ? 'true' : 'false'}
                        onChange={onChangeDatenschutz}
                        type="checkbox"
                        required={true}
                    />
                    <div className="checkmark"></div>
                    <span className="checkbox-text">
                        Ich habe die{' '}
                        <Link to={SLUG_DATENSCHUTZ} className="text-underline">
                            Datenschutzerklärung
                        </Link>{' '}
                        gelesen und bin damit einverstanden.*
                    </span>
                </label>

                <div className="input-wrapper captcha-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-form__lastname">
                        Captcha*
                    </label>
                    <div className="captcha-field">
                        <img
                            alt="Lade Captcha..."
                            src={captchaImageUrl || ''}
                            width="800"
                            height="200"
                        />
                        <button
                            title="Neues Captcha laden"
                            onClick={onClickReloadCaptcha}
                            type="button"
                        ></button>
                    </div>

                    <input
                        type="text"
                        id="contact-form__captcha"
                        name="contact-form__captcha"
                        value={captchaCode}
                        required={true}
                        placeholder={`Code`}
                        onChange={onChangeCaptchaCode}
                    />
                </div>

                {formState !== '' && <p className="form-error input-wrapper">{formState}</p>}

                <div className="form__buttons">
                    <button
                        className="button button-secondary"
                        title="Formular verlassen"
                        onClick={gotoPrevPage}
                    >
                        Abbrechen
                    </button>

                    <button type="submit" onClick={onSubmit} className="button button-submit">
                        Senden
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ContactForm
