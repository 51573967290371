import React, { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import {
    addGroupMember,
    confirmGroupMember,
    deleteGroupMember,
    fetchChatNews,
    fetchChatThread,
} from '../../../store/chat-threads/chat-threads.actions'
import { ChatGroupMembershipSubmitterProps } from './chat-group-membership-submitter.props'

const ChatGroupMembershipSubmitter: FC<ChatGroupMembershipSubmitterProps> = ({
    selfMembershipId,
    selfMembershipRole,
    chatThreadId,
}) => {
    const sessionUser = useSessionUser()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useFideoDispatch()
    const [actionInProgress, setActionInProgress] = useState<boolean>(false)

    const requestMembership = () => {
        if (!sessionUser) {
            // User is not logged in: Redirect to login page
            navigate('/auth', { state: { destination: location.pathname } })
        } else if (!actionInProgress) {
            setActionInProgress(true)
            // Request membership and update thread
            dispatch(addGroupMember(chatThreadId))
                .then(() => setActionInProgress(false))
                .then(() => dispatch(fetchChatThread(chatThreadId)))
        }
    }

    const confirmMembership = () => {
        // Confirm membership and update thread
        if (actionInProgress) {
            return
        }

        setActionInProgress(true)
        dispatch(confirmGroupMember(chatThreadId, selfMembershipId as number))
            .then(() => dispatch(fetchChatThread(chatThreadId)))
            .then(() => dispatch(fetchChatNews()))
            .then(() => setActionInProgress(false))
    }

    const rejectMembership = () => {
        if (actionInProgress) {
            return
        }

        setActionInProgress(true)
        dispatch(deleteGroupMember(chatThreadId, selfMembershipId as number))
            .then(() => dispatch(fetchChatThread(chatThreadId)))
            .then(() => dispatch(fetchChatNews()))
            .then(() => setActionInProgress(false))
    }

    return (
        <div>
            {selfMembershipRole === 'requested' ? (
                <button className="button button-center">Gruppenmitgliedschaft beantragt</button>
            ) : selfMembershipRole === 'invited' ? (
                <>
                    <button className="button button-center" onClick={confirmMembership}>
                        Du bist eingeladen: Mitgliedschaft annehmen
                    </button>
                    <button
                        className="button button-center button-hollow"
                        onClick={rejectMembership}
                    >
                        Einladung ablehnen
                    </button>
                </>
            ) : (
                <button className="button button-center" onClick={requestMembership}>
                    Mitglied werden
                </button>
            )}
        </div>
    )
}

export default ChatGroupMembershipSubmitter
