import * as klaro from 'klaro/dist/klaro-no-css'
import { FC, useEffect } from 'react'

import { SLUG_DATENSCHUTZ } from './pages'

/**
 * Integration of "klaro" (https://github.com/kiprotect/klaro) implementing
 * default settings + Matomo analytics.
 *
 * A rough typescript module declaration can be found in utils/klaro.d.ts
 * SCSS can be found in styles/components/_consent-management.scss
 */

// @see https://github.com/kiprotect/klaro/blob/master/dist/config.js
const config: klaro.KlaroConfig = {
    acceptAll: true,
    groupByPurpose: false,
    hideDeclineAll: true,
    htmlTexts: true,
    lang: 'de',
    translations: {
        // @see https://github.com/kiprotect/klaro/blob/master/src/translations/de.yml
        de: {
            app: {
                required: {
                    title: ' (notwendig)',
                    description: 'Diese Einstellung wird immer benötigt',
                },
            },
            matomo: {
                title: 'Statistik',
                description: `Diese Cookies helfen uns besser zu verstehen, wie Du Dich auf unseren Seiten bewegst. Wir verwenden Matomo. So können wir FIDEO noch besser machen und nachweisen, dass FIDEO wirkt. Die Informationen werden anonym gesammelt.`,
            },
            default: {
                title: '',
                description:
                    'Diese Cookies sind notwendig, damit Du durch die Seiten navigieren und wesentliche Funktionen nutzen kannst.',
            },
            privacy: {
                title: '',
                description:
                    'Diese Cookies sind notwendig, um Deine Datenschutzeinstellungen zu speichern.',
            },
            purposes: {
                analytics: 'Statistik',
                default: '',
            },
            acceptAll: 'Zustimmen',
            consentModal: {
                title: `Deine Datenschutzeinstellungen`,
                description: `Notwendige Cookies kannst Du nicht deaktivieren, weil unsere Seite sonst nicht funktioniert. Hier kannst Du den Cookie-Einstellungen zustimmen oder sie nach Deinen Wünschen anpassen.<br><br>
Du möchtest noch mehr erfahren? Lies unsere <a class="text-underline" href='${SLUG_DATENSCHUTZ}' target='_blank' rel='noopener noreferrer' title='Fideo Datenschutzerklärung'>Datenschutzerklärung</a>.`,
            },
            consentNotice: {
                description: `
                    <h1>Deine Datenschutz&shy;einstellungen</h1>
                    <p>
                    Wir freuen uns, dass Du unsere Seite besuchst. Wir nutzen Cookies, damit FIDEO für Dich problemlos funktioniert. Außerdem helfen uns Statistik-Cookies dabei, FIDEO noch besser zu machen. Du kannst entscheiden, welche Cookies Du zulässt und welche nicht.
                    </p>
                `,
                learnMore: 'Einstellungen anpassen',
            },
            privacyPolicy: {
                text: '',
            },
            poweredBy: '',
            acceptSelected: 'Einstellungen speichern',
        },
    },
    apps: [
        {
            name: 'default',
            title: 'Grundlegende Website-Funktionen',
            purposes: ['default'],
            required: true,
        },
        {
            name: 'privacy',
            title: 'Speichern von Datenschutzeinstellungen',
            purposes: ['default'],
            required: true,
        },
        {
            name: 'matomo',
            title: 'Statistik',
            purposes: ['analytics'],
            required: false,
        },
    ],
}

// @todo Duplicate fn
const getCookie = (name: string) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`) as Array<any>
    if (parts.length === 2) return parts.pop().split(';').shift()
}

export const initKlaro = () => {
    const manager = klaro.getManager(config)
    if (!manager || !manager.consents || getCookie('klaro') === undefined) {
        klaro.renderKlaro(config, true, false)
    }
}

export const showKlaroSettings = () => {
    klaro.renderKlaro(config, true, true)
}

/**
 * Provide syntactical sugar in React environments
 */
const Klaro: FC = () => {
    useEffect(() => {
        initKlaro()
    }, [])

    return null
}

export default Klaro
