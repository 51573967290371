import { GlobalState } from '../state.props'
import { FaqQuestion, FaqTopic } from './faq-topics.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */

// FAQ TOPICS

export const getFaqTopics = (state: GlobalState): FaqTopic[] | undefined =>
    state.faqTopics.data.sort((a, b) => a.position - b.position)

export const getFaqTopicById = (state: GlobalState, id: number): FaqTopic | undefined =>
    state.faqTopics.data.find((faqTopic) => {
        if (faqTopic.id === id) {
            faqTopic.faqQuestions = faqTopic.faqQuestions.sort((a, b) => a.position - b.position)
            return true
        }
        return false
    })

export const getFaqTopicBySlug = (state: GlobalState, slug: string): FaqTopic | undefined =>
    state.faqTopics.data.find((faqTopic) => faqTopic.seo?.slug === slug)

export const getFaqTopicsIsPending = (state: GlobalState): boolean => state.faqTopics.isPending

// FAQ QUESTIONS

export const getFaqQuestionById = (state: GlobalState, id: number): FaqQuestion | undefined => {
    let faqQuestion = undefined
    state.faqTopics.data.forEach((topic) => {
        const q = topic.faqQuestions.find((question) => question.id === id)
        if (q) faqQuestion = q
    })
    return faqQuestion
}

export const getFaqQuestionBySlug = (state: GlobalState, slug: string): FaqQuestion | undefined => {
    let faqQuestion = undefined
    state.faqTopics.data.forEach((topic) => {
        const q = topic.faqQuestions.find((question) => question.seo && question.seo.slug === slug)
        if (q) faqQuestion = q
    })
    return faqQuestion
}
