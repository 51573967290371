export const getCookie = (key: string): string | null => {
    // U guessed it... credits go to https://stackoverflow.com/a/24103596
    const nameEQ = key + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export const setCookie = (key: string, val: string, days: number = 999): void => {
    const date = new Date()
    date.setDate(date.getDate() + days)
    document.cookie = `${key}=${val}; expires=${date.toUTCString()}; path=/`
}

export const setOneDayCookie = (key: string, val: string): void => setCookie(key, val, 1)

export const deleteCookie = (key: string): void => {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
}
