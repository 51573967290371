import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { isValidPassword } from '../../../components/chatbot/chatbot-dialogues/chatbot-authentication-dialogues'
import VerificationForm from '../../../components/verification-form'
import useCaptcha from '../../../hooks/use-captcha'
import useSeo from '../../../hooks/use-seo'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { User, VerificationTarget } from '../../../store/user/user.props'
import { APIError } from '../../../utils/api'
import {
    defaultInputFieldAttributes,
    getUnsanitizedPageText,
    sanitizeText,
} from '../../../utils/configs'
import { deleteCookie, getCookie, setOneDayCookie } from '../../../utils/cookies'
import { isInvalidCaptchaError } from '../../../utils/errors'
import { submitPasswordReset } from './reset-password.actions'

const COOKIE_RESET_KEY = 'reset_password_ongoing'

const ResetPassword: FC = () => {
    const [captchaToken, captchaImageUrl, fetchCaptcha] = useCaptcha()
    const navigate = useNavigate()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_RESET_PASSWORD)
    )
    const [formState, setFormState] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [passwordNew1, setPasswordNew1] = useState<string>('')
    const [passwordNew2, setPasswordNew2] = useState<string>('')
    const [captchaCode, setCaptchaCode] = useState<string>('')

    useEffect(() => {
        const cookie = getCookie(COOKIE_RESET_KEY)
        if (cookie) {
            setUsername(sanitizeText(cookie))
            setFormState('VERIFY')
        }
    }, [])

    // Update canonical link
    useSeo({
        title: 'Passwort vergessen',
        metaTags: [],
        slug: '/passwort-vergessen',
    })

    const reloadCaptcha = () =>
        fetchCaptcha().then(() => {
            setCaptchaCode('')
        })

    const onChangeUsername = (ev: ChangeEvent<HTMLInputElement>) => {
        setUsername(ev.currentTarget.value)
    }

    const onChangePasswordNew1 = (ev: ChangeEvent<HTMLInputElement>) => {
        setPasswordNew1(ev.currentTarget.value)
    }

    const onChangePasswordNew2 = (ev: ChangeEvent<HTMLInputElement>) => {
        setPasswordNew2(ev.currentTarget.value)
    }

    const onChangeCaptchaCode = (ev: ChangeEvent<HTMLInputElement>) => {
        setCaptchaCode(ev.target.value)
    }

    const onClickReloadCaptcha = (ev: MouseEvent) => {
        reloadCaptcha()
        ev.preventDefault()
    }

    const onSubmit = (ev: MouseEvent) => {
        submitPasswordReset(username, passwordNew1, {
            token: captchaToken as string,
            phrase: captchaCode as string,
        })
            .then(() => {
                setFormState('VERIFY')
                // Store cookie for 24h to allow later SMS token confirmation
                setOneDayCookie(COOKIE_RESET_KEY, username)
            })
            .catch((error: APIError) => {
                reloadCaptcha()
                if (error.error?.code && error.error.code === 400) {
                    if (isInvalidCaptchaError(error)) {
                        setFormState(
                            `Der eingegebene Sicherheitscode ist ungültig. Bitte erneut probieren.`
                        )
                    } else {
                        setFormState(
                            `Der Nutzer konnte nicht gefunden werden oder ist nicht berechtigt, das Passwort zu ändern.`
                        )
                    }
                } else {
                    setFormState(
                        `Es ist ein unbekannter Fehler aufgetreten. Wurde das Passwort möglicherweise bereits kürzlich zurückgesetzt?`
                    )
                }
            })
        ev.preventDefault()
    }

    const onVerificationSuccess = (user: User) => {
        // Delete 24h cookie
        deleteCookie(COOKIE_RESET_KEY)
        alert(
            `Dein Passwort wurde erfolgreich zurückgesetzt! Du wirst jetzt zur Login-Seite weitergeleitet.`
        )
        navigate('/auth')
    }

    return (
        <div className="page page--settings-phone-nbr">
            <div className="page-header">
                <h1 className="title-black">Passwort vergessen</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <form className="password-form page-wrapper" method="POST" action="#">
                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="password-form__username">
                        Benutzername
                    </label>
                    <input
                        type="text"
                        id="password-form__username"
                        name="password-form__username"
                        required
                        placeholder="Dein Benutzername"
                        defaultValue={username}
                        onChange={onChangeUsername}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="password-form__password1">
                        Neues Passwort (mind. 8 Zeichen)
                    </label>
                    <input
                        type="password"
                        id="password-form__password1"
                        name="password-form__password1"
                        required
                        placeholder="******"
                        value={passwordNew1}
                        onChange={onChangePasswordNew1}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="password-form__password2">
                        Neues Passwort (Bestätigen)
                    </label>
                    <input
                        type="password"
                        id="password-form__password2"
                        name="password-form__password2"
                        required
                        placeholder="******"
                        value={passwordNew2}
                        onChange={onChangePasswordNew2}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper captcha-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-form__lastname">
                        Captcha*
                    </label>
                    <div className="captcha-field">
                        <img
                            alt="Lade Captcha..."
                            src={captchaImageUrl || ''}
                            width="800"
                            height="200"
                        />
                        <button
                            title="Neues Captcha laden"
                            onClick={onClickReloadCaptcha}
                            type="button"
                        ></button>
                    </div>

                    <input
                        type="text"
                        id="contact-form__captcha"
                        name="contact-form__captcha"
                        value={captchaCode}
                        required={true}
                        placeholder={`Code`}
                        onChange={onChangeCaptchaCode}
                    />
                </div>

                {formState !== '' && formState !== 'VERIFY' && (
                    <p className="form-error input-wrapper">{formState}</p>
                )}

                <div className="form__buttons">
                    <Link to="/auth" className="button button-secondary">
                        Abbrechen
                    </Link>

                    <button
                        type="submit"
                        onClick={onSubmit}
                        disabled={
                            formState === 'VERIFY' ||
                            !(
                                username !== '' &&
                                passwordNew1 !== '' &&
                                passwordNew1 === passwordNew2 &&
                                isValidPassword(passwordNew1) &&
                                captchaCode !== ''
                            )
                        }
                        className="button button-submit"
                    >
                        Passwort ändern
                    </button>
                </div>
            </form>
            {formState === 'VERIFY' && (
                <VerificationForm
                    target={VerificationTarget.PASSWORD_RESET}
                    username={username}
                    callback={onVerificationSuccess}
                />
            )}
        </div>
    )
}

export default ResetPassword
