import { AnyAction } from 'redux'

import {
    ADD_BLOG_ARTICLE,
    ADD_BLOG_ARTICLES,
    FETCH_BLOG_ARTICLES_PENDING,
    FLUSH_BLOG_ARTICLES,
    UPDATE_BLOG_PAGINATION,
} from './blog-articles.actions'
import { BlogArticlesState } from './blog-articles.props'

const initialState: BlogArticlesState = {
    data: [],
    isPending: false,
    pagination: {
        nextPage: 0,
    },
}

const BlogArticlesReducer = (
    state: BlogArticlesState = initialState,
    action: AnyAction
): BlogArticlesState => {
    switch (action.type) {
        case FETCH_BLOG_ARTICLES_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case ADD_BLOG_ARTICLE:
            return {
                ...state,
                isPending: false,
                data: [...state.data, action.blogArticle.data],
            }
        case ADD_BLOG_ARTICLES:
            return {
                ...state,
                isPending: false,
                data: [...state.data, ...action.blogArticles.data],
            }
        case UPDATE_BLOG_PAGINATION:
            return {
                ...state,
                pagination: {
                    // nextPage may be undefined!
                    nextPage: action.pagination.next,
                },
            }
        case FLUSH_BLOG_ARTICLES:
            return initialState
    }

    return state
}

export default BlogArticlesReducer
