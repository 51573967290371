import React, { FC } from 'react'
import { Offline, PollingConfig } from 'react-detect-offline'

const inBrowser = typeof navigator !== 'undefined'

// These browsers don't fully support navigator.onLine, so we need to use a polling backup
// @see https://developer.mozilla.org/de/docs/Web/API/NavigatorOnLine/onLine#Browserkompatibilit%C3%A4t
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/

const polling: PollingConfig = {
    enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
    url: '/1x1.png', // OR use API-provided ping endpoint `${getApiUrl()}/keep-alive`,
    interval: 10000,
    timeout: 8000,
}

const OfflineNotification: FC = () => (
    <Offline polling={polling}>
        <aside
            className="component--offline-notification"
            title="Es besteht keine Netzwerkverbindung. Einige Seiten werden möglicherweise nicht wie gewünscht funktionieren."
        >
            <p>Keine Netzwerkverbindung.</p>
        </aside>
    </Offline>
)

export default OfflineNotification
