import React, { FC } from 'react'

import { IModal } from './modal.props'

const Modal: FC<IModal> = ({ setModalOpen, children }) => (
    <aside className="component--modal">
        <div className="modal-overlay">
            <div className="modal-content">
                <button
                    className="button-close-blue"
                    title="Fenster schliessen"
                    onClick={() => {
                        setModalOpen(false)
                    }}
                ></button>
                {children}
            </div>
        </div>
    </aside>
)

export default Modal
