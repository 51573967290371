import { FC, useEffect } from 'react'

import { useFideoDispatch } from '../hooks/use-fideo-dispatch'
import { setShowWebappInstallDialogue } from '../store/user/user.actions'
import { getShowWebappInstallDialogue } from './A2HS'

/**
 * Runs once after "createStore" is called.
 * Includes all async functions that shall
 * be run after app start.
 */
const AsyncStoreInit: FC = () => {
    const dispatch = useFideoDispatch()

    useEffect(() => {
        getShowWebappInstallDialogue().then((showWebappInstallDialogue: boolean) => {
            dispatch(setShowWebappInstallDialogue(showWebappInstallDialogue))
        })
    }, [])

    return null
}

export default AsyncStoreInit
