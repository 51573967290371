import cn from 'classnames'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import BlogArticleListItem from '../../components/blog/blog-article-list-item'
import ButtonLoadMore from '../../components/button-load-more'
import ChatThreadListItem from '../../components/chat/chat-thread-list-item'
import FaqQuestionListItem from '../../components/faq/faq-question-list-item'
import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSeo from '../../hooks/use-seo'
import useSessionUser from '../../hooks/use-session-user'
import { fetchBlogArticlesByQuery } from '../../store/blog-articles/blog-articles.actions'
import { fetchChatThreadsByQuery } from '../../store/chat-threads/chat-threads.actions'
import { fetchFaqQuestionsByQuery } from '../../store/faq-top-questions/faq-top-questions.actions'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText, sanitize } from '../../utils/configs'
import {
    SearchArticleResult,
    SearchChatThreadsResult,
    SearchFaqQuestionsResult,
} from './search.props'

/**
 * Search view component
 * Regular dispatch() used to fetch data from endpoint; however results are stored in local state
 *
 * @todo Improve semantics: Container-switch should at least have ul li or regular anchors, add ARIA labels and state
 */
const Search: FC = () => {
    const [filter, setFilter] = useState<string>('chat')
    const [responseSuccess, setResponseSuccess] = useState<boolean>(true)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useFideoDispatch()
    const sessionUser = useSessionUser()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_SEARCH_RESULTS)
    )

    const [chatThreads, setChatThreads] = useState<SearchChatThreadsResult | null>(null)
    const [blogArticles, setBlogArticles] = useState<SearchArticleResult | null>(null)
    const [faqQuestions, setFaqQuestions] = useState<SearchFaqQuestionsResult | null>(null)

    const query = sanitize(location.search.trim().replace('?q=', '')).toLowerCase()
    const queryWUppercase = query.charAt(0).toUpperCase() + query.slice(1)

    const _fetchChatThreads = () => () => {
        if (query.length <= 2) {
            return
        }

        dispatch(
            fetchChatThreadsByQuery(
                query,
                chatThreads?.pagination.next && chatThreads.query === query
                    ? chatThreads.pagination.next
                    : 0
            )
        )
            .then((data) => {
                if (chatThreads && chatThreads.query === query) {
                    data.data = [...chatThreads.data, ...data.data]
                }

                data.query = query
                setChatThreads(data)
            })
            .catch(() => {
                setResponseSuccess(false)
            })
    }

    const _fetchBlogArticles = () => () => {
        if (query.length <= 2) {
            return
        }

        dispatch(
            fetchBlogArticlesByQuery(
                query,
                blogArticles?.pagination.next && blogArticles.query === query
                    ? blogArticles.pagination.next
                    : 0
            )
        )
            .then((data) => {
                if (blogArticles && blogArticles.query === query) {
                    data.data = [...blogArticles.data, ...data.data]
                }

                data.query = query
                setBlogArticles(data)
            })
            .catch(() => {
                setResponseSuccess(false)
            })
    }

    const _fetchFaqQuestions = () => () => {
        if (query.length <= 2) {
            return
        }

        dispatch(
            fetchFaqQuestionsByQuery(
                query,
                faqQuestions?.pagination.next && faqQuestions.query === query
                    ? faqQuestions.pagination.next
                    : 0
            )
        )
            .then((data) => {
                if (faqQuestions && faqQuestions.query === query) {
                    data.data = [...faqQuestions.data, ...data.data]
                }

                data.query = query
                setFaqQuestions(data)
            })
            .catch(() => {
                setResponseSuccess(false)
            })
    }

    useEffect(() => {
        // Poor man's query highlighter
        document
            .querySelectorAll('.search-result-list h2, .blog-card-content p')
            .forEach((node: any) => {
                node.innerHTML = node.innerText
                    .replace(new RegExp(query, 'g'), `<span class="highlight">${query}</span>`)
                    .replace(
                        new RegExp(queryWUppercase, 'g'),
                        `<span class="highlight">${queryWUppercase}</span>`
                    )
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatThreads, blogArticles, faqQuestions, filter])

    useEffect(() => {
        if (!query || query.length === 0 || query.indexOf('?') >= 0) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        switch (filter) {
            case 'chat':
                if (!chatThreads || chatThreads.query !== query) {
                    _fetchChatThreads()()
                }
                break

            case 'blog':
                if (!blogArticles || blogArticles.query !== query) {
                    _fetchBlogArticles()()
                }
                break

            case 'faq':
                if (!faqQuestions || faqQuestions.query !== query) {
                    _fetchFaqQuestions()()
                }
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, filter])

    // Update canonical link
    useSeo({
        title: 'Suche',
        metaTags: [],
        slug: '/suche',
    })

    if (query.length <= 2) {
        return (
            <div className="page page--search">
                <h1 className="title-black">Suche nach: {query}</h1>
                <p>
                    Suchbegriffe müssen <strong>mindestens drei Zeichen</strong> enthalten.
                </p>
            </div>
        )
    }

    if (!responseSuccess) {
        const reloadPage = (ev: MouseEvent) => {
            // eslint-disable-next-line no-restricted-globals
            window.location.reload()
            ev.preventDefault()
        }

        return (
            <div className="page page--search">
                <div className="page-header">
                    <h1 className="title-black">Suche: Fehler beim Laden</h1>
                    <p>
                        Beim Laden der Suchergebnisse ist ein Fehler aufgetreten. Bitte gehe zurück
                        oder{' '}
                        <a href="#" onClick={reloadPage}>
                            lade die Seite neu
                        </a>
                        .
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="page page--search">
            <div className="page-header">
                <h1 className="title-black">Suche nach: {query}</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <div>
                <div className="flex-container-switch">
                    <div
                        className={cn('flex-item-switch', {
                            'flex-item-switch--active': filter === 'chat',
                        })}
                        onClick={() => setFilter('chat')}
                    >
                        Chat
                    </div>
                    <div
                        className={cn('flex-item-switch', {
                            'flex-item-switch--active': filter === 'faq',
                        })}
                        onClick={() => setFilter('faq')}
                    >
                        Antworten
                    </div>
                    <div
                        className={cn('flex-item-switch', {
                            'flex-item-switch--active': filter === 'blog',
                        })}
                        onClick={() => setFilter('blog')}
                    >
                        Blog
                    </div>
                </div>

                <div className="search-result-list">
                    {filter === 'chat' && (
                        <>
                            {chatThreads?.data.map((chatThread) => (
                                <div
                                    key={chatThread.id}
                                    className={cn(
                                        'search-result-list-item',
                                        'full-width--search',
                                        'search-result-list-item--chat'
                                    )}
                                >
                                    <ChatThreadListItem
                                        chatThread={chatThread}
                                        sessionUserName={sessionUser?.username}
                                    />
                                </div>
                            ))}
                            {chatThreads?.pagination.next && (
                                <ButtonLoadMore dispatchAction={() => _fetchChatThreads()} />
                            )}
                            {chatThreads?.pagination && chatThreads.data.length === 0 && (
                                <NoItemFound />
                            )}
                            {chatThreads === null && <Loading />}
                        </>
                    )}

                    {filter === 'faq' && (
                        <>
                            {faqQuestions?.data.map((question) => (
                                <div
                                    key={question.id}
                                    className={cn(
                                        'search-result-list-item',
                                        'full-width--search',
                                        'search-result-list-item--faq'
                                    )}
                                >
                                    <FaqQuestionListItem question={question} />
                                </div>
                            ))}
                            {faqQuestions?.pagination.next && (
                                <ButtonLoadMore dispatchAction={() => _fetchFaqQuestions()} />
                            )}
                            {faqQuestions?.pagination && faqQuestions.data.length === 0 && (
                                <NoItemFound />
                            )}
                            {faqQuestions === null && <Loading />}
                        </>
                    )}

                    {filter === 'blog' && (
                        <>
                            {blogArticles?.data.map((blogArticle) => (
                                <div
                                    key={blogArticle.id}
                                    className={cn(
                                        'search-result-list-item',
                                        'full-width--search',
                                        'search-result-list-item--blog'
                                    )}
                                >
                                    <BlogArticleListItem article={blogArticle} />
                                </div>
                            ))}
                            {blogArticles?.pagination.next && (
                                <ButtonLoadMore dispatchAction={() => _fetchBlogArticles()} />
                            )}
                            {blogArticles?.pagination && blogArticles.data.length === 0 && (
                                <NoItemFound />
                            )}
                            {blogArticles === null && <Loading />}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export const NoItemFound: FC = () => (
    <p className="text-center" style={{ fontWeight: 'bold' }}>
        Kein Ergebnis gefunden.
    </p>
)

export default Search
