import { Location } from 'history'
import { useSelector } from 'react-redux'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

import { getPageIs404 } from '../../../store/layout/layout.selectors'
import NavbarLink from '../navbar/navbar.props'
import { getNavbarLinkBySlug } from '../navbar/navbar.view'

export interface UseTitlebar {
    activeLocation: NavbarLink
    navigate: NavigateFunction
    location: Location
    pageIs404: boolean
}

const useTitlebar = (): UseTitlebar => {
    const pageIs404 = useSelector(getPageIs404)
    const navigate = useNavigate()
    const location = useLocation()
    const activeLocation = getNavbarLinkBySlug(location.pathname)

    document.body.setAttribute(
        'data-page',
        pageIs404 ? '404' : activeLocation.slug.replace('/', '')
    )

    window.requestAnimationFrame(() => {
        window.scrollTo(0, 0)
    })

    return {
        activeLocation,
        navigate,
        location,
        pageIs404,
    }
}

export default useTitlebar
