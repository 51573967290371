import cn from 'classnames'
import React, { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { editUserProfile } from '../../../store/user/user.actions'
import { defaultInputFieldAttributes, getUnsanitizedPageText } from '../../../utils/configs'

const SettingsContactDetails: FC = () => {
    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_SETTINGS_CONTACT_DETAILS)
    )
    const sessionUser = useSessionUser()
    const [formState, setFormState] = useState<string>('')
    const [email, setEmail] = useState<string>(sessionUser?.email || '')
    const [city, setCity] = useState<string>(sessionUser?.residence || '')

    const onChangeEmail = (ev: ChangeEvent<HTMLInputElement>) => {
        setEmail(ev.currentTarget.value)
    }

    const onChangeCity = (ev: ChangeEvent<HTMLInputElement>) => {
        setCity(ev.currentTarget.value)
    }

    const onSubmit = (ev: MouseEvent) => {
        setFormState('')

        dispatch(editUserProfile(email, city))
            .then(() => {
                setFormState(`Kontaktdetails erfolgreich bearbeitet.`)
            })
            .catch(() => {
                setFormState(`Es gab ein Problem beim Versenden des Formulars.`)
            })

        ev.preventDefault()
    }

    return (
        <div className="page page--settings-phone-nbr">
            <div className="page-header">
                <h1 className="title-black">Kontaktdetails bearbeiten</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <form className="contact-details-form page-header" method="POST" action="#">
                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-details-form__email">
                        Deine E-Mail Adresse
                    </label>
                    <input
                        type="email"
                        id="contact-details-form__email"
                        name="contact-details-form__email"
                        value={email}
                        onChange={onChangeEmail}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-details-form__city">
                        Dein Wohnort
                    </label>
                    <input
                        type="text"
                        id="contact-details-form__city"
                        name="contact-details-form__city"
                        value={city}
                        onChange={onChangeCity}
                    />
                </div>

                {formState !== '' && (
                    <p
                        className={cn(
                            'form-error input-wrapper',
                            formState.indexOf('erfolgreich') >= 0 ? 'form-error--success' : null
                        )}
                    >
                        {formState}
                    </p>
                )}

                <div className="form__buttons">
                    <Link to="/einstellungen" className="button button-secondary">
                        Abbrechen
                    </Link>

                    <button
                        type="submit"
                        disabled={
                            !(sessionUser?.email !== email || sessionUser?.residence !== city)
                        }
                        onClick={onSubmit}
                        className="button button-submit"
                    >
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SettingsContactDetails
