import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import useSessionUser from '../../../hooks/use-session-user'

const ChatThreadAddButton: FC = () => {
    const sessionUser = useSessionUser()

    const addChatThreadLink = sessionUser ? `/chat/beitrag/hinzufuegen` : `/auth`
    const addChatThreadLinkState = sessionUser
        ? undefined
        : { destination: `/chat/beitrag/hinzufuegen` }

    const addGroupChatLink = sessionUser ? `/chat/gruppe/hinzufuegen` : `/auth`
    const addGroupChatLinkState = sessionUser
        ? undefined
        : { destination: `/chat/gruppe/hinzufuegen` }

    return (
        <>
            <div className="component--chat-thread-add-button">
                <Link
                    to={addChatThreadLink}
                    state={addChatThreadLinkState}
                    className="button button-center"
                >
                    Eigenen Beitrag schreiben
                </Link>
            </div>
            <div className="component--chat-thread-add-button">
                <Link
                    to={addGroupChatLink}
                    state={addGroupChatLinkState}
                    className="button button-center button-hollow"
                >
                    Gruppenchat anlegen
                </Link>
            </div>
        </>
    )
}

export default ChatThreadAddButton
