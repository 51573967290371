const stripTrailingSlash = (url: string) => url.replace(/\/$/, '')

export const sanitize = (url: string) => stripTrailingSlash(url)

export const sanitizeText = (text: string): string => {
    const div = document.createElement('div')
    div.innerText = text
    return div.innerText
}

export const noop = () => false

export const makeRandomID = (): string => Math.random().toString(36).substr(2, 9)

export const makeRandomString = (stringLength: number = 8): string => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'
    let randomString = ''

    for (let i = 0; i < stringLength; i++) {
        const rnum = Math.floor(Math.random() * chars.length)
        randomString += chars.substring(rnum, rnum + 1)
    }

    return randomString
}

interface UnsanitizedText {
    __html: string
}

export const getUnsanitizedPageText = (content: string | undefined): UnsanitizedText => ({
    __html: content || '',
})

/**
 * Attach attributes to input field in order to skip client-side keyboard features.
 */
export const defaultInputFieldAttributes = {
    spellCheck: false,
    autoCapitalize: 'off',
    autoCorrect: 'off',
}

export enum Environment {
    Production = 'production',
    Development = 'development',
}

export const getEnvironment = (): Environment => process.env.NODE_ENV as Environment
