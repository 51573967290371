import { getApiToken, getApiUrl, handleFetchErrorAsObject } from '../../../utils/api'

export interface IDeleteAccountRequest {
    keepContent: boolean
}

export const postDeleteAccount = (body: IDeleteAccountRequest): Promise<any> =>
    fetch(`${getApiUrl()}/user/delete-account`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': getApiToken(),
        },
        body: JSON.stringify(body),
    })
        .then(handleFetchErrorAsObject)
        .then((json) => json)
        .catch((error) => {
            return Promise.reject(error)
        })
