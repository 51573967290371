import { AnyAction } from 'redux'

import { RESET_SESSION } from '../user/user.actions'
import {
    ADD_CHAT_TOPIC,
    ADD_CHAT_TOPICS,
    EDIT_CHAT_TOPIC,
    FETCH_CHAT_THREADS_BY_CHAT_TOPIC_ID_SUCCESS,
    FETCH_CHAT_TOPICS_ERROR,
    FETCH_CHAT_TOPICS_PENDING,
    RESET_CHAT_TOPICS,
} from './chat-topics.actions'
import { ChatTopic, ChatTopicsState } from './chat-topics.props'

const initialState: ChatTopicsState = {
    data: [],
    isPending: false,
}

const ChatTopicsReducer = (
    state: ChatTopicsState = initialState,
    action: AnyAction
): ChatTopicsState => {
    switch (action.type) {
        case FETCH_CHAT_TOPICS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case FETCH_CHAT_TOPICS_ERROR:
            // @todo Implement error codes/handling
            return {
                ...state,
                isPending: false,
            }
        case EDIT_CHAT_TOPIC:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatTopic: ChatTopic) => {
                    if (chatTopic.id === action.chatTopic.data.id) {
                        return action.chatTopic.data
                    }
                    return chatTopic
                }),
            }
        case ADD_CHAT_TOPIC:
            return {
                ...state,
                isPending: false,
                data: [...state.data, action.chatTopic.data],
            }
        case ADD_CHAT_TOPICS:
            return {
                ...state,
                isPending: false,
                data: [...state.data, ...action.chatTopics.data],
            }
        case RESET_CHAT_TOPICS:
            return {
                isPending: false,
                data: action.chatTopics.data,
            }
        case FETCH_CHAT_THREADS_BY_CHAT_TOPIC_ID_SUCCESS:
            return {
                ...state,
                isPending: false,
                data: state.data.map((chatTopic: ChatTopic) => {
                    if (chatTopic.id === action.chatTopicId) {
                        return {
                            ...chatTopic,
                            chatThreads: action.chatThreads.data,
                        }
                    }
                    return chatTopic
                }),
            }
        case RESET_SESSION:
            return initialState
    }

    return state
}

export default ChatTopicsReducer
