import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../store/state.props'
import ChatbotWidgetOverlay from '../../chatbot/chatbot-widget-overlay'
import ChatbotWidgetSwitch from '../../chatbot/chatbot-widget-switch'

const ChatbotWidget: FC = () => {
    const [chatbotWidgetIsVisible, toggleChatbotWidgetIsVisible] = useState<boolean>(false)
    const userSession = useSelector((state: GlobalState) => state.userSession)

    useEffect(() => {
        if (chatbotWidgetIsVisible) {
            // Avoid calculation mistakes in VerticalDragArea
            window.scrollTo(0, 0)
        }
    }, [chatbotWidgetIsVisible])

    return (
        <aside className="component--chatbot-widget">
            {chatbotWidgetIsVisible && (
                <ChatbotWidgetOverlay
                    toggleChatbotWidgetIsVisible={toggleChatbotWidgetIsVisible}
                    showWebappInstallDialogue={userSession.showWebappInstallDialogue}
                />
            )}
            {!chatbotWidgetIsVisible && (
                <ChatbotWidgetSwitch
                    toggleChatbotWidgetIsVisible={toggleChatbotWidgetIsVisible}
                    showWebappInstallDialogue={userSession.showWebappInstallDialogue}
                />
            )}
        </aside>
    )
}

export default ChatbotWidget
