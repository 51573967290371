import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import useSessionUser from '../../../../hooks/use-session-user'
import { ChatPostAddButtonProps } from './chat-post-add-button.props'

const ChatPostAddButton: FC<ChatPostAddButtonProps> = ({ threadId, toggleFormVisibility }) => {
    const sessionUser = useSessionUser()

    const onClick = () => {
        toggleFormVisibility(true)
    }

    return (
        <div className="component--chat-post-add-button">
            {sessionUser ? (
                <button className="button button-center" onClick={onClick}>
                    Antwort schreiben
                </button>
            ) : (
                <Link
                    to={`/auth`}
                    state={{ destination: `/chat/beitrag/${threadId}?_=1` }}
                    className="button button-center"
                >
                    Antwort schreiben
                </Link>
            )}
        </div>
    )
}

export default ChatPostAddButton
