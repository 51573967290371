import React, { FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { resetSession } from '../../../store/user/user.actions'
import { getUnsanitizedPageText } from '../../../utils/configs'
import { postDeleteAccount } from './delete-account-modal.actions'
import { IDeleteAccountModal } from './delete-account-modal.props'

const DeleteAccountModal: FC<IDeleteAccountModal> = ({ setModalOpen }) => {
    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.DELETE_ACCOUNT)
    )

    const [keepContent, setKeepContent] = useState<boolean>(true)
    const [formState, setFormState] = useState<string>('')

    const onSubmit = (ev: MouseEvent) => {
        ev.preventDefault()

        postDeleteAccount({
            keepContent,
        })
            .then(() => {
                setFormState(
                    `Dein Account wurde erfolgreich gelöscht. Du wirst in wenigen Sekunden zur Registrierungsseite weitergeleitet...`
                )

                window.setTimeout(() => {
                    dispatch(resetSession())
                    setModalOpen(false)
                }, 3000)
            })
            .catch((error) => {
                setFormState(`
                Es gab ein Problem beim Löschen Deines Accounts. 
                Bitte lade die Seite neu und probiere es erneut. 
                Sollte das Problem weiterhin bestehen, nimm bitte Kontakt mit der Redaktion auf: redaktion@fideo.de
            `)
                console.log('delete account error:', error)
            })
    }

    return (
        <div className="delete-account-modal">
            <h1 className="title-black">Account löschen</h1>

            {formState !== '' && <p className="form-error input-wrapper">{formState}</p>}

            <p
                className="text-black"
                dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
            ></p>

            <div className="delete-account-modal__content">
                <label
                    className="radio-wrapper"
                    onClick={() => {
                        setKeepContent(true)
                    }}
                >
                    <input type="radio" name="delete-contents-yes" checked={keepContent} readOnly />
                    <div className="checkmark"></div>
                    <span className="radio-text">
                        Meine Beiträge und Reaktionen dürfen anonymisiert beibehalten werden.
                    </span>
                </label>

                <label
                    className="radio-wrapper"
                    onClick={() => {
                        setKeepContent(false)
                    }}
                >
                    <input
                        type="radio"
                        name="delete-contents-yes"
                        checked={!keepContent}
                        readOnly
                    />
                    <div className="checkmark"></div>
                    <span className="radio-text">
                        Meine Beiträge und Reaktionen sollen komplett gelöscht werden.
                    </span>
                </label>
            </div>

            <div className="form__buttons">
                <button
                    className="button button-secondary"
                    onClick={() => {
                        setModalOpen(false)
                    }}
                >
                    Abbrechen
                </button>
                <button type="submit" onClick={onSubmit} className="button button-submit">
                    Account löschen
                </button>
            </div>
        </div>
    )
}

export default DeleteAccountModal
