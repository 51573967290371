import { debounce } from 'lodash-es'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import { addGroupMember } from '../../../store/chat-threads/chat-threads.actions'
import { searchUsername } from '../../../store/user/user.actions'
import { SearchUsernameResponseItem } from '../../../store/user/user.props'
import { makeRandomString } from '../../../utils/configs'
import Loading from '../../loading'
import { MembersInviteDropdownProps } from './members-invite-dropdown.props'

const MembersInviteDropdown: FC<MembersInviteDropdownProps> = ({
    threadId,
    excludeMembers = [],
}) => {
    const dispatch = useFideoDispatch()
    const [dropdownID] = useState<string>(makeRandomString())
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [userSuggestionList, setUserSuggestionList] = useState<SearchUsernameResponseItem[]>([])
    const filteredUserSuggestions: SearchUsernameResponseItem[] = useMemo(
        () =>
            userSuggestionList.filter(
                (user) =>
                    !excludeMembers.some(
                        (membership) => membership.member.username === user.username
                    )
            ),
        [userSuggestionList, excludeMembers]
    )

    useEffect(() => {
        const onClickOutside = (ev: MouseEvent) => {
            const target = ev.target as HTMLElement

            if (target && !target.closest(`#${dropdownID}`)) {
                setShowDropdown(false)
            }
        }

        document.addEventListener('click', onClickOutside)

        return () => {
            document.removeEventListener('click', onClickOutside)
        }
    }, [])

    const _searchUsername = (_searchQuery: string) => {
        searchUsername(_searchQuery)
            .then((suggestions) => {
                setUserSuggestionList(suggestions)
                setShowDropdown(true)
                setIsFetching(false)
            })
            .catch(() => {
                setUserSuggestionList([])
                setIsFetching(false)
            })
    }

    const debouncedSearchUsername = useCallback(debounce(_searchUsername, 200), [])

    useEffect(() => {
        // On searchQuery change
        setUserSuggestionList([])

        if (searchQuery.length < 3) {
            setShowDropdown(false)
            return
        }

        setIsFetching(true)
        setShowDropdown(true)

        debouncedSearchUsername(searchQuery)
    }, [searchQuery])

    const onChangeInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = ev.target
        value = value.trim()
        setSearchQuery(value)
    }

    const onClickUser = (username: string) => {
        if (!window.confirm(`Nutzer:in "${username}" in Gruppenchat einladen?`)) return

        dispatch(addGroupMember(threadId, username)).catch(() => {
            alert(
                `Leider gab es Probleme beim Einladen der Nutzer:in. Bitte überprüfe Deine Internetverbinung probiere es erneut.`
            )
        })

        setSearchQuery('')
        setShowDropdown(false)
    }

    const showUserSuggestions = () => {
        if (userSuggestionList.length >= 1) {
            setShowDropdown(true)
        }
    }

    return (
        <div className="dropdown" id={dropdownID}>
            <label className="label-hashtag-link">Nutzer:in einladen</label>
            <input
                type="text"
                placeholder={`Benutzernamen eintippen, um Vorschläge zu erhalten`}
                onChange={onChangeInput}
                value={searchQuery}
                onFocus={() => {
                    showUserSuggestions()
                }}
            />
            {showDropdown && (
                <ul className="dropdown__list">
                    {isFetching ? (
                        <Loading />
                    ) : filteredUserSuggestions.length >= 1 ? (
                        filteredUserSuggestions.map((user) => (
                            <li key={user.username} onClick={() => onClickUser(user.username)}>
                                <p>{user.username}</p>
                                <span className="meta-text">Einladen</span>
                            </li>
                        ))
                    ) : (
                        <li>
                            <p className="text-center">Keine Ergebnisse gefunden.</p>
                        </li>
                    )}
                </ul>
            )}
        </div>
    )
}

export default MembersInviteDropdown
