import React, { ChangeEvent, FC, MouseEvent, useState } from 'react'

import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSessionUser from '../../hooks/use-session-user'
import { verifyUser } from '../../store/user/user.actions'
import { User, VerificationTarget } from '../../store/user/user.props'
import { noop } from '../../utils/configs'
import { isValidCode } from '../chatbot/chatbot-dialogues/chatbot-authentication-dialogues'

interface IVerificationForm {
    target: VerificationTarget
    username?: string | null
    callback?: (user: User) => void
}

const VerificationForm: FC<IVerificationForm> = ({ target, username, callback = noop }) => {
    const dispatch = useFideoDispatch()
    const [formState, setFormState] = useState<string>('')
    const [token, setToken] = useState<string>('')
    const sessionUser = useSessionUser()

    const onSubmit = (ev: MouseEvent) => {
        if (isValidCode(token)) {
            dispatch(
                verifyUser(target, username ? username : (sessionUser as User).username, token)
            )
                .then((json) => {
                    callback(json.data)
                })
                .catch(() => {
                    setFormState(`Der eingegebene Bestätigungscode ist ungültig.`)
                })
        }

        ev.preventDefault()
    }

    const onChangeToken = (ev: ChangeEvent<HTMLInputElement>) => {
        setToken(ev.currentTarget.value)
    }

    return (
        <form className="component--verification-form page-wrapper">
            <div className="input-wrapper">
                <label className="label-hashtag-link" htmlFor="verification-token">
                    Super. Du solltet eine SMS zur Freigabe erhalten haben. Gib den Code hier ein:
                </label>
                <input
                    type="text"
                    id="verification-token"
                    name="verification-token"
                    onChange={onChangeToken}
                />
            </div>

            {formState !== '' && <p className="form-error input-wrapper">{formState}</p>}

            <div className="form__buttons">
                <button
                    type="submit"
                    onClick={onSubmit}
                    disabled={!isValidCode(token)}
                    className="button button-submit"
                >
                    Code absenden
                </button>
            </div>
        </form>
    )
}

export default VerificationForm
