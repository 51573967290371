import cn from 'classnames'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { GroupChatNewsType } from '../../../store/chat-threads/chat-threads.props'
import {
    getChatNewsById,
    getMarkedChatNewsTypes,
} from '../../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../../store/state.props'
import { calendarFormatLowercase } from '../../../utils/format'
import ChatGroupIndicator from '../chat-group-indicator'
import ChatPostListItem from '../chat-post-list-item'
import { ChatThreadListItemProps } from './chat-thread-list-item.props'

const ChatThreadListItem: FC<ChatThreadListItemProps> = ({
    chatThread,
    sessionUserName,
    chatPost = undefined,
    showChatNumsForType = 'public',
}) => {
    const isGroupChat = chatThread.type === 'group'
    const chatNews = useSelector((state: GlobalState) => getChatNewsById(state, chatThread.id))
    const isOwnThread = chatThread.creatorName === sessionUserName
    const latestUpdateAt = useMemo(
        () => (chatThread.latestUpdateAt !== '' ? moment(chatThread.latestUpdateAt) : null),
        [chatThread.latestUpdateAt]
    )
    const hasRecentUpdates = useMemo(
        () => (latestUpdateAt ? moment().diff(latestUpdateAt, 'days') <= 14 : false),
        [latestUpdateAt]
    )

    const markedChatNews =
        chatNews && chatThread.type === 'group' && sessionUserName
            ? getMarkedChatNewsTypes(chatNews, sessionUserName)
            : []

    const newPostsNum = chatNews
        ? showChatNumsForType === 'group'
            ? markedChatNews.length
            : chatNews.newPostsNum >= 1
            ? chatNews.newPostsNum
            : 0
        : 0

    const chatThreadLink =
        showChatNumsForType === 'group' &&
        isOwnThread &&
        newPostsNum >= 1 &&
        markedChatNews[0].type === GroupChatNewsType.MEMBER_REQUESTED
            ? `/chat/gruppe/${chatThread.id}/bearbeiten`
            : `/chat/beitrag/${chatThread.id}`

    return (
        <div
            className={cn(
                'component--chat-thread-list-item component--chat-article-item chat-card',
                {
                    'chat-card-user': isOwnThread,
                    'chat-card--unread': newPostsNum >= 1,
                }
            )}
        >
            <div className="chat-card-wrapper">
                {isOwnThread ? (
                    <div className="user">
                        {newPostsNum >= 1 && <div className="badge">{newPostsNum}</div>}
                    </div>
                ) : (
                    <p className="label-nickname">
                        {chatThread.creatorName}
                        {showChatNumsForType === 'group' && newPostsNum >= 1 && (
                            <div className="badge badge--invert">{newPostsNum}</div>
                        )}
                    </p>
                )}
                <p className="meta-text">
                    {moment(chatThread.createdAt).format('DD.MM.YYYY')}
                    {isOwnThread ? (
                        <Link
                            to={`/chat/${isGroupChat ? 'gruppe' : 'beitrag'}/${
                                chatThread.id
                            }/bearbeiten`}
                            title={`${isGroupChat ? 'Gruppenchat' : 'Beitrag'} bearbeiten`}
                        />
                    ) : (
                        ''
                    )}
                </p>
            </div>
            <Link to={chatThreadLink} className="h2 card-pseudo-link">
                {chatThread.title}
            </Link>
            {chatPost && (
                <ChatPostListItem
                    chatPost={chatPost}
                    sessionUserName={sessionUserName}
                    newPostsNum={
                        chatNews && chatNews.newPostsNum >= 1 ? chatNews.newPostsNum : undefined
                    }
                />
            )}
            <div className="border-bottom"></div>
            <div className="chat-card-wrapper">
                {showChatNumsForType !== 'group' ? (
                    <p className="meta-text text-blue-mid">
                        {chatThread.chatPostsNum === 0
                            ? 'Keine'
                            : chatNews && chatNews.newPostsNum >= 1
                            ? chatThread.chatPostsNum - chatNews.newPostsNum < 0
                                ? 0
                                : chatThread.chatPostsNum - chatNews.newPostsNum
                            : chatThread.chatPostsNum}
                        {chatNews && chatNews.newPostsNum >= 1 ? ' + ' + chatNews.newPostsNum : ''}{' '}
                        Reaktionen
                        {chatThread.chatPostsNum >= 1 && latestUpdateAt && (
                            <span
                                className={cn('recent-updates', hasRecentUpdates ? 'active' : '')}
                                title={`Neueste Reaktion am ${latestUpdateAt.format('DD.MM.YYYY')}`}
                            >
                                |{' '}
                                <strong>
                                    neueste{' '}
                                    {latestUpdateAt.calendar(undefined, calendarFormatLowercase)}
                                </strong>
                            </span>
                        )}
                    </p>
                ) : newPostsNum >= 1 ? (
                    <p
                        className="meta-text text-blue-mid"
                        dangerouslySetInnerHTML={{ __html: markedChatNews[0].type }}
                    ></p>
                ) : (
                    <p className="meta-text text-blue-mid">Chat öffnen</p>
                )}
                <p className="link-icon-blue">→</p>
            </div>
            {isGroupChat && (
                <ChatGroupIndicator chatThread={chatThread} sessionUsername={sessionUserName} />
            )}
        </div>
    )
}

export default ChatThreadListItem
