import { useEffect } from 'react'

/**
 * Prevents the page from being indexed by search engines.
 * @see https://developers.google.com/search/docs/crawling-indexing/block-indexing?hl=de
 */

const useNoIndex = (): void => {
    useEffect(() => {
        const meta = document.createElement('meta')
        meta.name = 'robots'
        meta.content = 'noindex'
        document.head.appendChild(meta)

        return () => {
            document.head.removeChild(meta)
        }
    }, [])

    // return null
}

export default useNoIndex
