import React, { FC, MouseEvent, useEffect, useRef } from 'react'

import { noop } from '../../../utils/configs'
import { ChatbotVerticalDragAreaProps } from './chatbot-vertical-drag-area.props'

/**
 * Returns vertically dragable area at top of chatbot.
 * Supports touch gestures.
 */

const defaultPaddingTop = 32

let lastDragYPosition: number = 0 // Required for onEndTouch event where page.Y is undefined
let debounce: number | null = null

const ChatbotVerticalDragArea: FC<ChatbotVerticalDragAreaProps> = ({ onClickClose }) => {
    const area = useRef<HTMLDivElement>(null)

    const onMouseMove = (ev: MouseEvent | any) => {
        let pageY = ev.pageY

        if (typeof ev.changedTouches !== 'undefined') {
            pageY = ev.changedTouches[0].pageY
        }

        if (pageY < defaultPaddingTop) {
            return
        }

        moveWidgetAt(pageY)
    }

    const moveWidgetAt = (position: number) => {
        if (typeof area.current === 'undefined') {
            return
        }

        const windowHeight: number = window.innerHeight
        const target: HTMLDivElement = (area as any).current.parentElement
        // position -= window.scrollY

        if (
            typeof target === 'undefined' ||
            position >= windowHeight ||
            lastDragYPosition === position
        ) {
            return
        }

        // If there's a pending render, cancel it
        if (debounce) {
            window.cancelAnimationFrame(debounce)
        }

        lastDragYPosition = position

        debounce = window.requestAnimationFrame(() => {
            target.style.paddingTop = `${position}px`
            // More performant approach: Increase fps by utilizing GPU via translate3d; this however requires adjustments
            // to the outter modal-wrapper in chatbot-widget-overlay.view.tsx
            // target.style.transform = "translate3d(0, " + position + "px, 0)";

            if (position <= defaultPaddingTop) {
                target.removeAttribute('data-drag-status')
            } else if (!target.hasAttribute('data-drag-status')) {
                target.setAttribute('data-drag-status', '')
                // For performance reasons, skip visual transitions
                //  const yAxisMouseToWindowRatio: number = Math.round(position / windowHeight * 10)
                //  target.setAttribute('data-drag-status', yAxisMouseToWindowRatio.toString())
            }
        })
    }

    const onMouseUp = (ev: MouseEvent | any) => {
        document.removeEventListener('touchmove', onMouseMove)
        document.removeEventListener('mousemove', onMouseMove)

        const windowHeight: number = window.innerHeight

        let pageY = ev.pageY

        if (!pageY) {
            pageY = lastDragYPosition
        }

        if (pageY >= windowHeight / 2) {
            onClickClose(ev)
        } else {
            moveWidgetAt(defaultPaddingTop)
        }

        document.onmouseup = null
        document.ontouchend = null
    }

    const onMouseDown = (ev: MouseEvent) => {
        if (typeof area.current === 'undefined') {
            return false
        }

        moveWidgetAt(ev.pageY)

        document.addEventListener('touchmove', onMouseMove)
        document.addEventListener('mousemove', onMouseMove)
        document.onmouseup = onMouseUp
        document.ontouchend = onMouseUp
        // OR:
        // document.addEventListener('mouseup', onMouseUp)
        // document.addEventListener('touchend', onMouseUp)
    }

    useEffect(() => {
        ;(area as any).current.addEventListener('mousedown', onMouseDown)
        ;(area as any).current.addEventListener('touchstart', onMouseDown)
        ;(area as any).current.addEventListener('dragstart', noop)

        return () => {
            if ((area as any).current) {
                ;(area as any).current.removeEventListener('mousedown', onMouseDown)
                ;(area as any).current.removeEventListener('touchstart', onMouseDown)
            }
        }
    })

    return (
        <div className="component--chatbot-widget-overlay__drag-area" ref={area}>
            ======
        </div>
    )
}

export default ChatbotVerticalDragArea
