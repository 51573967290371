import { AnyAction } from 'redux'

import {
    EDIT_USER,
    GET_USER_PROFILE_SUCCESS,
    HIDE_WEBAPP_INSTALL_DIALOGUE,
    LOGIN_USER_ERROR,
    LOGIN_USER_PENDING,
    LOGIN_USER_SUCCESS,
    RESET_SESSION,
    SET_PHONE_NBR_PENDING,
    SET_SHOW_WEBAPP_INSTALL_DIALOGUE,
    sessionStorage,
} from './user.actions'
import { LoginStatus, SessionUserState, User } from './user.props'

const initialState: SessionUserState = {
    token: sessionStorage.getItem('token'),
    showWebappInstallDialogue: false,
    loginStatus: LoginStatus.LOGGED_OUT,
    user: undefined,
}

const UserReducer = (
    state: SessionUserState = {
        ...initialState,
        loginStatus: LoginStatus.PENDING, // Required for inital async loading of private pages
    },
    action: AnyAction
): SessionUserState => {
    switch (action.type) {
        case LOGIN_USER_PENDING:
            return {
                ...state,
                loginStatus: LoginStatus.PENDING,
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loginStatus: LoginStatus.LOGGED_IN,
                token: action.token,
                user: action.user,
            }
        case LOGIN_USER_ERROR:
            return {
                ...state,
                loginStatus: action.LoginStatus,
            }
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                user: action.user,
                loginStatus: LoginStatus.LOGGED_IN,
            }
        case EDIT_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    phoneNumberPending: false,
                    ...action.user,
                },
            }
        case SET_PHONE_NBR_PENDING:
            return {
                ...state,
                user: {
                    ...state.user,
                    phoneNumberPending: action.phoneNbr,
                } as User,
            }
        case SET_SHOW_WEBAPP_INSTALL_DIALOGUE:
            return {
                ...state,
                showWebappInstallDialogue: action.show,
            }
        case HIDE_WEBAPP_INSTALL_DIALOGUE:
            return {
                ...state,
                showWebappInstallDialogue: false,
            }
        case RESET_SESSION:
            return initialState
    }

    return state
}

export default UserReducer
