import React, { FC } from 'react'

import BlogArticleListItem from '../blog-article-list-item'
import { BlogArticleListProps } from './blog-article-list.props'

const BlogArticleList: FC<BlogArticleListProps> = ({ articles, limit = null }) => (
    <div className="component--blog-article-list">
        {articles
            ?.filter((article, index) => (limit ? index < limit : true))
            .map((article) => (
                <BlogArticleListItem article={article} key={article.id} />
            ))}
    </div>
)

export default BlogArticleList
