import { ChatbotContextEnum } from '../../components/chatbot/chatbot.props'
import { GlobalState } from '../state.props'
import { ChatbotDialogueItem } from './chatbot.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */
export const getChatbotItems = (
    state: GlobalState,
    context: ChatbotContextEnum
): ChatbotDialogueItem[] => state.chatbot.data[context]
