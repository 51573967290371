import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { ChatTopicListItemProps } from './chat-topic-list-item.props'

const ChatTopicListItem: FC<ChatTopicListItemProps> = ({ chatTopic }) => (
    <Link
        to={`/chat/thema/${chatTopic.id}`}
        className="component--chat-topic-list-item chat-list-topic"
    >
        <h2>{chatTopic.title}</h2>
        <p className="label-hashtag-link">
            {chatTopic.chatThreadsNum} Beiträge
            {chatTopic.recentUpdatesNum >= 1 && (
                <span
                    className={'recent-updates active'}
                    title={`${chatTopic.recentUpdatesNum} neue Beiträge oder Reaktionen`}
                >
                    | <strong>{chatTopic.recentUpdatesNum} neu</strong>
                </span>
            )}
        </p>
        <i className="chat-list-topic--arrow"></i>
    </Link>
)

export default ChatTopicListItem
