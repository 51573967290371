import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import BlogArticleList from '../../components/blog/blog-article-list'
import Chatbot from '../../components/chatbot/chatbot-container'
import { ChatbotContextEnum } from '../../components/chatbot/chatbot.props'
import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSeo from '../../hooks/use-seo'
import { fetchBlogArticles } from '../../store/blog-articles/blog-articles.actions'
import { getBlogArticles } from '../../store/blog-articles/blog-articles.selectors'
import { IMenuPage, IMenuType } from '../../store/menu-pages/menu-pages.props'
import { getMenuByName } from '../../store/menu-pages/menu-pages.selectors'
import { GlobalState } from '../../store/state.props'
import { SLUG_ABOUT_PAGE, getMenuPageLink } from '../../utils/pages'
import { isMobile, isTablet } from '../../utils/viewport'

const minHeaderHeight = 605

const Home: FC = () => {
    const dispatch = useFideoDispatch()
    const furtherMenuLinks = useSelector((state: GlobalState) =>
        getMenuByName(state, IMenuType.FURTHER)
    )
    const blogArticles = useSelector((state: GlobalState) => getBlogArticles(state))

    let headerHeight = 'auto'

    useEffect(() => {
        if (!blogArticles || blogArticles.length <= 1) {
            dispatch(fetchBlogArticles())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Update canonical link
    useSeo({
        title: null,
        metaTags: [],
        slug: '',
    })

    if (isMobile()) {
        if (window.innerHeight > minHeaderHeight) {
            // Middle / Large
            headerHeight = window.innerHeight + 'px'
        } else {
            // Small
            headerHeight = minHeaderHeight + 'px'

            if (window.innerWidth <= 380) {
                // Very small
                headerHeight = minHeaderHeight + 20 + 'px'
            }
        }
    } else if (isTablet()) {
        headerHeight = window.innerHeight - 200 + 'px'
    }

    return (
        <div className="page page--home">
            <div className="page--home__header page-header" style={{ height: headerHeight }}>
                <h1 className="title-white no-hyphens">Stark gegen Depression</h1>
                <Chatbot context={ChatbotContextEnum.HOME} />
                <a
                    href="#main-content"
                    className="to-content-button"
                    title="Zum Seiteninhalt navigieren"
                ></a>
            </div>

            <div id="main-content" className="page--home__articles">
                <div className="headline-section">News</div>
                <h2 className="title-white">Was gibt es Neues?</h2>
                {!blogArticles || blogArticles.length === 0 ? (
                    <Loading />
                ) : (
                    <BlogArticleList articles={blogArticles} limit={3} />
                )}
                <Link to="/aktuelles" className="link-white" aria-label="Den Fideo Blog lesen">
                    Entdecke unseren Blog
                </Link>
            </div>

            <div className="image-teaser-list">
                <div className="image-teaser bg-green">
                    <p className="text-white">CHAT</p>
                    <h3 className="title-white">
                        Ich bin ständig traurig. Stimmt mit mir etwas nicht?
                    </h3>
                    <Link to="/chat" className="link-white">
                        Über Themen reden
                    </Link>
                </div>

                <div className="image-teaser bg-orange">
                    <p className="text-white">ANTWORTEN</p>
                    <h3 className="title-white">Was passiert mit mir?</h3>
                    <Link to="/antworten" className="link-white">
                        Antworten finden
                    </Link>
                </div>
            </div>

            <div className="page--home-meta full-width">
                <div className="page--home-meta__about">
                    <p className="label-hashtag-link">ÜBER FIDEO</p>
                    <p>
                        <span className="strong">FIDEO</span> ist stark gegen Depression.
                    </p>
                    <p>
                        <span className="strong">FIDEO</span> informiert Dich über die Erkrankung
                        Depression bei Jugendlichen, klärt auf und baut Vorurteile ab.
                    </p>
                    <p>
                        <span className="strong">FIDEO</span> ist Selbsthilfe. Tausche Dich mit
                        anderen aus und unterstützt Euch gegenseitig.
                    </p>
                    <p>
                        <span className="strong">FIDEO</span> ist ein Projekt des Diskussionsforum
                        Depression e.V. unter dem Dach der Stiftung Deutsche Depressionshilfe.
                    </p>
                    <p className="p-v">#starkgegendepression</p>
                    <a href={SLUG_ABOUT_PAGE} className="link-blue">
                        Mehr über uns erfahren
                    </a>
                </div>
                <div className="page--home-meta__other">
                    <p className="label-hashtag-link">WEITERES</p>
                    <div className="flex-container-list">
                        {furtherMenuLinks?.pages.map((page: IMenuPage, key: number) => (
                            <div className="flex-item-list" key={key}>
                                <Link
                                    to={getMenuPageLink(page)}
                                    title={`Zur Inhaltsseite "${page.linkTitle}"`}
                                >
                                    <h2>{page.linkTitle}</h2>
                                    <i className="flex-item-list--arrow"></i>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
