import { Seo } from '../state.props'

export enum IMenuType {
    CHAT = 'chat',
    FOOTER = 'footer',
    FURTHER = 'further',
}

export interface IPage {
    id: number
    seo: Seo
    title: string
    text: string
}

export interface IMenuPage {
    position: number
    pageId: number
    linkTitle: string
    type?: string
    slug: string
}

export interface IMenu {
    pages: IMenuPage[]
    type: IMenuType
}

export interface MenuPageState {
    menues: IMenu[]
    pages: IPage[]
    isPending: boolean
}
