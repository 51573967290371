import React, { FC, MouseEvent, useRef } from 'react'

import { useFideoDispatch } from '../../../../hooks/use-fideo-dispatch'
import { postChatPost } from '../../../../store/chat-posts/chat-posts.actions'
import { APIError } from '../../../../utils/api'
import { ChatPostAddFormProps } from './chat-post-add-form.props'

const ChatPostAddForm: FC<ChatPostAddFormProps> = ({ threadId, toggleFormVisibility }) => {
    const dispatch = useFideoDispatch()
    const textarea = useRef<HTMLTextAreaElement>(null)

    const onClickCloseBtn = () => {
        toggleFormVisibility(false)
    }

    const onSubmit = (ev: MouseEvent) => {
        if (textarea && textarea.current) {
            const text = (textarea as any).current.value.trim()

            dispatch(
                postChatPost({
                    chatThreadId: Number(threadId),
                    text,
                })
            )
                .then(() => {
                    toggleFormVisibility(false)
                })
                .catch((error: APIError) => {
                    if (error.error && error.error.code && error.error.code === 403) {
                        alert(
                            'Dein Konto wurde deaktiviert. Bitte nimm Kontakt mit der Redaktion auf: redaktion@fideo.de'
                        )
                        toggleFormVisibility(false)
                        return
                    }

                    alert(
                        'Fehler beim absenden. Bitte stelle sicher, dass Du eine Verbindung zum Internet hast und probiere es erneut.'
                    )
                })
        }

        ev.preventDefault()
    }

    return (
        <form className="component--chat-post-add-form chat-answer" action="#" method="POST">
            <textarea ref={textarea}></textarea>
            <div className="chat-card-wrapper">
                <button className="button-close-white" onClick={onClickCloseBtn}></button>

                <button type="submit" className="button-submit" onClick={onSubmit}>
                    Senden
                </button>
            </div>
        </form>
    )
}

export default ChatPostAddForm
