import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { TextBlock } from '../../../store/text-blocks/text-blocks.props'
import { getUnsanitizedPageText } from '../../../utils/configs'

interface ChatThreadAddFormSuccess {
    textBlock: TextBlock
    linkTarget: string
    linkText: string
}

const ChatThreadFormSuccess: FC<ChatThreadAddFormSuccess> = ({
    textBlock,
    linkTarget,
    linkText,
}) => (
    <div className="component--chat-thread-add-form">
        <div className="page--chat-thread-new-success page-header">
            <h1 className="title-black">Danke!</h1>
            <p dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}></p>
            <Link to={linkTarget} className="button button-primary">
                {linkText}
            </Link>
        </div>
    </div>
)

export default ChatThreadFormSuccess
