import { getApiUrl, handleFetchError } from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult, GlobalState, Pagination } from '../state.props'
import { FaqTopQuestionsState } from './faq-top-questions.props'
import { getFaqTopQuestionsPagination } from './faq-top-questions.selectors'

export const FETCH_FAQ_TOP_QUESTIONS_PENDING = 'FETCH_FAQ_TOP_QUESTIONS_PENDING'
export const RESET_FAQ_TOP_QUESTIONS = 'RESET_FAQ_TOP_QUESTIONS'
export const ADD_FAQ_TOP_QUESTIONS = 'ADD_FAQ_TOP_QUESTIONS'
export const UPDATE_FAQ_TOP_QUESTIONS_PAGINATION = 'UPDATE_FAQ_TOP_QUESTIONS_PAGINATION'

const fetchFaqTopQuestionsSuccess = (resetData: boolean, faqTopQuestions: FaqTopQuestionsState) => {
    return {
        type: resetData ? RESET_FAQ_TOP_QUESTIONS : ADD_FAQ_TOP_QUESTIONS,
        faqTopQuestions,
    }
}

const fetchFaqTopQuestionsPending = () => {
    return {
        type: FETCH_FAQ_TOP_QUESTIONS_PENDING,
    }
}

const updatePagination = (pagination: Pagination) => {
    return {
        type: UPDATE_FAQ_TOP_QUESTIONS_PAGINATION,
        pagination,
    }
}

// -- API --

/**
 * @param resetData {boolean} Should response data replace whole collection (i.e. skip pagination)?
 */
export const fetchFaqTopQuestions =
    (resetData: boolean = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchFaqTopQuestionsPending())

        const nextPage = getFaqTopQuestionsPagination(getState()).nextPage || 0

        return fetch(`${getApiUrl()}/faq-top-questions?page=${nextPage}`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchFaqTopQuestionsSuccess(resetData, json))
                return json
            })
    }

// GET /faq-top-questions?query={string}
// Does NOT mutate store
export const fetchFaqQuestionsByQuery =
    (query: string, page: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        return fetch(`${getApiUrl()}/faq-questions?page=${page}&query=${query}`)
            .then(handleFetchError)
            .then((json) => json)
    }
