import cn from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import useSessionUser from '../../hooks/use-session-user'
import {
    getGroupChatNews,
    getPrivateChatNews,
    getPublicChatNews,
} from '../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../store/state.props'

interface UnreadNotification {
    displayInvert?: boolean
    displayNumFor?: 'all' | 'public' | 'private' | 'group'
}

const UnreadNotification: FC<UnreadNotification> = ({
    displayInvert = false,
    displayNumFor = 'all',
}) => {
    const sessionUser = useSessionUser()
    const publicChatNewsNum = useSelector(getPublicChatNews).length
    const privateChatNewsNum = useSelector(getPrivateChatNews).length
    const groupChatNewsNum = useSelector((state: GlobalState) =>
        getGroupChatNews(state, sessionUser?.username)
    ).length

    let num

    switch (displayNumFor) {
        case 'all':
            num = publicChatNewsNum + privateChatNewsNum + groupChatNewsNum
            break
        case 'private':
            num = privateChatNewsNum
            break
        case 'public':
            num = publicChatNewsNum
            break
        case 'group':
            num = groupChatNewsNum
            break
    }

    if (!sessionUser || num === 0) {
        return null
    }

    return (
        <span
            className={cn(
                'badge',
                displayInvert ? 'badge--invert' : '',
                displayNumFor === 'private' ? 'badge--private' : ''
            )}
        >
            {num}
        </span>
    )
}

export default UnreadNotification
