import { getApiUrl, handleFetchError } from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult } from '../state.props'
import { FaqTopicsState } from './faq-topics.props'

export const FETCH_FAQ_TOPICS_PENDING = 'FETCH_FAQ_TOPICS_PENDING'
export const RESET_FAQ_TOPICS = 'RESET_FAQ_TOPICS'
export const ADD_FAQ_TOPICS = 'ADD_FAQ_TOPICS'

const fetchFaqTopicsSuccess = (resetData: boolean, faqTopics: FaqTopicsState) => {
    return {
        type: resetData ? RESET_FAQ_TOPICS : ADD_FAQ_TOPICS,
        faqTopics,
    }
}

const fetchFaqTopicsPending = () => {
    return {
        type: FETCH_FAQ_TOPICS_PENDING,
    }
}

// -- API --

/**
 * @param resetData {boolean} Should response data replace whole collection (i.e. skip pagination)?
 */
export const fetchFaqTopics =
    (resetData: boolean = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(fetchFaqTopicsPending())

        return fetch(`${getApiUrl()}/faq-topics`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchFaqTopicsSuccess(resetData, json))
            })
    }
