import React, { FC, useEffect } from 'react'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import { fetchMenus } from '../../../store/menu-pages/menu-pages.actions'
import { fetchTextBlocks } from '../../../store/text-blocks/text-blocks.actions'
import ChatbotWidget from '../chatbot-widget'
import Navbar from '../navbar'
import Titlebar from '../titlebar'

export interface IHeader {
    navbarIsVisible: boolean
    toggleNavbarVisibility: () => void
}

let initalResourcesRequested: number = 0

const Header: FC<IHeader> = (props) => {
    const dispatch = useFideoDispatch()

    const fetchInitialResources = () => {
        // Try max. 2 times to fetch essential app resources
        dispatch(fetchMenus()).catch(() => {
            if (++initalResourcesRequested <= 1) {
                window.setTimeout(fetchInitialResources, 4000)
            }
        })
        dispatch(fetchTextBlocks()).catch(() => {
            if (++initalResourcesRequested <= 1) {
                window.setTimeout(fetchInitialResources, 4000)
            }
        })
    }

    useEffect(() => {
        fetchInitialResources()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <header className="header">
            <Navbar {...props} />
            <Titlebar {...props} />
            <ChatbotWidget />
        </header>
    )
}

export default Header
