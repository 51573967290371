import { AnyAction } from 'redux'

import { ADD_TEXT_BLOCKS } from './text-blocks.actions'
import { TextBlockState } from './text-blocks.props'

const initialState: TextBlockState = {
    data: [],
}

const TextBlocksReducer = (
    state: TextBlockState = initialState,
    action: AnyAction
): TextBlockState => {
    switch (action.type) {
        case ADD_TEXT_BLOCKS:
            return {
                ...state,
                data: action.textBlocks,
            }
    }

    return state
}

export default TextBlocksReducer
