import React, { FC, MouseEvent, useState } from 'react'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import {
    deleteEmergencyKitItem,
    moveEmergencyKitItemDown,
    moveEmergencyKitItemUp,
} from '../../../store/emergency-kit/emergency-kit.actions'
import {
    IEmergencyKitImageItem,
    IEmergencyKitItem,
    IEmergencyKitTextItem,
} from '../../../store/emergency-kit/emergency-kit.props'
import Modal from '../../layout/modal'
import Picture from '../../picture'

interface EmergencyKitListItem {
    isEditMode: boolean
    item: IEmergencyKitItem
}

const EmergencyKitListItem: FC<EmergencyKitListItem> = ({ isEditMode, item }) => {
    const dispatch = useFideoDispatch()
    const [modalOpen, setModalOpen] = useState(false)

    const onClickDelete = (ev: MouseEvent) => {
        if (window.confirm(`Möchtest Du dieses Element wirklich entfernen?`)) {
            dispatch(deleteEmergencyKitItem(item.id))
        }

        ev.preventDefault()
    }

    const onClickUp = (ev: MouseEvent) => {
        dispatch(moveEmergencyKitItemUp(item))
        ev.preventDefault()
    }

    const onClickDown = (ev: MouseEvent) => {
        dispatch(moveEmergencyKitItemDown(item))
        ev.preventDefault()
    }

    return (
        <div className="component--emergency-kit-list-item" data-item-type={item.type}>
            {modalOpen && (
                <Modal setModalOpen={setModalOpen}>
                    <Picture
                        image={(item as IEmergencyKitImageItem).image}
                        alt="Bild Notfallkoffer"
                    />
                </Modal>
            )}
            {isEditMode && (
                <>
                    <div
                        className="component--emergency-kit-list-item__up"
                        title="Nach weiter oben verschieben"
                        onClick={onClickUp}
                    ></div>
                    <div
                        className="component--emergency-kit-list-item__down"
                        title="Nach weiter unten verschieben"
                        onClick={onClickDown}
                    ></div>
                    <div
                        className="component--emergency-kit-list-item__delete"
                        title="Löschen"
                        onClick={onClickDelete}
                    ></div>
                </>
            )}
            {item.type === 'image' && (
                <picture>
                    <img
                        onClick={() => {
                            setModalOpen(true)
                        }}
                        src={(item as IEmergencyKitImageItem).image.url}
                        alt="Bild Notfallkoffer"
                    />
                </picture>
            )}
            {item.type === 'text' && <p>{(item as IEmergencyKitTextItem).text}</p>}
        </div>
    )
}

export default EmergencyKitListItem
