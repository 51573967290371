import cn from 'classnames'
import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import {
    fetchEmergencyKitItems,
    postEmergencyKitImageItem,
    postEmergencyKitTextItem,
} from '../../../store/emergency-kit/emergency-kit.actions'
import { getEmergencyKitItems } from '../../../store/emergency-kit/emergency-kit.selectors'
import { GlobalState } from '../../../store/state.props'
import { scrollToTop } from '../../../utils/pages'

// @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const _allowedMemeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp']

const EmergencyKitAddForm: FC = () => {
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const emergencyKitItems = useSelector((state: GlobalState) => getEmergencyKitItems(state))

    const [itemType, setItemType] = useState<string>()
    const [itemText, setItemText] = useState<string>('')
    const [itemImage, setItemImage] = useState<File | null>(null)
    const [isUploading, setIsUploading] = useState<boolean>(false)

    useEffect(() => {
        if (emergencyKitItems.length === 0) {
            dispatch(fetchEmergencyKitItems())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeItemType = (ev: ChangeEvent<HTMLSelectElement>): void => {
        const value: string = ev.currentTarget.value
        if (value === 'text' || value === 'image') {
            setItemType(value)
        }
    }

    const onSubmit = (ev: MouseEvent) => {
        ev.preventDefault()

        if (isUploading) {
            return
        }

        setIsUploading(true)

        if (itemType === 'text') {
            dispatch(
                postEmergencyKitTextItem({
                    type: 'text',
                    text: itemText,
                })
            )
                .then(() => {
                    navigate('/notfallkoffer/bearbeiten')
                })
                .catch(() => {
                    setIsUploading(false)
                    alert(`Problem beim hinzufügen. Bitte probiere es erneut.`)
                })
        } else if (itemType === 'image') {
            if (itemImage) {
                const formData = new FormData()
                formData.append('file', itemImage, itemImage.name)

                dispatch(postEmergencyKitImageItem(formData))
                    .then(() => {
                        navigate('/notfallkoffer/bearbeiten')
                        scrollToTop()
                    })
                    .catch(() => {
                        setIsUploading(false)
                        alert(`Problem beim hinzufügen. Bitte probiere es erneut.`)
                    })
            }
        }
    }

    const onChangeItemText = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setItemText(ev.currentTarget.value)
    }

    const onChangeItemImage = (ev: ChangeEvent<HTMLInputElement>) => {
        const files: FileList = ev.currentTarget.files as FileList

        if (typeof files[0] !== 'undefined') {
            const file: File = files[0]

            if (!_allowedMemeTypes.includes(file.type)) {
                alert('Dateiformat wird nicht unterstützt.')
                return false
            }

            setItemImage(file)
        } else {
            setItemImage(null)
        }

        ev.preventDefault()
    }

    return (
        <div className="component--emergency-kit-add-form page-wrapper">
            <form className="emergency-kit-add-form" action="#" method="post">
                <div className="select-wrapper">
                    <label className="label-hashtag-link" htmlFor="emergency-kit-add-form__type">
                        Typ
                    </label>
                    <select
                        id="emergency-kit-add-form__type"
                        name="emergency-kit-add-form__type"
                        value={itemType}
                        onChange={onChangeItemType}
                    >
                        <option value="">Bitte wählen</option>
                        <option value="image">Bild</option>
                        <option value="text">Text</option>
                    </select>
                </div>

                {itemType === 'text' && (
                    <div className="input-wrapper">
                        <label
                            className="label-hashtag-link"
                            htmlFor="emergency-kit-add-form__text"
                        >
                            Text
                        </label>
                        <textarea
                            id="emergency-kit-add-form__text"
                            name="emergency-kit-add-form__text"
                            value={itemText}
                            onChange={onChangeItemText}
                        ></textarea>
                    </div>
                )}

                {itemType === 'image' && (
                    <div className="input-wrapper input-wrapper--file">
                        <label
                            className="label-hashtag-link"
                            htmlFor="emergency-kit-add-form__image"
                        >
                            Hochladen
                            <input
                                type="file"
                                placeholder="Datei"
                                id="emergency-kit-add-form__image"
                                name="emergency-kit-add-form__image"
                                accept="image/png, image/jpeg"
                                onChange={onChangeItemImage}
                            ></input>
                            <span
                                data-file-name={itemImage !== null ? itemImage.name : ''}
                                className={cn('file-input', {
                                    selected: itemImage !== null,
                                })}
                            ></span>
                        </label>
                    </div>
                )}

                <div className="form__buttons">
                    <Link to="/notfallkoffer/bearbeiten" className="button button-secondary">
                        Abbrechen
                    </Link>
                    {itemType &&
                        (itemType === 'text' || (itemType === 'image' && itemImage !== null)) && (
                            <button
                                type="submit"
                                onClick={onSubmit}
                                className="button button-submit"
                            >
                                Hinzufügen
                            </button>
                        )}
                </div>
            </form>
        </div>
    )
}

export default EmergencyKitAddForm
