import { GlobalState } from '../state.props'
import { IEmergencyKitItem } from './emergency-kit.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */

/**
 * @return IEmergencyKitItem[] Sorted by position asc
 */
export const getEmergencyKitItems = (state: GlobalState): IEmergencyKitItem[] =>
    state.emergencyKit.data

export const getEmergencyKitIsPending = (state: GlobalState): boolean =>
    state.emergencyKit.isPending
