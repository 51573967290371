import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Picture from '../../../components/picture'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSeo from '../../../hooks/use-seo'
import {
    fetchBlogArticle,
    fetchBlogArticleBySlug,
} from '../../../store/blog-articles/blog-articles.actions'
import {
    getBlogArticleById,
    getBlogArticleBySlug,
} from '../../../store/blog-articles/blog-articles.selectors'
import { GlobalState, ParamsSlug } from '../../../store/state.props'
import { getUnsanitizedPageText } from '../../../utils/configs'
import BlogDetailSchema from './blog-detail.schema'

const BlogDetail: FC = () => {
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const params = useParams<ParamsSlug>()
    const param: any = params.slug
    const isSlug = isNaN(param)
    const article = useSelector((state: GlobalState) =>
        isSlug
            ? getBlogArticleBySlug(state, `/aktuelles/${param}`)
            : getBlogArticleById(state, Number(param))
    )

    useEffect(() => {
        if (!article) {
            dispatch(
                isSlug ? fetchBlogArticleBySlug(`/aktuelles/${param}`) : fetchBlogArticle(param)
            ).catch(() => navigate('/404'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useSeo(article?.seo)

    if (!article) {
        return null
    }

    return (
        <>
            <BlogDetailSchema article={article} />
            <div className="page page--blog page--blog-detail bg-yellow">
                <div className="blog-single">
                    {article?.image && article.image.url && (
                        <Picture
                            image={article.image}
                            className="blog-single-img"
                            alt={`Artikelbild ${article.title}`}
                        />
                    )}
                    <div className="blog-single-content">
                        <h1>{article?.title}</h1>
                        <div dangerouslySetInnerHTML={getUnsanitizedPageText(article?.text)}></div>
                        <div className="border-bottom"></div>
                        <div className="blog-card-wrapper">
                            <p className="meta-text">
                                {moment(article.createdAt).format('DD.MM.YYYY')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDetail
