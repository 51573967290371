import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useFideoNavigate from '../../../hooks/use-fideo-navigate'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchChatTopics } from '../../../store/chat-topics/chat-topics.actions'
import { getSelectableChatTopics } from '../../../store/chat-topics/chat-topics.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlock, TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { APIError } from '../../../utils/api'
import { getUnsanitizedPageText } from '../../../utils/configs'
import { scrollToTop } from '../../../utils/pages'
import ChatGroupMembersForm from '../chat-group-members-form'
import ChatThreadFormSuccess from '../chat-thread-form-success'
import { ChatGroupFormProps } from './chat-group-form.props'

const ChatGroupForm: FC<ChatGroupFormProps> = ({
    threadTitle = '',
    threadText = '',
    threadChatTopicId = undefined,
    formTitle = 'Gruppenchat anlegen',
    textBlockKey = TextBlockCode.INTRO_CHAT_GROUP_NEW,
    textBlockSuccessKey = TextBlockCode.INTRO_CHAT_GROUP_SUCCESS,
    submitAction = undefined,
    linkTarget = '/chat',
    linkText = `Zurück zur Übersicht`,
    thread = undefined,
    selfIsAdmin = false,
}) => {
    useSetParentLink('/chat')
    const { gotoPrevPage } = useFideoNavigate()
    const [formState, setFormState] = useState<string>('')
    const [title, setTitle] = useState<string>(threadTitle)
    const [text, setText] = useState<string>(threadText)
    const [chatTopicId, setChatTopicId] = useState<number | undefined>(threadChatTopicId)

    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, textBlockKey)
    )
    const textBlockSuccess = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, textBlockSuccessKey)
    )
    const chatTopics = useSelector((state: GlobalState) => getSelectableChatTopics(state))

    useEffect(() => {
        if (!chatTopics || !chatTopics.length) {
            dispatch(fetchChatTopics(true) as unknown as AnyAction)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Force black-coloured back-button
    document.body.setAttribute('data-page', 'chat-thread-add')

    const onSubmit = (ev: MouseEvent) => {
        if (title.trim() === '' || text.trim() === '' || !chatTopicId) {
            setFormState(`Bitte fülle alle Felder aus.`)
        } else {
            if (!submitAction) return

            submitAction({
                title: title.trim(),
                text: text.trim(),
                type: 'group',
                chatTopicId: Number(chatTopicId),
            })
                .then(() => {
                    setFormState('__DONE__')
                    scrollToTop()
                })
                .catch((error: APIError) => {
                    if (error.error && error.error.code && error.error.code === 403) {
                        alert(
                            'Dein Konto wurde deaktiviert. Bitte nimm Kontakt mit der Redaktion auf: redaktion@fideo.de'
                        )
                        return
                    }

                    setFormState(
                        `Beitrag konnte nicht angelegt/editiert werden. Besteht eine Netzwerkverbindung?`
                    )
                })
        }

        ev.preventDefault()
    }

    const onChangeTitle = (ev: ChangeEvent<HTMLInputElement>) => {
        const { value } = ev.currentTarget
        if (value.length > 250) return

        setTitle(value)
    }

    const onChangeText = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = ev.currentTarget
        if (value.length > 10000) return

        setText(value)
    }

    // const onChangeTextInternal = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    //     setTextInternal(ev.currentTarget.value)
    // }

    const onChangeChatTopicId = (ev: ChangeEvent<HTMLSelectElement>): void => {
        const value: string = ev.currentTarget.value
        setChatTopicId(Number(value))
    }

    if (formState === '__DONE__') {
        return (
            <ChatThreadFormSuccess
                textBlock={textBlockSuccess as TextBlock}
                linkTarget={linkTarget}
                linkText={linkText}
            />
        )
    }

    return (
        <div className="component--chat-thread-add-form page-header">
            <h1 className="title-black">{formTitle}</h1>
            <p
                className="text-black"
                dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
            ></p>
            <br />

            <form className="chat-thread-form" action="#" method="POST">
                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="chat-thread-form__title">
                        Name der Gruppe (öffentlich)*
                    </label>
                    <input
                        type="text"
                        id="chat-thread-form__title"
                        name="chat-thread-form__title"
                        placeholder={`z.B. "Wieso bin ich so einsam?"`}
                        value={title}
                        disabled={thread && !selfIsAdmin}
                        required
                        onChange={onChangeTitle}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="chat-thread-form__text">
                        Gruppenbeschreibung (öffentlich)*
                    </label>
                    <textarea
                        id="chat-thread-form__text"
                        name="chat-thread-form__text"
                        value={text}
                        disabled={thread && !selfIsAdmin}
                        onChange={onChangeText}
                    ></textarea>
                </div>

                <div className="select-wrapper">
                    <label className="label-hashtag-link" htmlFor="chat-thread-form__topic">
                        Thema*
                    </label>
                    <select
                        id="chat-thread-form__topic"
                        name="chat-thread-form__topic"
                        required
                        value={chatTopicId}
                        disabled={thread && !selfIsAdmin}
                        onChange={onChangeChatTopicId}
                    >
                        <option value="">Bitte wählen</option>
                        {chatTopics.map((chatTopic) => (
                            <option key={chatTopic.id} value={chatTopic.id}>
                                {chatTopic.title}
                            </option>
                        ))}
                    </select>
                </div>

                {thread && thread.groupMembers && (
                    <ChatGroupMembersForm
                        threadId={thread.id}
                        memberships={thread.groupMembers}
                        selfIsAdmin={selfIsAdmin}
                    />
                )}

                {formState !== '' && <p className="form-error input-wrapper">{formState}</p>}

                <div className="form__buttons">
                    <a
                        href="#"
                        className="button button-secondary"
                        title="Formular verlassen"
                        onClick={gotoPrevPage}
                    >
                        {thread ? 'Schließen' : 'Abbrechen'}
                    </a>

                    {!thread || (thread && selfIsAdmin) ? (
                        <button type="submit" onClick={onSubmit} className="button button-submit">
                            Speichern
                        </button>
                    ) : null}
                </div>
            </form>
        </div>
    )
}

export default ChatGroupForm
