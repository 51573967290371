import Bowser from 'bowser'

import { getCookie } from './cookies'

// Provides methods for triggering an "Add to homescreen" (A2HS) prompt via chatbot.
// Consumed in Chatbot-view & -store
// @see https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen#How_do_you_make_an_app_A2HS-ready
let deferredInstallPrompt: any

window.addEventListener('beforeinstallprompt', (ev: any) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    ev.preventDefault()
    deferredInstallPrompt = ev
})

export const triggerInstallPrompt = (): Promise<any> => {
    if (!deferredInstallPrompt) {
        return Promise.reject()
    }

    deferredInstallPrompt.prompt()

    // Wait for the user to respond to the prompt
    return deferredInstallPrompt.userChoice.then((choiceResult: any) => {
        // if (choiceResult.outcome === 'accepted') {
        //     console.log('User accepted the A2HS prompt');
        // } else {
        //     console.log('User dismissed the A2HS prompt');
        // }
        deferredInstallPrompt = null
        return choiceResult
    })
}

/**
 * We do not know whether FIDEO app has already been added to homescreen,
 * but we can assume whether the current window is a PWA or if the add-to-homescreen
 * dialogue has already been triggered whilst this session.
 */

// Assess browser support for "Add-To-Homescreen" feature
// @see https://caniuse.com/web-app-manifest
// @see https://github.com/philfung/add-to-homescreen/blob/main/src/index.js
const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser = browser.satisfies({
    edge: '>=79',
    chrome: '>=39',
    mobile: {
        safari: '>=11.3',
        'android browser': '>=81',
        opera: '>=59',
        chrome: '>=88',
        firefox: '>=83',
        uc: '>=12.12',
    },
})

export const FIDEO_A2HS_SKIP_COOKIE = 'fideo-a2hs-skip'

export const getShowWebappInstallDialogue = async (): Promise<boolean> => {
    const isWebappInstalled = await getIsWebappInstalled()

    return (
        !matchMedia('(display-mode: standalone)').matches &&
        getCookie(FIDEO_A2HS_SKIP_COOKIE) === null &&
        true === isValidBrowser &&
        !isWebappInstalled
    )
}

export const getIsWebappInstalled = async (): Promise<boolean> => {
    if (typeof (navigator as any).getInstalledRelatedApps !== 'function') {
        return false
    }
    const installedApps = await (navigator as any).getInstalledRelatedApps()

    if (!installedApps) {
        return false
    }

    if (typeof installedApps.length !== 'undefined' && installedApps.length === 0) {
        return false
    }

    return true
}
