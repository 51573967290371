import React, { FC } from 'react'

import FideoLogo from '../../../images/fideo-logo.svg'
import UnreadNotification from '../../unread-notification'
import useTitlebar from './use-titlebar'

interface ITitlebar {
    toggleNavbarVisibility: () => void
}

const Titlebar: FC<ITitlebar> = ({ toggleNavbarVisibility }) => {
    const { activeLocation, navigate, location, pageIs404 } = useTitlebar()

    /**
     * Go a step back, but not to previous page (as in history.goBack()), but to
     * next meaningful parent path (either defined by component via hook or auto-estimated)
     * @todo Should this also override the native BrowserHistory.goBack logic?
     */
    const onClickBackLink = () => {
        let target = null

        const parentLink =
            location.state && (location.state as any).parentLink
                ? (location.state as any).parentLink
                : false

        if (parentLink) {
            // View has defined own parent?
            target = parentLink
        } else {
            // Fallback: Estimate parent path ("/einstellungen/profil" => "/einstellungen")
            const url = location.pathname
            target = url.substring(0, url.lastIndexOf('/')) || '/'
        }

        navigate(target)
    }

    const onClickToggleMenu = () => {
        toggleNavbarVisibility()
    }

    let locationTitle = pageIs404 ? '404' : activeLocation.title.replace('-', ' ')

    if (locationTitle.indexOf('einstellungen') >= 0) {
        // Hide sub-paths
        locationTitle = locationTitle.split('/')[0]
    }

    return (
        <>
            <div className="titlebar">
                {locationTitle !== 'Startseite' ? (
                    <div className="titlebar__back-button" onClick={onClickBackLink}>
                        Zurück
                    </div>
                ) : null}
                <div className="titlebar__title text-uppercase">
                    {locationTitle === 'Startseite' ? (
                        <img src={FideoLogo} alt="Logo Fideo" width="120" height="54" />
                    ) : (
                        locationTitle
                    )}
                </div>
                <div
                    className="titlebar__toggle-menu"
                    title="Menü öffnen"
                    onClick={onClickToggleMenu}
                >
                    Menü öffnen
                    <UnreadNotification />
                </div>
            </div>
        </>
    )
}

export default Titlebar
