import React, { FC } from 'react'

import useSessionUser from '../../../hooks/use-session-user'
import ChatThreadListItem from '../chat-thread-list-item'
import { ChatThreadListProps } from './chat-thread-list.props'

const ChatThreadList: FC<ChatThreadListProps> = ({ threads }) => {
    const sessionUser = useSessionUser()

    return (
        <div className="component--chat-thread-list">
            {threads?.map((chatThread) => (
                <ChatThreadListItem
                    chatThread={chatThread}
                    sessionUserName={sessionUser?.username}
                    key={chatThread.id}
                />
            ))}
        </div>
    )
}

export default ChatThreadList
