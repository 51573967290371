import React, { FC, useMemo } from 'react'

import { noop, sanitize } from '../../../utils/configs'
import ChatbotTextInput from '../chatbot-text-input/chatbot-text-input.view'
import { ChatbotDialogueItemActionType as ActionType } from '../chatbot.props'
import { ChatbotContextEnum } from '../chatbot.props'
import { ChatbotTextInputWrapperProps } from './chatbot-text-input-wrapper.props'

const ChatbotTextInputWrapper: FC<ChatbotTextInputWrapperProps> = ({
    items = [],
    setUserAnswer = noop,
    setInvalidAnswer = noop,
    dispatchChatbotAction = noop,
    context,
}) => {
    const isPasswordItem = useMemo(
        () =>
            items[items.length - 1].content.indexOf('asswort') >= 0 &&
            items[items.length - 1].content.indexOf('vergessen') === -1,
        [items]
    )

    const submitAction = (answer: string) => {
        // Evaluate answer
        // @todo Where indexOf is used, answer should be of more than 2 digits
        // (as not numeric) to guarantee preciseness
        const sanitizedAnswer = sanitize(answer).trim()
        const escapedAnswer = sanitizedAnswer.toLowerCase()

        if (escapedAnswer !== '') {
            const activeItems = [...items].filter((item) => item.active && item.actionType)
            const lastActiveItem = activeItems.reverse()[0]

            switch (lastActiveItem.actionType) {
                case ActionType.ANSWER_SELECT:
                    // Input be like 'yes' or 'no' or free input field
                    if (typeof lastActiveItem?.answerOptions !== 'undefined') {
                        const validAnswer = lastActiveItem.answerOptions.find(
                            (answer) =>
                                answer.value === escapedAnswer ||
                                answer.label.toLowerCase() === escapedAnswer
                        )

                        if (validAnswer) {
                            dispatchChatbotAction(lastActiveItem, validAnswer)
                        } else {
                            setUserAnswer(sanitizedAnswer)
                            setInvalidAnswer()
                        }
                    }
                    break

                case ActionType.TEXT_INPUT:
                    // free-typed input, e.g. `username`, `password` or `phoneNbr`
                    dispatchChatbotAction(lastActiveItem, sanitizedAnswer)
                    break

                case ActionType.CLICK:
                case ActionType.ROUTE:
                    if (context === ChatbotContextEnum.AUTHENTICATION) {
                        // Skip item search for context = login- & registration-form
                        // as could possibly lead to arbitrary state switches (see #2702)
                        if (lastActiveItem.content === 'Nummer ändern') {
                            // Registration form: Force passing security code to last item
                            setUserAnswer(sanitizedAnswer)
                            dispatchChatbotAction(lastActiveItem, sanitizedAnswer)
                        }
                        return
                    }
                    // Evaluate which item the user meant

                    // Is input within ChatbotDialogoueItem.id||content?...
                    let validItem =
                        activeItems.find((item) => item.id.toString() === escapedAnswer) ||
                        activeItems.find(
                            (item) => item.content.toLowerCase().indexOf(escapedAnswer) >= 0
                        )

                    if (!validItem) {
                        // ...or within tags?
                        const escapedAnswerWords = escapedAnswer.split(' ')
                        validItem = activeItems.find(
                            (item) =>
                                item.tags &&
                                item.tags.find((tag) =>
                                    escapedAnswerWords.find((word) => word.indexOf(tag) >= 0)
                                )
                        )
                    }

                    // @todo Nice-to-have: If more than 1 possible answer print `Did you mean: 'Chat' or 'FAQ'?`
                    if (validItem) {
                        dispatchChatbotAction(validItem)
                    } else {
                        setUserAnswer(sanitizedAnswer)
                        setInvalidAnswer()
                    }
                    break
            }
        }
    }

    return <ChatbotTextInput isPasswordItem={isPasswordItem} submitAction={submitAction} />
}

export default ChatbotTextInputWrapper
