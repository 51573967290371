import {
    APIError,
    getApiRequestHeaders,
    getApiToken,
    getApiUrl,
    handleFetchError,
    handleFetchErrorAsObject,
} from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult, GlobalState, Pagination } from '../state.props'
import {
    ChatNews,
    ChatThreadRequest,
    ChatThreadResponse,
    ChatThreadType,
    ChatThreadsState,
    DeleteGroupMemberPayload,
    GroupMembership,
    PatchGroupMemberPayload,
} from './chat-threads.props'
import { getChatThreadsPagination } from './chat-threads.selectors'

export const FETCH_CHAT_THREAD = 'FETCH_CHAT_THREAD'
export const FETCH_CHAT_THREADS = 'FETCH_CHAT_THREADS'
export const FETCH_CHAT_THREADS_PENDING = 'FETCH_CHAT_THREADS_PENDING'
export const FETCH_CHAT_THREADS_ERROR = 'FETCH_CHAT_THREADS_ERROR'
export const RESET_CHAT_THREADS = 'RESET_CHAT_THREADS'
export const ADD_CHAT_THREAD = 'ADD_CHAT_THREAD'
export const ADD_CHAT_THREADS = 'ADD_CHAT_THREADS'
export const ADD_PRIVATE_CHAT_THREAD = 'ADD_PRIVATE_CHAT_THREAD'
export const ADD_CHAT_NEWS = 'ADD_CHAT_NEWS'
export const DELETE_CHAT_NEWS = 'DELETE_CHAT_NEWS'
export const UPDATE_THREADS_PAGINATION = 'UPDATE_THREADS_PAGINATION'
export const PATCH_CHAT_THREAD = 'PATCH_CHAT_THREAD'
export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER'
export const DELETE_GROUP_MEMBER = 'DELETE_GROUP_MEMBER'
export const UNMARK_GROUP_MEMBER = 'UNMARK_GROUP_MEMBER'
export const PATCH_GROUP_MEMBER = 'PATCH_GROUP_MEMBER'

const fetchChatThreadSuccess = (chatThread: ChatThreadResponse) => {
    return {
        type: ADD_CHAT_THREAD,
        chatThread,
    }
}

const fetchPrivateChatThreadSuccess = (chatThread: ChatThreadResponse) => {
    return {
        type: ADD_PRIVATE_CHAT_THREAD,
        chatThread,
    }
}

const fetchChatThreadsSuccess = (chatThreads: ChatThreadsState) => {
    return {
        type: ADD_CHAT_THREADS,
        chatThreads,
    }
}

const fetchChatThreadsPending = () => {
    return {
        type: FETCH_CHAT_THREADS_PENDING,
    }
}

const fetchError = () => {
    return {
        type: FETCH_CHAT_THREADS_ERROR,
    }
}

const updatePagination = (pagination: Pagination) => {
    return {
        type: UPDATE_THREADS_PAGINATION,
        pagination,
    }
}

const patchChatThreadSuccess = (chatThread: ChatThreadResponse) => {
    return {
        type: PATCH_CHAT_THREAD,
        chatThread,
    }
}

export const addChatNews = (chatNews: ChatNews[]) => {
    return {
        type: ADD_CHAT_NEWS,
        chatNews,
    }
}

export const successDeleteChatNews = (threadId: number) => {
    return {
        type: DELETE_CHAT_NEWS,
        threadId,
    }
}

export const resetChatThreads = () => {
    return {
        type: RESET_CHAT_THREADS,
    }
}

const addGroupMemberSuccess = (threadId: number, data: any) => {
    return {
        type: ADD_GROUP_MEMBER,
        threadId,
        data,
    }
}

const deleteGroupMemberSuccess = (
    threadId: number,
    memberId: number
): DeleteGroupMemberPayload => ({
    type: DELETE_GROUP_MEMBER,
    threadId,
    memberId,
})

const patchGroupMember = (
    threadId: number,
    membership: GroupMembership
): PatchGroupMemberPayload => ({
    type: PATCH_GROUP_MEMBER,
    threadId,
    membership,
})

/**
 * GET /chat-threads/:id
 */
export const fetchChatThread =
    (id: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(fetchChatThreadsPending())

        return fetch(`${getApiUrl()}/chat-threads/${id}`, {
            headers: getApiRequestHeaders(),
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchChatThreadSuccess(json))
                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * GET /chat-threads?types[]=private
 */
export const fetchPrivateChatThread =
    (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) => {
        dispatch(fetchChatThreadsPending())

        return fetch(`${getApiUrl()}/chat-threads?types[]=private`, {
            headers: {
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchErrorAsObject)
            .then((json) => {
                dispatch(fetchPrivateChatThreadSuccess(json))
                return json
            })
            .catch(() => {
                dispatch(fetchError())
                return Promise.reject()
            })
    }

// GET /chat-threads?query={string}
// Does NOT mutate store
export const fetchChatThreadsByQuery =
    (query: string, page: number): FideoThunkResult<Promise<any>> =>
    () => {
        return fetch(`${getApiUrl()}/chat-threads?query=${query}&page=${page}`)
            .then(handleFetchError)
            .then((json) => json)
    }

/**
 * GET /chat-threads?where_i_am_member=1&types[]=group
 *
 *  @todo This "isIntitialCall" sounds like a side effect. Eventually, wrap by another fn
 */
export const fetchGroupChatsWhereIAmMember =
    (isInitialCall: boolean = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatThreadsPending())

        let nextPage = 0

        if (!isInitialCall) {
            nextPage = getChatThreadsPagination(getState()).nextPage || 0
        }

        return fetch(
            `${getApiUrl()}/chat-threads?where_i_am_member=1&types[]=group&page=${nextPage}`,
            {
                headers: {
                    'X-AUTH-TOKEN': getApiToken(),
                },
            }
        )
            .then(handleFetchError)
            .then((json) => {
                dispatch(updatePagination(json.pagination))
                dispatch(fetchChatThreadsSuccess(json))
                // console.log(json)
                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * GET /chat-threads?creator_name=:string&types[]=...
 *
 *  @todo This "isIntitialCall" sounds like a side effect. Eventually, wrap by another fn
 */
export const fetchChatThreadsByCreatorName =
    (
        creatorName: string,
        isInitialCall: boolean = false,
        chatTypes: ChatThreadType[] = ['public', 'group']
    ): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatThreadsPending())

        let nextPage = 0

        if (!isInitialCall) {
            nextPage = getChatThreadsPagination(getState()).nextPage || 0
        }

        const chatTypesParam = chatTypes.join('&types[]=')

        return fetch(
            `${getApiUrl()}/chat-threads?creator_name=${creatorName}&types[]=${chatTypesParam}&page=${nextPage}`,
            {
                headers: {
                    'X-AUTH-TOKEN': getApiToken(),
                },
            }
        )
            .then(handleFetchError)
            .then((json) => {
                dispatch(updatePagination(json.pagination))
                dispatch(fetchChatThreadsSuccess(json))
                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * GET /chat-threads?chat_topic_id=:id
 *
 * Fetch chat-threads and save 'em flat
 *
 * @todo This "isIntitialCall" sounds like a side effect. Eventually, wrap by another fn
 */
export const fetchChatThreadsByChatTopicId =
    (
        id: number,
        isInitialCall: boolean = false,
        chatTypes: ChatThreadType[] = ['public', 'group']
    ): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatThreadsPending())

        let nextPage = 0

        if (!isInitialCall) {
            nextPage = getChatThreadsPagination(getState()).nextPage || 0
        }

        const chatTypesParam = chatTypes.join('&types[]=')

        return fetch(
            `${getApiUrl()}/chat-threads?chat_topic_id=${id}&page=${nextPage}&types[]=${chatTypesParam}`,
            {
                headers: getApiRequestHeaders(),
            }
        )
            .then(handleFetchError)
            .then((json) => {
                dispatch(updatePagination(json.pagination))
                dispatch(fetchChatThreadsSuccess(json))
                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * PATCH /chat-threads/:id
 */
export const patchChatThread =
    (id: number, body: ChatThreadRequest): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(fetchChatThreadsPending())

        return fetch(`${getApiUrl()}/chat-threads/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify(body),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => {
                dispatch(patchChatThreadSuccess(json))
                return json
            })
            .catch(() => {
                return Promise.reject()
            })
    }

/**
 * GET /chat-news
 */
export const fetchChatNews = (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) =>
    fetch(`${getApiUrl()}/chat-news`, {
        headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': getApiToken(),
        },
    })
        .then(handleFetchErrorAsObject)
        .then((json) => {
            dispatch(addChatNews(json.data))
            return json
        })
        .catch((error: APIError) => Promise.reject(error))

/**
 * DELETE /chat-news/chat-threads/{id}
 *
 * This action does not mutate the store (as will be auto-patched
 * via ping on fetchChatNews).
 */
export const deleteChatNews =
    (threadId: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-news/chat-threads/${threadId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchErrorAsObject)
            .then((json) => {
                dispatch(successDeleteChatNews(threadId))
                return json
            })
            .catch((error) => {
                return Promise.reject(error)
            })

/**
 * POST /chat-thread-group-members
 *
 * If username is not provided, the current user will be added to the group ("request membership")
 */
export const addGroupMember =
    (chatThreadId: number, username?: string): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-thread-group-members`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify({
                chatThreadId,
                ...(username && { username }),
            }),
        })
            .then(handleFetchError)
            .then((json) => json.data)
            .then((data) => {
                dispatch(addGroupMemberSuccess(chatThreadId, data))
                return data
            })
            .catch((error: APIError) => Promise.reject(error))

/**
 * DELETE /chat-threads/:threadId/members/:memberId
 */
export const deleteGroupMember =
    (threadId: number, memberId: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-thread-group-members/${memberId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchError)
            .then((json) => json.data)
            .then((data) => {
                dispatch(deleteGroupMemberSuccess(threadId, memberId))
                return data
            })
            .catch((error: APIError) => Promise.reject(error))

/**
 * POST /chat-thread-group-members/:memberId/confirm
 */
export const confirmGroupMember =
    (threadId: number, memberId: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-thread-group-members/${memberId}/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchError)
            .then((json) => json.data)
            .then((data: GroupMembership) => {
                dispatch(patchGroupMember(threadId, data))
                return data
            })
            .catch((error: APIError) => Promise.reject(error))

/**
 * POST /chat-threads/:threadId/members/:memberId/unmark
 */
export const unmarkGroupMember =
    (threadId: number, memberId: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-thread-group-members/${memberId}/unmark`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchError)
            .then((json) => json.data)
            .then((data: GroupMembership) => {
                dispatch(fetchChatNews())
                return data
            })
            .catch((error: APIError) => Promise.reject(error))
