import { ChatbotWidgetDialogues } from '../../components/chatbot/chatbot-dialogues'
import { ChatbotContextEnum } from '../../components/chatbot/chatbot.props'
import { FideoThunkDispatch } from '../state.props'
import { ChatbotDialogueAnswer, ChatbotDialogueItem } from './chatbot.props'

export const ADD_ITEM = 'ADD_ITEM'
export const ADD_ITEM_AFTER = 'ADD_ITEM_AFTER'
export const ADD_ITEMS = 'ADD_ITEMS'
export const APPEND_ITEMS = 'APPEND_ITEMS'
export const REPLACE_ITEM_CONTENT = 'REPLACE_ITEM_CONTENT'
export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const SET_NEXT_ITEM_ACTIVE = 'SET_NEXT_ITEM_ACTIVE'
export const EVALUATE_ANSWERS = 'EVALUATE_ANSWERS'

export const addItem =
    (context: ChatbotContextEnum, item: ChatbotDialogueItem, addAfterIndex: number | null = null) =>
    (dispatch: FideoThunkDispatch) => {
        if (addAfterIndex) {
            dispatch({
                type: ADD_ITEM_AFTER,
                context,
                item,
                addAfterIndex,
            })
        } else {
            dispatch({
                type: ADD_ITEM,
                context,
                item,
            })
        }
    }

export const addItems =
    (context: ChatbotContextEnum, items: ChatbotDialogueItem[]) => (dispatch: FideoThunkDispatch) =>
        dispatch({
            type: ADD_ITEMS,
            context,
            data: items,
        })

export const replaceItemContent =
    (context: ChatbotContextEnum, id: number, placeholder: string) =>
    (dispatch: FideoThunkDispatch) =>
        dispatch({
            type: REPLACE_ITEM_CONTENT,
            context,
            id,
            placeholder,
        })

/**
 * Sets the following(!) item to active, pushes it to
 * the end of the array
 *
 * @param {number} id Last active item
 */
export const setNextItemActive =
    (context: ChatbotContextEnum, id: number) => (dispatch: FideoThunkDispatch) =>
        dispatch({
            type: SET_NEXT_ITEM_ACTIVE,
            context,
            id,
        })

export const updateSelfTestAnswer =
    (context: ChatbotContextEnum, id: number, answer: ChatbotDialogueAnswer) =>
    (dispatch: FideoThunkDispatch) => {
        dispatch({
            type: UPDATE_ANSWER,
            context,
            id,
            data: { ...answer },
        })
        dispatch({
            type: EVALUATE_ANSWERS,
            context,
            id,
        })
        dispatch({
            type: SET_NEXT_ITEM_ACTIVE,
            context,
            id,
        })

        if (id === 10) {
            // On display of evaluation result: Wait, then show
            // global chatbot navigation dialogue [#2085]
            // without login/logout actions [#2778]
            window.setTimeout(() => {
                dispatch({
                    type: APPEND_ITEMS,
                    context,
                    data: ChatbotWidgetDialogues.filter((item) => item.id < 6).map((item) => ({
                        ...item,
                        id: 20 + item.id,
                    })),
                })
            }, 9000)
        }
    }
