import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'

import ChatThreadAddButton from '../../../components/chat/chat-thread-add-button'
import ChatTopicList from '../../../components/chat/chat-topic-list'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSeo from '../../../hooks/use-seo'
import { fetchChatTopics } from '../../../store/chat-topics/chat-topics.actions'
import { getChatTopics } from '../../../store/chat-topics/chat-topics.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../../utils/configs'

/**
 * ChatTopics list page
 * @route /chat
 */
const ChatTopicOverview: FC = () => {
    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_CHAT_TOPIC_OVERVIEW)
    )
    const chatTopics = useSelector((state: GlobalState) => getChatTopics(state))

    useEffect(() => {
        // Force update/initial fetch of chatTopics
        dispatch(fetchChatTopics(true)).catch((err) => {}) // @todo "Keine Netwerkverbindung"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Update canonical link
    useSeo({
        title: 'Tausche Dich aus',
        metaTags: [],
        slug: '/chat',
    })

    return (
        <div className="page page--chat page--chat-topic-overview bg-green">
            <div className="page-header">
                <h1 className="title-white">Tausche Dich mit anderen aus</h1>
                <p
                    className="text-white"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <div className="headline-section">Themen</div>
            {(!chatTopics || chatTopics.length === 0) && <Loading />}
            <ChatTopicList topics={chatTopics?.filter((chatTopic) => chatTopic.isHighlighted)} />

            <div className="headline-section">Weitere Themen</div>
            {(!chatTopics || chatTopics.length === 0) && <Loading />}
            <ChatTopicList topics={chatTopics?.filter((chatTopic) => !chatTopic.isHighlighted)} />

            <ChatThreadAddButton />
        </div>
    )
}

export default ChatTopicOverview
