import React, { FC } from 'react'

import InstagramIcon from '../../../images/instagram.svg'

const InstaBanner: FC = () => (
    <aside className="insta-banner">
        <a
            href="https://instagram.com/stark_gegen_depression?igshid=1fts46zglftlv"
            title="Folge uns auf Instagram! (externer Link)"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={InstagramIcon} alt="Logo Instagram" width="36" height="36" />
            <div>
                <h2>Folge uns auf Instagram!</h2>
                <p>@stark_gegen_depression</p>
            </div>
        </a>
    </aside>
)

export default InstaBanner
