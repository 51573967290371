import React, { FC, useState } from 'react'

import ChatThreadForm from '../../../components/chat/chat-thread-form'
import { ChatThreadRequest } from '../../../store/chat-threads/chat-threads.props'
import { getApiToken, getApiUrl, handleFetchErrorAsObject } from '../../../utils/api'

/**
 * @route /chat/beitrag/hinzufuegen
 */
const ChatThreadAddForm: FC = () => {
    const [linkTarget, setLinkTarget] = useState<string>('/chat')

    const postChatThread = (body: ChatThreadRequest): Promise<any> =>
        fetch(`${getApiUrl()}/chat-threads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify(body),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => {
                setLinkTarget(`/chat/beitrag/${json.data.id}`)
                return json
            })
            .catch((error) => {
                return Promise.reject(error)
            })

    return (
        <div className="page page--chat-thread-new">
            <ChatThreadForm
                submitAction={postChatThread}
                linkText={`Zum Beitrag`}
                linkTarget={linkTarget}
            />
        </div>
    )
}

export default ChatThreadAddForm
