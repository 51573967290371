export interface User {
    id: number
    username: string
    createdAt: string
    phoneNumber: string
    email?: string
    residence?: string
    phoneNumberPending?: string
    unreadMessagesCount?: number
    isMod?: boolean
}

export enum LoginStatus {
    CREDENTIALS_ERROR = 'CREDENTIALS_ERROR',
    NETWORK_ERROR = 'NETWORK_ERROR',
    PENDING = 'PENDING',
    LOGGED_OUT = 'LOGGED_OUT',
    LOGGED_IN = 'LOGGED_IN',
}

export enum VerificationTarget {
    REGISTER = 'register',
    PASSWORD = 'password',
    PASSWORD_RESET = 'password-reset',
    PHONE_NUMBER = 'phone-number',
}

export interface SessionUserState {
    token: string | null
    showWebappInstallDialogue: boolean
    loginStatus: LoginStatus
    user?: User
}

export interface CheckUsernameExistsResponse {
    data: {
        valid: boolean
        freeForRegistration: boolean
    }
}

export interface SearchUsernameResponseItem {
    username: string
    isMod: boolean
}

export interface SearchUsernameResponse {
    data: SearchUsernameResponseItem[]
}
