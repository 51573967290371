import React, { FC, useEffect } from 'react'
import { FAQPage, WithContext } from 'schema-dts'
import striptags from 'striptags'

import FaqQuestionListItem from '../faq-question-list-item'
import { FaqQuestionListProps } from './faq-question-list.props'

const FaqQuestionList: FC<FaqQuestionListProps> = ({ faqQuestions, includeJsonLd }) => {
    useEffect(() => {
        if (!faqQuestions?.length || !includeJsonLd) {
            return
        }

        /**
         * FAQ JSON-LD Schema for Google Results
         * Schema generator: https://saijogeorge.com/json-ld-schema-generator/faq/
         * Tester: https://search.google.com/test/rich-results
         */
        const script = document.createElement('script')
        script.type = 'application/ld+json'
        const json: WithContext<FAQPage> = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity:
                faqQuestions?.map((question) => ({
                    '@type': 'Question',
                    name: question.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: striptags(question.answer),
                    },
                })) || [],
        }

        script.innerText = JSON.stringify(json)
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [faqQuestions])

    return (
        <div className="component--faq-question-list">
            {faqQuestions?.map((question) => (
                <FaqQuestionListItem question={question} key={question.id} />
            ))}
        </div>
    )
}

export default FaqQuestionList
