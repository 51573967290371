import cn from 'classnames'
import React, { FC, FormEvent } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'

import FideoLogo from '../../../images/fideo-logo.svg'
import { GlobalState } from '../../../store/state.props'
import { LoginStatus, SessionUserState } from '../../../store/user/user.props'
import UnreadNotification from '../../unread-notification'
import NavbarLink from './navbar.props'

interface NavbarProps {
    navbarIsVisible: boolean
    toggleNavbarVisibility: () => void
}

const Navbar: FC<NavbarProps> = ({ navbarIsVisible, toggleNavbarVisibility }) => {
    const sessionState = useSelector((state: GlobalState) => state.userSession)
    const navigate = useNavigate()
    const location = useLocation()

    const onClickToggleMenu = () => {
        toggleNavbarVisibility()
    }

    const onSubmitSearch = (ev: FormEvent) => {
        const input = document.querySelector('input[name="q"]') as HTMLInputElement
        navigate(`/suche?q=${input.value}`)
        toggleNavbarVisibility()
        input.blur()
        ev.preventDefault()
    }

    /** Render related parent link of active page as active */
    const getNavbarLinkClass = (slug: string): string =>
        (slug === '/' && location.pathname === '/') ||
        (slug !== '/' && location.pathname.indexOf(slug) >= 0) ||
        ((location.pathname === '/kontakt' || location.pathname === '/flyer-bestellen') &&
            slug === '/weiteres') ||
        ((location.pathname.indexOf('/notfallkoffer') >= 0 ||
            location.pathname.indexOf('/einstellungen') >= 0) &&
            slug === '/profil')
            ? 'active'
            : ''

    return (
        <nav className={cn('navbar', { 'is-open': navbarIsVisible })}>
            <div
                className="navbar__toggle-menu hide-for-desktop"
                title="Menü schließen"
                onClick={onClickToggleMenu}
            ></div>
            <Link to="/" title="Zur Startseite" className="navbar__logo hide-for-desktop-down">
                <img src={FideoLogo} alt="Logo Fideo" width="120" height="54" />
            </Link>
            <ul className="navbar-list flex-wrapper">
                {navbarLinks
                    .filter(
                        (navLink) =>
                            typeof navLink.condition === 'undefined' ||
                            navLink.condition(sessionState),
                    )
                    .map((navLink, key) => (
                        <li
                            key={key}
                            className={getNavbarLinkClass(navLink.slug)}
                            onClick={() => toggleNavbarVisibility()}
                        >
                            <NavLink to={navLink.slug} title={navLink.title}>
                                {navLink.menuLabel}
                                {navLink.slug === '/profil' && <UnreadNotification />}
                            </NavLink>
                        </li>
                    ))}
                <li className="navbar-list__search">
                    <form method="GET" action="/suche" onSubmit={onSubmitSearch}>
                        <input name="q" type="text" placeholder="Suche" />
                        <a href="#"></a>
                    </form>
                </li>
            </ul>
        </nav>
    )
}

export const navbarLinks: NavbarLink[] = [
    {
        menuLabel: 'Startseite',
        title: 'Startseite',
        slug: '/',
    },
    {
        menuLabel: 'Tausche Dich aus',
        title: 'Chat',
        slug: '/chat',
    },
    {
        menuLabel: 'Finde Antworten',
        title: 'Fragen & Antworten',
        slug: '/antworten',
    },
    {
        menuLabel: 'Selbsttest',
        title: 'Selbsttest',
        slug: '/selbsttest',
    },
    {
        menuLabel: 'Für Schulen',
        title: 'Für Schulen',
        slug: '/schule',
    },
    {
        menuLabel: 'Dein Bereich',
        title: 'Dein Bereich',
        slug: '/profil',
        condition: (state: SessionUserState) => state.loginStatus === LoginStatus.LOGGED_IN,
    },
    {
        menuLabel: 'Login / Registrierung',
        title: 'Anmeldung',
        slug: '/auth',
        condition: (state: SessionUserState) => state.loginStatus !== LoginStatus.LOGGED_IN,
    },
    {
        menuLabel: 'Noch mehr Informationen',
        title: 'Weiteres',
        slug: '/weiteres',
    },
]

// Internal "pseudo" links for titlebar-specifc styling
const _emergencyKitLink: NavbarLink = {
    menuLabel: '',
    title: 'Notfallkoffer',
    slug: '/notfallkoffer',
}

const _searchLink: NavbarLink = {
    menuLabel: '',
    title: 'Suche',
    slug: '/suche?q=',
}

export const getNavbarLinkBySlug = (slug: string): NavbarLink => {
    return (
        navbarLinks.find(
            (navLink, index) =>
                (index === 0 && slug === '/') || (index !== 0 && slug.indexOf(navLink.slug) === 0),
        ) ||
        (slug.indexOf('/notfallkoffer') === 0 ? _emergencyKitLink : false) ||
        (slug.indexOf('/suche') === 0
            ? _searchLink
            : {
                  menuLabel: '',
                  title: slug.replace('/', ''),
                  slug,
              })
    )
}

export default Navbar
