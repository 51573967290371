import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useFideoDispatch } from '../hooks/use-fideo-dispatch'
import { fetchChatNews } from '../store/chat-threads/chat-threads.actions'
import { GlobalState } from '../store/state.props'
import { getUserProfile, resetSession } from '../store/user/user.actions'
import { LoginStatus } from '../store/user/user.props'
import { APIError } from './api'
import { isAuthenticationError } from './errors'

/**
 * Handles initial authentication of user-account.
 * Delegates requesting private messages on a definable interval.
 */
const PING_INTERVAL = 60000

let pingInterval: number = 0
let pingInProgress: boolean = false

const UserSessionHandler: FC = () => {
    const dispatch = useFideoDispatch()
    const userSession = useSelector((state: GlobalState) => state.userSession)

    const pingAction = () => {
        if (userSession && userSession.loginStatus === LoginStatus.LOGGED_IN && !pingInProgress) {
            pingInProgress = true

            dispatch(fetchChatNews())
                .catch((error: APIError) => {
                    if (isAuthenticationError(error)) {
                        stopPingChatThreadNews()
                        // @todo Apparently, session expired; should user be logged out
                    }
                })
                .finally(() => (pingInProgress = false))
        }
    }

    const startPingChatThreadNews = () => {
        pingAction()
        pingInterval = window.setInterval(pingAction, PING_INTERVAL)
    }

    const stopPingChatThreadNews = () => window.clearInterval(pingInterval)

    useEffect(() => {
        if (userSession.token) {
            // On app start: Check if session-token from localStorage is still valid;
            // either update local user or remove invalid token & login-status
            dispatch(getUserProfile()).catch(() => {
                dispatch(resetSession())
            })
        } else {
            dispatch(resetSession())
        }
    }, [])

    useEffect(() => {
        stopPingChatThreadNews()
        if (userSession) {
            startPingChatThreadNews()
        }
    }, [userSession])

    return null
}

export default UserSessionHandler
