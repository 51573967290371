import { getApiToken, getApiUrl, handleFetchError } from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult } from '../state.props'
import {
    EmergencyKitState,
    IEmergencyKitItem,
    IEmergencyKitKitTextRequestItem,
} from './emergency-kit.props'

export const FETCH_EMERGENCY_KIT_ITEMS_PENDING = 'FETCH_EMERGENCY_KIT_ITEMS_PENDING'
export const ADD_EMERGENCY_KIT_ITEM = 'ADD_EMERGENY_KIT_ITEM'
export const ADD_EMERGENCY_KIT_ITEMS = 'ADD_EMERGENCY_KIT_ITEMS'
export const DELETE_EMERGENCY_KIT_ITEM = 'DELETE_EMERGENCY_KIT_ITEM'
export const MOVE_EMERGENCY_KIT_ITEM_UP = 'MOVE_EMERGENCY_KIT_ITEM_UP'
export const MOVE_EMERGENCY_KIT_ITEM_DOWN = 'MOVE_EMERGENCY_KIT_ITEM_DOWN'
export const UNSET_EMERGENCY_KIT_ITEM_UPLOADED_FLAG = 'UNSET_EMERGENCY_KIT_ITEM_UPLOADED_FLAG'

const fetchEmergencyKitItemsPending = () => {
    return {
        type: FETCH_EMERGENCY_KIT_ITEMS_PENDING,
    }
}

const fetchEmergencyKitItemsSuccess = (emergencyKitItems: EmergencyKitState) => {
    return {
        type: ADD_EMERGENCY_KIT_ITEMS,
        emergencyKitItems,
    }
}

const addEmergencyKitItem = (emergencyKitItem: IEmergencyKitItem) => {
    return {
        type: ADD_EMERGENCY_KIT_ITEM,
        emergencyKitItem: {
            ...emergencyKitItem,
            justUploaded: true,
        },
    }
}

export const deleteEmergencyKitItem = (id: number) => {
    return {
        type: DELETE_EMERGENCY_KIT_ITEM,
        id,
    }
}

export const moveEmergencyKitItemUp = (item: IEmergencyKitItem) => {
    return {
        type: MOVE_EMERGENCY_KIT_ITEM_UP,
        item,
    }
}

export const moveEmergencyKitItemDown = (item: IEmergencyKitItem) => {
    return {
        type: MOVE_EMERGENCY_KIT_ITEM_DOWN,
        item,
    }
}

export const unsetEmergencyKitItemsUploadedFlag = () => {
    return {
        type: UNSET_EMERGENCY_KIT_ITEM_UPLOADED_FLAG,
    }
}

/**
 * GET /emergency-kit-items
 */
export const fetchEmergencyKitItems =
    (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) => {
        dispatch(fetchEmergencyKitItemsPending())

        return fetch(`${getApiUrl()}/emergency-kit-items`, {
            headers: {
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchEmergencyKitItemsSuccess(json))
                return json
            })
            .catch((error) => Promise.reject(error))
    }

/**
 * PATCH /emergency-kit-items/texts
 *
 * Sets the order of the items. All other items will be deleted.
 * E.g. previous: [2,3,4,5]; new "orderedItems": [5,3] (which tells API to remove 2 & 4; change position of 5 & 3)
 */
export const patchEmergencyKitItems =
    (orderedItems: any): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/emergency-kit-items`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify(orderedItems),
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchEmergencyKitItemsSuccess(json))
                return json
            })
            .catch((error) => Promise.reject(error))

/**
 * POST /emergency-kit-items/texts
 */
export const postEmergencyKitTextItem =
    (body: IEmergencyKitKitTextRequestItem): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/emergency-kit-items/texts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify(body),
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(addEmergencyKitItem(json.data))
                return json
            })
            .catch((error) => Promise.reject(error))

/**
 * POST /emergency-kit-items/images
 */
export const postEmergencyKitImageItem =
    (body: FormData): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/emergency-kit-items/images`, {
            method: 'POST',
            headers: {
                'X-AUTH-TOKEN': getApiToken(),
            },
            body,
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(addEmergencyKitItem(json.data))
                return json
            })
            .catch((error) => Promise.reject(error))
