import { GlobalState } from '../state.props'
import { Pagination } from '../state.props'
import { ChatPost } from './chat-posts.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */
export const getChatPostById = (state: GlobalState, id: number): ChatPost | undefined =>
    state.chatPosts.data.find((chatPost) => chatPost.id === id)

export const getChatPostsByThreadId = (
    state: GlobalState,
    chatThreadId: number | undefined
): ChatPost[] => state.chatPosts.data.filter((chatPost) => chatPost.chatThreadId === chatThreadId)

export const getChatPostsByCreatorName = (
    state: GlobalState,
    creatorName: string | undefined
): ChatPost[] => state.chatPosts.data.filter((chatPost) => chatPost.creatorName === creatorName)

export const getChatPostPagination = (state: GlobalState): Pagination => state.chatPosts.pagination
