import { APIError, getApiToken, getApiUrl, handleFetchErrorAsObject } from '../../../utils/api'

export const ReportChatThread = (chatThreadId: number) => {
    if (!window.confirm('Wirklich melden?')) {
        return false
    }

    return fetch(`${getApiUrl()}/chat-thread-reports`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': getApiToken(),
        },
        body: JSON.stringify({ chatThreadId }),
    })
        .then(handleFetchErrorAsObject)
        .then((json) => {
            alert('Beitrag wurde gemeldet.')
        })
        .catch((error: APIError) => {
            if (error.error && error.error.code && error.error.code === 403) {
                alert(
                    'Dein Konto wurde deaktiviert. Bitte nimm Kontakt mit der Redaktion auf: redaktion@fideo.de'
                )
                return
            }

            alert(
                'Beitrag konnte nicht gemeldet werden. Bitte vergewissere Dich, dass eine Netzwerkverbindung besteht.'
            )
        })
}
