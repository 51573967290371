import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { FaqQuestionListItemProps } from './faq-question-list-item.props'

const FaqQuestionListItem: FC<FaqQuestionListItemProps> = ({ question }) => {
    return (
        <Link
            className="component--faq-question-list-item faq-card-question"
            to={
                question.seo && question.seo.slug
                    ? question.seo.slug
                    : `/antworten/frage/${question.id}`
            }
        >
            <h2>{question.question}</h2>
        </Link>
    )
}

export default FaqQuestionListItem
