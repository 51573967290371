export const calendarFormat = {
    sameDay: '[Heute], HH:mm',
    lastDay: '[Gestern], HH:mm',
    lastWeek: '[Letzten] dddd, HH:mm',
    sameElse: 'DD.MM.YYYY HH:mm',
}

export const calendarFormatLowercase = {
    sameDay: '[heute], HH:mm',
    lastDay: '[gestern], HH:mm',
    lastWeek: '[letzten] dddd',
    sameElse: 'DD.MM.YYYY',
}
