import { ChatbotDialogueItem } from '../../../store/chatbot/chatbot.props'
import { hideWebappInstallDialogue } from '../../../store/user/user.actions'
import { FIDEO_A2HS_SKIP_COOKIE, triggerInstallPrompt } from '../../../utils/A2HS'
import { setCookie } from '../../../utils/cookies'
import { ChatbotDialogueItemActionType } from '.././chatbot.props'

export const ChatbotWebappInstallDialogues: ChatbotDialogueItem[] = [
    {
        id: 0,
        type: 'left',
        active: true,
        content:
            'Möchtest Du FIDEO zu Deinem Startbildschirm hinzufügen? So hast Du uns immer griffbereit!',
    },
    {
        id: 1,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.CLICK,
        active: true,
        content: 'Ja',
        action: ({ closeWidget, dispatch, setInvalidAnswer }) => {
            triggerInstallPrompt()
                .then((choiceResult: any) => {
                    if (choiceResult.outcome === 'accepted') {
                        setInvalidAnswer(
                            null,
                            `Super, Fideo ist jetzt als Web App auf Deinem Gerät eingerichtet!`
                        )
                        window.setTimeout(() => {
                            dispatch(hideWebappInstallDialogue())
                            closeWidget()
                        }, 2500)
                    } else {
                        setInvalidAnswer(
                            null,
                            `Upps, da hat etwas nicht geklappt. Bitte probiere es erneut.`
                        )
                    }
                })
                .catch((err) => {
                    setInvalidAnswer(
                        null,
                        `Upps, da hat etwas nicht geklappt. Möglicherweise unterstützt Dein Browser keine Web Apps. Bitte probiere es erneut.`
                    )
                })
        },
    },
    {
        id: 2,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.CLICK,
        active: true,
        content: 'Jetzt nicht',
        action: ({ closeWidget, dispatch }) => {
            // Hide dialogue for 4 days
            setCookie(FIDEO_A2HS_SKIP_COOKIE, '1', 4)
            dispatch(hideWebappInstallDialogue())
            closeWidget()
        },
    },
]
