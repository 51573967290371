import {
    getApiRequestHeaders,
    getApiToken,
    getApiUrl,
    handleFetchError,
    handleFetchErrorAsObject,
} from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult, GlobalState, Pagination } from '../state.props'
import {
    ChatPost,
    ChatPostPatchRequest,
    ChatPostRequest,
    ChatPostResponse,
    ChatPostsResponse,
} from './chat-posts.props'
import { getChatPostPagination } from './chat-posts.selectors'

export const FETCH_CHAT_POST = 'FETCH_CHAT_POST'
export const FETCH_CHAT_POSTS = 'FETCH_CHAT_POSTS'
export const FETCH_CHAT_POSTS_PENDING = 'FETCH_CHAT_POSTS_PENDING'
export const FETCH_CHAT_POSTS_ERROR = 'FETCH_CHAT_POSTS_ERROR'
export const RESET_CHAT_POSTS = 'RESET_CHAT_POSTS'
export const ADD_CHAT_POST = 'ADD_CHAT_POST'
export const ADD_CHAT_POSTS = 'ADD_CHAT_POSTS'
export const RESET_PAGINATION = 'RESET_PAGINATION'
export const UPDATE_POSTS_PAGINATION = 'UPDATE_POSTS_PAGINATION'
export const PATCH_CHAT_POST = 'PATCH_CHAT_POST'

const fetchChatPostsSuccess = (chatPosts: ChatPostsResponse) => {
    return {
        type: ADD_CHAT_POSTS,
        chatPosts,
    }
}

const submitChatPostSuccess = (chatPost: ChatPost) => {
    return {
        type: ADD_CHAT_POST,
        chatPost,
    }
}

const fetchChatPostsPending = () => {
    return {
        type: FETCH_CHAT_POSTS_PENDING,
    }
}

const fetchError = () => {
    return {
        type: FETCH_CHAT_POSTS_ERROR,
    }
}

const updatePagination = (pagination: Pagination) => {
    return {
        type: UPDATE_POSTS_PAGINATION,
        pagination,
    }
}

const patchChatPostSuccess = (chatPost: ChatPostResponse) => {
    return {
        type: PATCH_CHAT_POST,
        chatPost,
    }
}

/**
 * GET /chat-posts/:id
 */
export const fetchChatPost =
    (id: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatPostsPending())

        return fetch(`${getApiUrl()}/chat-posts/${id}`)
            .then(handleFetchError)
            .then((json) => {
                dispatch({
                    type: ADD_CHAT_POST,
                    chatPost: json.data,
                })

                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * GET /chat-posts?chat_thread_id=
 */
export const fetchChatPostsByChatThreadId =
    (chatThreadId: number, isInitialCall: boolean = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatPostsPending())

        let nextPage = 0

        if (!isInitialCall) {
            nextPage = getChatPostPagination(getState()).nextPage || 0
        }

        return fetch(`${getApiUrl()}/chat-posts?chat_thread_id=${chatThreadId}&page=${nextPage}`, {
            headers: getApiRequestHeaders(),
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch({
                    type: nextPage === 0 ? RESET_CHAT_POSTS : ADD_CHAT_POSTS,
                    chatPosts: json,
                })

                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * GET /chat-posts?chat_thread_id=
 * w/ X-AUTH-TOKEN, skips pagination
 */
export const fetchPrivateChatPosts =
    (chatThreadId: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-posts?chat_thread_id=${chatThreadId}`, {
            headers: {
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch({
                    type: ADD_CHAT_POSTS,
                    chatPosts: json,
                })

                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })

/**
 * GET /chat-posts?creator_name=:string
 */
export const fetchChatPostsByCreatorName =
    (creatorName: string, isInitialCall: boolean = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatPostsPending())

        let nextPage = 0

        if (!isInitialCall) {
            nextPage = getChatPostPagination(getState()).nextPage || 0
        }

        return fetch(
            `${getApiUrl()}/chat-posts?creator_name=${creatorName}&page=${nextPage}&show_chat_thread=1`,
            {
                headers: {
                    'X-AUTH-TOKEN': getApiToken(),
                },
            }
        )
            .then(handleFetchError)
            .then((json) => {
                dispatch({
                    type: nextPage === 0 ? RESET_CHAT_POSTS : ADD_CHAT_POSTS,
                    chatPosts: json,
                })

                return json
            })
            .catch(() => {
                // Code "404" may simply indicate a blacklisted element
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * POST /chat-posts
 */
export const postChatPost =
    (body: ChatPostRequest): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-posts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify(body),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => {
                dispatch(submitChatPostSuccess(json.data))
                return json
            })
            .catch((error) => {
                return Promise.reject(error)
            })

/**
 * PATCH /chat-posts/:id
 */
export const patchChatPost =
    (id: number, body: ChatPostPatchRequest): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/chat-posts/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify(body),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => {
                dispatch(patchChatPostSuccess(json))
                return json
            })
            .catch(() => {
                return Promise.reject()
            })
