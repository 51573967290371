import { Captcha } from '../../hooks/use-captcha'
import { getApiUrl, handleFetchErrorAsObject } from '../../utils/api'

export interface FlyerFormRequest {
    firstName: string
    lastName: string
    email: string
    notes: string
    institution: string
    streetAndNumber: string
    zip: string
    city: string
    quantity: number
    captcha: Captcha
}

export const postFlyerForm = (body: FlyerFormRequest): Promise<any> => {
    return fetch(`${getApiUrl()}/flyer-orders`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleFetchErrorAsObject)
        .then((json) => json)
        .catch((error) => {
            return Promise.reject(error)
        })
}
