import arrayMove from 'array-move'
import { AnyAction } from 'redux'

import { RESET_SESSION } from '../user/user.actions'
import {
    ADD_EMERGENCY_KIT_ITEM,
    ADD_EMERGENCY_KIT_ITEMS,
    DELETE_EMERGENCY_KIT_ITEM,
    FETCH_EMERGENCY_KIT_ITEMS_PENDING,
    MOVE_EMERGENCY_KIT_ITEM_DOWN,
    MOVE_EMERGENCY_KIT_ITEM_UP,
    UNSET_EMERGENCY_KIT_ITEM_UPLOADED_FLAG,
} from './emergency-kit.actions'
import { EmergencyKitState } from './emergency-kit.props'

const initialState: EmergencyKitState = {
    data: [],
    isPending: false,
}

const EmergencyKitReducer = (
    state: EmergencyKitState = initialState,
    action: AnyAction
): EmergencyKitState => {
    switch (action.type) {
        case FETCH_EMERGENCY_KIT_ITEMS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case ADD_EMERGENCY_KIT_ITEM:
            return {
                ...state,
                data: [...state.data, action.emergencyKitItem],
            }
        case ADD_EMERGENCY_KIT_ITEMS:
            return {
                data: action.emergencyKitItems.data,
                isPending: false,
            }
        case DELETE_EMERGENCY_KIT_ITEM:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.id),
            }
        case MOVE_EMERGENCY_KIT_ITEM_UP:
            const upIndex = state.data.findIndex((item) => item.id === action.item.id)

            return {
                ...state,
                data: arrayMove(state.data, upIndex, upIndex - 1),
            }
        case MOVE_EMERGENCY_KIT_ITEM_DOWN:
            const downIndex = state.data.findIndex((item) => item.id === action.item.id)
            return {
                ...state,
                data: arrayMove(state.data, downIndex, downIndex + 1),
            }
        case UNSET_EMERGENCY_KIT_ITEM_UPLOADED_FLAG:
            return {
                ...state,
                data: state.data.map((item) => ({
                    ...item,
                    justUploaded: false,
                })),
            }
        case RESET_SESSION:
            return initialState
    }

    return state
}

export default EmergencyKitReducer
