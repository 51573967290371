import env from '../../.env.local.json'

const EMPTY_API_TOKEN = 'error-no-token'

export const handleFetchError = (response: Response): Promise<any> => {
    if (!response.ok) {
        throw Error(response.statusText)
    }

    return response.json()
}

export interface ActualErrorFields {
    type: string
    title: string
    detail: string
}

interface APIErrorFieldIterator {
    messageTemplate: string
    message: string
}

export interface APIErrorFieldsIterators {
    iterator: APIErrorFieldIterator[]
}
interface ActualAPIError {
    message: string
    code: number
    fields?: ActualErrorFields | APIErrorFieldsIterators
}

export interface APIError {
    error?: ActualAPIError
    status?: number
    message?: string
}

export const handleFetchErrorAsObject = (response: Response): Promise<any | APIError> => {
    if (!response.ok) {
        return response.text().then((text: string) => Promise.reject(JSON.parse(text)))
    }

    return response.json()
}

export const getApiUrl = (): string => env.apiUrl

export const getApiToken = (): string => window.localStorage.getItem('token') || EMPTY_API_TOKEN

export const getApiRequestHeaders = (): HeadersInit => {
    const token = getApiToken()

    if (token && token !== EMPTY_API_TOKEN) {
        return {
            'X-AUTH-TOKEN': token,
        }
    }

    return {}
}
