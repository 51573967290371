import React, { FC, useEffect, useState } from 'react'

import ChatbotIcon from '../../../images/chatbot.svg'
import { getCookie } from '../../../utils/cookies'
import { ChatbotWidgetSwitchProps } from './chatbot-widget-switch.props'

/**
 * Requirements #2598 (if chatbot-context === A2HS): Wait ~20s to
 * show "Add to homescreen" tooltip. If consent not yet given,
 * wait until it given and then start. If on homepage, wait till
 * user scrolled at least one window-height.
 */

const TOOLTIP_DISPLAY_INTERVAL = 10000
let tooltipAlreadyShown = false

const ChatbotWidgetSwitch: FC<ChatbotWidgetSwitchProps> = ({
    toggleChatbotWidgetIsVisible,
    showWebappInstallDialogue,
}) => {
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
    const [timeout, setTimeout] = useState<number>()

    const showTooltip = () => {
        setTooltipVisible(true)
        tooltipAlreadyShown = true
    }

    const startTimeout = () => {
        const body = document.querySelector('body')

        if (body?.getAttribute('data-page') !== '') {
            // Active page is not homepage
            setTimeout(window.setTimeout(showTooltip, TOOLTIP_DISPLAY_INTERVAL))

            if (tooltipAlreadyShown) {
                showTooltip()
            }
        } else {
            // Active page is homepage; wait till user scrolled one window height
            document.addEventListener('scroll', function () {
                if (window.scrollY >= window.innerHeight) {
                    showTooltip()
                }
            })
        }

        tooltipAlreadyShown = true
    }

    useEffect(() => {
        if (showWebappInstallDialogue) {
            const consentManagerClosed = getCookie('klaro') !== null

            if (consentManagerClosed) {
                // Consent agreed: Show immediately
                startTimeout()
            } else {
                // Consent not yet given: Wait till klaro-window closed
                const interval = window.setInterval(() => {
                    const klaroWindow = document.querySelector('.klaro .cn-body')
                    if (klaroWindow === null) {
                        startTimeout()
                        window.clearInterval(interval)
                    }
                }, 1000)
            }
        }

        return () => {
            window.clearTimeout(timeout)
        }
        // eslint-disable-next-line
    }, [showWebappInstallDialogue])

    return (
        <div className="component--chatbot-widget-switch">
            {tooltipVisible && (
                <div className="component--chatbot-dialogue-list-item chatbot-widget-tooltip">
                    <div className="list-item-content" data-dialogue-type="left">
                        Möchtest Du FIDEO zu Deinem Startbildschirm hinzufügen? So hast Du uns immer
                        griffbereit!
                    </div>
                </div>
            )}
            <img
                src={ChatbotIcon}
                alt="Icon das zwei Sprechblasen zeigt"
                width="93"
                height="81"
                loading="lazy"
                decoding="async"
                onClick={() => toggleChatbotWidgetIsVisible(true)}
            />
        </div>
    )
}

export default ChatbotWidgetSwitch
