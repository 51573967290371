import { GlobalState, Pagination } from '../state.props'
import { ChatNews, ChatThread, GroupChatNewsType, MarkedGroupChatNews } from './chat-threads.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */
export const getPrivateChatThread = (state: GlobalState): ChatThread | undefined =>
    state.chatThreads.privateChatThread

export const getChatThreadById = (state: GlobalState, id: number): ChatThread | undefined =>
    state.chatThreads.data.find((chatThread) => chatThread.id === id)

export const getChatThreadsByTopicId = (state: GlobalState, chatTopicId: number): ChatThread[] =>
    state.chatThreads.data.filter((chatThread) => chatThread.chatTopicId === chatTopicId)

export const getChatThreadsByCreatorName = (
    state: GlobalState,
    creatorName: string | undefined
): ChatThread[] =>
    creatorName
        ? state.chatThreads.data.filter((chatThread) => chatThread.creatorName === creatorName)
        : []

export const getChatThreadsByMemberUsername = (
    state: GlobalState,
    memberUsername: string | undefined
): ChatThread[] => {
    // Disable filtering for mod-users to show ChatThreads where the user is not a member. The server
    // guarantees that only the allowed groups are shown here
    if (state.userSession.user?.isMod) {
        return state.chatThreads.data
    }

    return memberUsername
        ? state.chatThreads.data.filter((chatThread) =>
              chatThread.groupMembers?.some(
                  (membership) => membership.member.username === memberUsername
              )
          )
        : []
}

export const getChatThreadsPagination = (state: GlobalState): Pagination =>
    state.chatThreads.pagination

export const getChatNews = (state: GlobalState): ChatNews[] => state.chatThreads.chatNews

export const getChatNewsById = (state: GlobalState, threadId: number): ChatNews | undefined =>
    state.chatThreads.chatNews.find((chatNews) => chatNews.chatThreadId === threadId)

export const getPublicChatNews = (state: GlobalState): ChatNews[] =>
    state.chatThreads.chatNews.filter(
        (chatNews) =>
            chatNews.chatThread?.type === 'public' ||
            (chatNews.chatThread?.type === 'group' && chatNews.newPostsNum >= 1)
    )

export const getPrivateChatNews = (state: GlobalState): ChatNews[] =>
    state.chatThreads.chatNews.filter((chatNews) => chatNews.chatThread?.type === 'private')

/**
 *  * A relevant "chatNews" for group chats is given if...
 * ...the logged in user is the admin of a thread whose "groupMembers" have a pending "requested" membership
 * ...the logged in user is a member of a thread whose own "groupMember" entry is "marked"
 */
export const getMarkedChatNewsTypes = (
    chatNews: ChatNews,
    username: string | undefined
): MarkedGroupChatNews[] => {
    if (!username || chatNews.chatThread?.type !== 'group') return []

    return getMarkedChatNewsTypesFromChatThread(chatNews.chatThread, username)
}

export const getMarkedChatNewsTypesFromChatThread = (
    chatThread: ChatThread,
    username: string | undefined
): MarkedGroupChatNews[] => {
    const loggedInUserIsAdmin = chatThread.groupMembers?.some(
        (membership) => membership.member.username === username && membership.state === 'admin'
    )

    let summary: MarkedGroupChatNews[] = []

    chatThread.groupMembers?.forEach((membership) => {
        if (!membership.isMarked) {
            return
        }

        if (loggedInUserIsAdmin && membership.state === 'requested') {
            summary.push({
                membershipId: membership.id,
                type: GroupChatNewsType.MEMBER_REQUESTED,
            })
        } else if (membership.member.username === username) {
            if (membership.state === 'invited') {
                summary.push({
                    membershipId: membership.id,
                    type: GroupChatNewsType.MEMBER_INVITED,
                })
            } else if (membership.state === 'member') {
                summary.push({
                    membershipId: membership.id,
                    type: GroupChatNewsType.MEMBER_JOINED,
                })
            }
        }
    })

    return summary
}

export const getGroupChatNews = (state: GlobalState, username: string | undefined): ChatNews[] =>
    username
        ? state.chatThreads.chatNews.filter(
              (chatNews) => getMarkedChatNewsTypes(chatNews, username).length >= 1
          )
        : []
