import React, { FC } from 'react'

import ChatTopicListItem from '../chat-topic-list-item'
import { ChatTopicListProps } from './chat-topic-list.props'

const ChatTopicList: FC<ChatTopicListProps> = (props) => (
    <div className="component--chat-topic-list">
        {props.topics?.map((chatTopic, key) => (
            <ChatTopicListItem chatTopic={chatTopic} key={chatTopic.id} />
        ))}
    </div>
)

export default ChatTopicList
