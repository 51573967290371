import { useEffect, useState } from 'react'

import { getApiUrl, handleFetchError } from '../utils/api'

export interface Captcha {
    token: string
    phrase: string
}

const useCaptcha = (): [string | null, string | null, () => Promise<string>] => {
    const [captchaToken, setCaptchaToken] = useState<string | null>(null)
    const [captchaImageUrl, setCaptchaImageUrl] = useState<string | null>(null)

    const fetchCaptcha = () =>
        fetch(`${getApiUrl()}/captcha`)
            .then(handleFetchError)
            .then((response) => {
                setCaptchaToken(response.data.token)
                setCaptchaImageUrl(response.data.imageUrl)
                return Promise.resolve(response.data.token)
            })

    useEffect(() => {
        fetchCaptcha()
    }, [])

    return [captchaToken, captchaImageUrl, fetchCaptcha]
}

export default useCaptcha
