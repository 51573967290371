import { FC, useEffect } from 'react'
import { SearchAction, WebSite, WithContext } from 'schema-dts'

const WebsiteSchema: FC = () => {
    useEffect(() => {
        /**
         * "WebSite" & "SearchAction" JSON-LD Schema for Google Results
         * @see https://schema.org/WebSite
         * @see https://schema.org/SearchAction
         * @see https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox
         * Tester: https://search.google.com/test/rich-results
         */
        const script = document.createElement('script')
        script.type = 'application/ld+json'
        const json: WithContext<WebSite> = {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://fideo.de',
            potentialAction: {
                '@type': 'SearchAction',
                target: {
                    '@type': 'EntryPoint',
                    urlTemplate: 'https://fideo.de/suche?q={query}',
                },
                'query-input': 'required name=query',
            } as SearchAction,
        }

        script.innerText = JSON.stringify(json)
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    })

    return null
}

export default WebsiteSchema
