import { useNavigate } from 'react-router-dom'

const windowHistory = window.history

interface UseFideoNavigate {
    gotoPrevPage: (ev: Event | any) => void
}

/**
 * Custom hook to navigate back to previous page (keeps codebase DRY)
 */
const useFideoNavigate = (): UseFideoNavigate => {
    const navigate = useNavigate()

    const gotoPrevPage = (ev: Event) => {
        windowHistory && windowHistory.length >= 1 ? navigate(-1) : navigate('/')

        if (ev) {
            ev.preventDefault()
        }
    }

    return {
        gotoPrevPage,
    }
}

export default useFideoNavigate
