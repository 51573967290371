const BASE = 'LAYOUT'
export const SHOW_NAVBAR = `${BASE}.SHOW_NAVBAR`
export const HIDE_NAVBAR = `${BASE}.HIDE_NAVBAR`
export const TOGGLE_NAVBAR = `${BASE}.TOGGLE_NAVBAR`
export const TOGGLE_CHATBOT_WIDGET = `${BASE}.TOGGLE_CHATBOT_WIDGET`
export const SET_PAGE_IS_404 = `${BASE}.SET_PAGE_IS_404`

export const toggleNavbarVisibility = () => ({
    type: TOGGLE_NAVBAR,
})

export const showNavbar = () => ({
    type: SHOW_NAVBAR,
})

export const hideNavbar = () => ({
    type: HIDE_NAVBAR,
})

export const toggleChatbotWidgetVisibility = () => ({
    type: TOGGLE_CHATBOT_WIDGET,
})

export const setPageIs404 = (pageIs404: boolean) => ({
    type: SET_PAGE_IS_404,
    pageIs404,
})
