import { AnyAction } from 'redux'

import {
    HIDE_NAVBAR,
    SET_PAGE_IS_404,
    SHOW_NAVBAR,
    TOGGLE_CHATBOT_WIDGET,
    TOGGLE_NAVBAR,
} from './layout.actions'
import { LayoutState } from './layout.props'

const initialState: LayoutState = {
    navbarIsVisible: false,
    chatbotWidgetIsVisible: false,
    pageIs404: false,
}

const LayoutReducer = (state: LayoutState = initialState, action: AnyAction): LayoutState => {
    switch (action.type) {
        case TOGGLE_NAVBAR:
            return {
                ...state,
                navbarIsVisible: !state.navbarIsVisible,
            }
        case SHOW_NAVBAR:
            return {
                ...state,
                navbarIsVisible: true,
            }
        case HIDE_NAVBAR:
            return {
                ...state,
                navbarIsVisible: false,
            }
        case TOGGLE_CHATBOT_WIDGET:
            return {
                ...state,
                chatbotWidgetIsVisible: !state.chatbotWidgetIsVisible,
            }
        case SET_PAGE_IS_404:
            return {
                ...state,
                pageIs404: action.pageIs404,
            }

        default:
            return state
    }
}

export default LayoutReducer
