import { useEffect } from 'react'

import { Seo } from '../store/state.props'
import { makeRandomID } from '../utils/configs'

const defaultTitle = document.title
const defaultDescription = (
    document.head.querySelector(`meta[name="description"]`) as HTMLMetaElement
)?.content
const defaultKeywords = (document.head.querySelector(`meta[name="keywords"]`) as HTMLMetaElement)
    ?.content

/**
 * Global hook to set document title and meta-tags
 */
const useSeo = (seo: Seo | undefined) => {
    useEffect(() => {
        if (seo) {
            // Update page title
            if (seo.title) {
                document.title = seo.title + ' | ' + defaultTitle
            }

            // Update page metatags
            seo.metaTags.forEach((metaTag) => {
                metaTag.__id = makeRandomID()
                const tag = document.createElement('meta')
                tag.setAttribute('data-id', metaTag.__id)

                if (metaTag.property && metaTag.property !== null) {
                    tag.setAttribute('property', metaTag.property)
                } else if (metaTag.name && metaTag.name !== null) {
                    tag.setAttribute('name', metaTag.name)

                    if (metaTag.name === 'description') {
                        // Avoid duplicate "description"-tag (will be re-added on unmount)
                        const descriptionTag =
                            document.head.querySelector(`meta[name="description"]`)
                        if (descriptionTag) {
                            descriptionTag.remove()
                        }
                    }
                    if (metaTag.name === 'keywords') {
                        // Avoid duplicate "keywords"-tag
                        const keywordsTag = document.head.querySelector(`meta[name="keywords"]`)
                        if (keywordsTag) {
                            keywordsTag.remove()
                        }
                    }
                }

                tag.content = metaTag.content
                document.head.appendChild(tag)
            })

            // Update page canonical link
            if (seo.slug !== null) {
                const tag = document.createElement('link')
                tag.setAttribute('rel', 'canonical')
                tag.setAttribute('href', `https://fideo.de${seo.slug}`)
                document.head.appendChild(tag)
            }
        }

        return () => {
            // On unmount: Remove meta-tags of previous page
            document.title = defaultTitle

            seo?.metaTags.forEach((metaTag) => {
                let tag = document.head.querySelector(`[data-id="${metaTag.__id}"]`)
                if (tag) {
                    tag.remove()
                }

                if (metaTag.name && metaTag.name === 'description') {
                    // Re-add description-tag
                    const descriptionTag = document.createElement('meta') as HTMLMetaElement
                    descriptionTag.setAttribute('name', 'description')
                    descriptionTag.content = defaultDescription
                    document.head.appendChild(descriptionTag)
                }

                if (metaTag.name && metaTag.name === 'keywords') {
                    // Re-add description-tag
                    const keywordsTag = document.createElement('meta') as HTMLMetaElement
                    keywordsTag.setAttribute('name', 'keywords')
                    keywordsTag.content = defaultKeywords
                    document.head.appendChild(keywordsTag)
                }
            })

            const tag = document.head.querySelector('link[rel="canonical"]')
            if (tag) {
                tag.remove()
            }
        }
    })
}

export default useSeo
