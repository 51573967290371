import { GlobalState } from '../state.props'
import { IMenu, IMenuType, IPage } from './menu-pages.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */
export const getMenusIsPending = (state: GlobalState): boolean => state.menuPages.isPending

export const getMenuByName = (state: GlobalState, type: IMenuType): IMenu | undefined =>
    state.menuPages.menues.find((menu) => menu.type === type)

export const getPage = (state: GlobalState, needle: number | string): IPage | undefined =>
    state.menuPages.pages.find((page) =>
        typeof needle === 'number' ? page.id === needle : page.seo.slug === needle
    )

export const existsMenuPageBySlug = (state: GlobalState, slug: string): boolean =>
    state.menuPages.menues.find((menu) => menu.pages.find((page) => page.slug === slug)) !==
    undefined
