import { getApiUrl, handleFetchError } from '../../utils/api'
// only for approach b), see chat-topic-detail.view
import { fetchChatThreadsByChatTopicId } from '../chat-threads/chat-threads.actions'
import { ChatThreadsState } from '../chat-threads/chat-threads.props'
import { FideoThunkDispatch, FideoThunkResult, GlobalState } from '../state.props'
import { ChatTopicResponse, ChatTopicsState } from './chat-topics.props'
import { getChatTopicById } from './chat-topics.selectors'

export const FETCH_CHAT_TOPIC = 'FETCH_CHAT_TOPIC'
export const FETCH_CHAT_TOPICS = 'FETCH_CHAT_TOPICS'
export const FETCH_CHAT_TOPICS_PENDING = 'FETCH_CHAT_TOPICS_PENDING'
export const FETCH_CHAT_TOPICS_ERROR = 'FETCH_CHAT_TOPICS_ERROR'
export const RESET_CHAT_TOPICS = 'RESET_CHAT_TOPICS'
export const ADD_CHAT_TOPIC = 'ADD_CHAT_TOPIC'
export const EDIT_CHAT_TOPIC = 'EDIT_CHAT_TOPIC'
export const ADD_CHAT_TOPICS = 'ADD_CHAT_TOPICS'
export const FETCH_CHAT_THREADS_BY_CHAT_TOPIC_ID_SUCCESS =
    'FETCH_CHAT_THREADS_BY_CHAT_TOPIC_ID_SUCCESS'

const fetchChatTopicSuccess = (chatTopic: ChatTopicResponse, state: GlobalState) => {
    const chatTopicExists = getChatTopicById(state, Number(chatTopic.data.id))

    return {
        type: chatTopicExists ? EDIT_CHAT_TOPIC : ADD_CHAT_TOPIC,
        chatTopic,
    }
}

const fetchChatTopicsSuccess = (resetData: boolean, chatTopics: ChatTopicsState) => {
    return {
        type: resetData ? RESET_CHAT_TOPICS : ADD_CHAT_TOPICS,
        chatTopics,
    }
}

const fetchChatTopicsPending = () => {
    return {
        type: FETCH_CHAT_TOPICS_PENDING,
    }
}

const fetchChatThreadsByChatTopicIdSuccess = (
    chatTopicId: number,
    chatThreads: ChatThreadsState
) => {
    return {
        type: FETCH_CHAT_THREADS_BY_CHAT_TOPIC_ID_SUCCESS,
        chatTopicId,
        chatThreads,
    }
}

const fetchError = () => {
    return {
        type: FETCH_CHAT_TOPICS_ERROR,
    }
}

/**
 * GET /chat-topics
 *
 * @param resetData {boolean}
 *     Should response data replace whole collection (i.e. skip pagination)?
 */
export const fetchChatTopics =
    (resetData: boolean = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(fetchChatTopicsPending())

        return fetch(`${getApiUrl()}/chat-topics`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchChatTopicsSuccess(resetData, json))
                return json
            })
            .catch(() => {
                dispatch(fetchError())
                return Promise.reject()
            })
    }

/**
 * GET /chat-topics/:id
 */
export const fetchChatTopic =
    (id: number, fetchWithThreads = false): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchChatTopicsPending())

        return fetch(`${getApiUrl()}/chat-topics/${id}`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchChatTopicSuccess(json, getState()))
                if (fetchWithThreads) {
                    dispatch(fetchChatThreadsByChatTopicId(id))
                }
                return json
            })
            .catch(() => {
                dispatch(fetchError())
                return Promise.reject()
            })
    }
