import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Picture from '../../picture'
import { BlogArticleListItemProps } from './blog-article-list-item.props'

const BlogArticleListItem: FC<BlogArticleListItemProps> = ({ article }) => {
    const createdAt = useMemo(() => moment(article.createdAt).format('DD.MM.YYYY'), [article])

    return (
        <Link
            className="component--blog-article-list-item blog-card"
            to={article.seo && article.seo.slug ? article.seo.slug : `/aktuelles/${article.id}`}
        >
            {article?.image && article.image.url && (
                <Picture
                    image={article.image}
                    className="blog-card-img"
                    alt={`Artikelbild ${article.title}`}
                />
            )}
            <div className="blog-card-content">
                <h2 className="h1">{article.title}</h2>
                <p>{article.descriptionShort}</p>
            </div>
            <div className="blog-card-footer">
                <p className="meta-text">{createdAt}</p>
                <p className="link-icon-blue">→</p>
            </div>
        </Link>
    )
}

export default BlogArticleListItem
