import React, { FC } from 'react'

import LockIcon from '../../../images/lock_icon_black.svg'
import {
    getGroupMembershipRole,
    getReadableOwnGroupMembershipRole,
} from '../../../utils/group-memberships'
import { ChatGroupIndicatorProps } from './chat-group-indicator.props'

const ChatGroupIndicator: FC<ChatGroupIndicatorProps> = ({ chatThread, sessionUsername }) => (
    <div className="chat-card-group-indicator">
        <div>
            <img src={LockIcon} alt="Schloss Symbol" width="13" height="17" />
            <span className="meta-text">Gruppenchat</span>
        </div>
        <span className="meta-text">
            {getReadableOwnGroupMembershipRole(
                getGroupMembershipRole(chatThread.groupMembers, sessionUsername)
            )}
        </span>
    </div>
)

export default ChatGroupIndicator
