import { AnyAction } from 'redux'

import { ADD_MENU, ADD_PAGE } from './menu-pages.actions'
import { MenuPageState } from './menu-pages.props'

const initialState: MenuPageState = {
    menues: [],
    pages: [],
    isPending: false,
}

const MenuPagesReducer = (
    state: MenuPageState = {
        ...initialState,
        isPending: true, // Required for initial async loading of custom pages
    },
    action: AnyAction
): MenuPageState => {
    switch (action.type) {
        case ADD_MENU:
            return {
                ...state,
                menues: [...state.menues, action.menu],
                isPending: false,
            }
        case ADD_PAGE:
            // @todo EDIT_PAGE action
            return {
                ...state,
                pages: [...state.pages, action.page],
                isPending: false,
            }
    }

    return state
}

export default MenuPagesReducer
