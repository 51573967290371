import React, { FC } from 'react'

import Chatbot from '../../components/chatbot/chatbot-container'
import { ChatbotContextEnum } from '../../components/chatbot/chatbot.props'
import useSeo from '../../hooks/use-seo'

const Authentication: FC = () => {
    // Update canonical link
    useSeo({
        title: 'Login und Registrierung',
        metaTags: [],
        slug: '/auth',
    })

    return (
        <div className="page page--profile page--auth">
            <div className="page--home__header page-header">
                <Chatbot context={ChatbotContextEnum.AUTHENTICATION} />
            </div>
        </div>
    )
}

export default Authentication
