import { applyMiddleware, combineReducers, createStore } from 'redux'
import camelMiddleware from 'redux-camel'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { Environment, getEnvironment } from '../utils/configs'
import blogArticles from './blog-articles/blog-articles.reducer'
import chatPosts from './chat-posts/chat-posts.reducer'
import chatThreads from './chat-threads/chat-threads.reducer'
import chatTopics from './chat-topics/chat-topics.reducer'
import chatbot from './chatbot/chatbot.reducer'
import emergencyKit from './emergency-kit/emergency-kit.reducer'
import faqTopQuestions from './faq-top-questions/faq-top-questions.reducer'
import faqTopics from './faq-topics/faq-topics.reducer'
import layout from './layout/layout.reducer'
import menuPages from './menu-pages/menu-pages.reducer'
import textBlocks from './text-blocks/text-blocks.reducer'
import userSession from './user/user.reducer'

const reducers = combineReducers({
    layout,
    chatTopics,
    chatThreads,
    chatPosts,
    faqTopics,
    faqTopQuestions,
    userSession,
    blogArticles,
    emergencyKit,
    chatbot,
    menuPages,
    textBlocks,
})

const middleware = [thunk, camelMiddleware()]

export const store = () =>
    getEnvironment() === Environment.Production
        ? createStore(reducers, undefined, applyMiddleware(...middleware))
        : createStore(reducers, undefined, composeWithDevTools(applyMiddleware(...middleware)))

export default store
