import { AnyAction } from 'redux'

import { ADD_FAQ_TOPICS, FETCH_FAQ_TOPICS_PENDING, RESET_FAQ_TOPICS } from './faq-topics.actions'
import { FaqTopicsState } from './faq-topics.props'

const initialState: FaqTopicsState = {
    data: [],
    isPending: false,
}

const FaqTopicsReducer = (
    state: FaqTopicsState = initialState,
    action: AnyAction
): FaqTopicsState => {
    switch (action.type) {
        case FETCH_FAQ_TOPICS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case ADD_FAQ_TOPICS:
            return {
                ...state,
                isPending: false,
                data: [...state.data, ...action.faqTopics.data],
            }
        case RESET_FAQ_TOPICS:
            return {
                isPending: false,
                data: action.faqTopics.data,
            }
    }

    return state
}

export default FaqTopicsReducer
