import { setNextItemActive } from '../../../store/chatbot/chatbot.actions'
import { ChatbotDialogueItem } from '../../../store/chatbot/chatbot.props'
import { ChatbotDialogueItemActionType as ActionType, ChatbotContextEnum } from '.././chatbot.props'

export const ChatbotSelfTestDialogues: ChatbotDialogueItem[] = [
    {
        id: -4,
        type: 'left',
        active: true,
        transitionTime: 0,
        content: `Selbsttest`,
    },
    {
        id: -3,
        type: 'left',
        active: true,
        transitionTime: 0.1,
        content: `Habe ich eine Depression? Mit diesem Selbsttest kannst Du schauen, ob Du vielleicht an einer Depression erkrankt bist. Der Test ersetzt aber keinen Besuch in einer Arztpraxis oder in einer psychotherapeutischen Praxis.`,
    },
    {
        id: -2,
        type: 'left',
        active: true,
        transitionTime: 0.5,
        content: `Der Test ist anonym und vertraulich.`,
    },
    {
        id: -1,
        type: 'left',
        active: true,
        transitionTime: 0.9,
        content: `Wenn Du bereit bist, prüfe bitte, ob bei Dir die folgenden Aussagen für die vergangenen zwei Wochen zutreffen.`,
    },
    {
        id: 0,
        type: 'right',
        actionType: ActionType.CLICK,
        active: true,
        content: `Los geht's!`,
        transitionTime: 1.3,
        action: ({ dispatch }) => {
            dispatch(setNextItemActive(ChatbotContextEnum.SELF_TEST, 0))
        },
    },
    {
        id: 1,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content: 'Ich war nur selten oder nie gut gelaunt?',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 2,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content:
            'Ich hatte weniger oder gar kein Interesse oder Freude an den Dingen, die mir sonst Spaß gemacht haben?',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 3,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content: 'Ich fühlte mich meist schwunglos und müde oder war innerlich unruhig?',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 4,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content:
            'Im Vergleich mit anderen fühlte ich mich unbedeutend oder dachte, andere mögen mich nicht.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 5,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content: '\tIch konnte mich nur schwer konzentrieren und/oder Entscheidungen treffen.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 6,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content:
            ' Ich hatte Schuldgefühle, wenn etwas schlecht lief und/oder hatte das Gefühl, alles falsch zu machen.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 7,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content: 'Ich mache mir Sorgen über die Zukunft und bin manchmal hoffnungslos.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 8,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content:
            'Ich hatte Schwierigkeiten beim Einschlafen, Durchschlafen und/oder wachte zu früh auf.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 9,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content: 'Ich hatte deutlich weniger/ mehr Appetit, als ich es sonst von mir kenne.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    {
        id: 10,
        type: 'left',
        actionType: ActionType.ANSWER_SELECT,
        active: false,
        content: 'Ich war verzweifelt und dachte daran, mir etwas anzutun.',
        answerOptions: [
            {
                value: '1',
                label: 'Ja',
            },
            {
                value: '0',
                label: 'Nein',
            },
        ],
    },
    // -- Only displayed programmatically: --
    {
        id: 11,
        type: 'left',
        active: false,
        evaluationResults: [
            'Deine Angaben weisen nicht auf das Vorliegen einer depressiven Erkrankung hin. Falls Du Dich dennoch ' +
                'unwohl und depressiv fühlst, suche bitte sicherheitshalber trotzdem Ärzt:innen auf und ' +
                'sprich über Deine Beschwerden.',
            'Du leidest möglicherweise unter einer leichten depressiven Erkrankung. Bitte sprich mit Ärzt:innen ' +
                '(z.B. Hausärzt:innen, Fachärzt:innen für Kinder- und Jugendpsychiatrie, Fachärzt:innen für Psychiatrie) ' +
                'oder direkt mit einem Kinder- und Jugendlichenpsychotherapeut:innen über Deine Beschwerden. ' +
                'Bitte teile Ärzt:innen neben Deinen körperlichen auch möglicherweise vorhandene psychische ' +
                'Beschwerden mit.',
            'Deine Angaben deuten auf das Vorliegen einer depressiven Erkrankung hin. Wir möchten Dich bitten, Dich ' +
                'möglichst bald an Ärzt:innen (z.B. Hausärzt:innen, Fachärzt:innen für Kinder- und Jugendpsychiatrie, ' +
                'Fachärzt:innen für Psychiatrie) oder Kinder- und Jugendlichenpsychotherapeut:innen zu wenden ' +
                'und das weitere Vorgehen zu besprechen. Durch Psychotherapie und/oder Medikamente kann eine Depression in ' +
                'der Regel gut behandelt werden.',
            'Bitte suche umgehend Ärzt:innen (z.B. Hausärzt:innen, Fachärzt:innen für Kinder- und ' +
                'Jugendpsychiatrie, Fachärzt:innen für Psychiatrie) auf. Dein Ergebnis lässt vermuten, dass Du unter einer ' +
                'schweren depressiven Erkankung leidest. Falls Du diesen Test für eine:n Familienangehörige:n oder Freund:in ' +
                'gemacht hast, solltest du ihn oder sie unbedingt zu einem Ärzt:innenbesuch motivieren.',
            '\n\nDu hast außerdem angegeben, dass Du in den vergangenen zwei Wochen daran gedacht hast, Dir etwas anzutun. Bitte wende Dich an Hausärzt:innen oder eine:n der folgenden Ärzt:innen: Kinder- und Jugendpsychiater:innen oder Fachärzt:innen für Psychiatrie, Psychosomatische Medizin oder Nervenärzt:innen.' +
                '\n\nNehmen die Gedanken zu, suche Dir umgehend professionelle Hilfe!!!' +
                '\nWende Dich außerhalb der Sprechzeiten immer an die nächste psychiatrische Klinik oder Notärzt:innen unter der Telefonnummer 112.' +
                '\n\nDu kannst Dich auch anonym und kostenlos an die Nummer gegen Kummer/ Kinder- und Jugendtelefon unter 0800 - 1110333 (Montag bis Samstag von 14 bis 20 Uhr) oder rund um die Uhr an die Telefonseelsorge unter 0800 - 1110111 oder 0800 222 0 222 wenden.',
        ],
        content: '',
    },
]
