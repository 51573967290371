import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { FaqTopicListItemProps } from './faq-topic-list-item.props'

const FaqTopicListItem: FC<FaqTopicListItemProps> = ({ topic }) => {
    return (
        <Link
            className="component--faq-topic-list-item faq-card-topic"
            to={topic.seo && topic.seo.slug ? topic.seo.slug : `/antworten/thema/${topic.id}`}
        >
            <p className="h1">{topic.title}</p>
            <div className="faq-card-wrapper">
                <p className="meta-text text-blue-mid">
                    {topic.faqQuestions.length} beantwortete Fragen
                </p>
                <p className="link-icon-blue">→</p>
            </div>
        </Link>
    )
}

export default FaqTopicListItem
