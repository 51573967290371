import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import EmergencyKitEditContainer from '../../components/emergency-kit/emergency-kit-edit'
import EmergencyKitList from '../../components/emergency-kit/emergency-kit-list'
import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSetParentLink from '../../hooks/use-set-parent-link'
import {
    fetchEmergencyKitItems,
    patchEmergencyKitItems,
    unsetEmergencyKitItemsUploadedFlag,
} from '../../store/emergency-kit/emergency-kit.actions'
import {
    getEmergencyKitIsPending,
    getEmergencyKitItems,
} from '../../store/emergency-kit/emergency-kit.selectors'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../utils/configs'

// @todo if emergency items.length = 0 (i.e. user has no items yet), edit mode = true
const EmergencyKit: FC = () => {
    useSetParentLink('/profil')
    const dispatch = useFideoDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_EMERGENCY_KIT)
    )
    const isPending = useSelector((state: GlobalState) => getEmergencyKitIsPending(state))
    const emergencyKitItems = useSelector((state: GlobalState) => getEmergencyKitItems(state))
    const [isEditMode, setIsEditMode] = useState(location.pathname.indexOf('bearbeiten') !== -1)

    useEffect(() => {
        if (emergencyKitItems.length === 0) {
            dispatch(fetchEmergencyKitItems())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const emitSetIsEditMode = (newVal: boolean) => {
        if (!newVal) {
            // Reset items after they were falsely edited
            dispatch(fetchEmergencyKitItems())
        }
        setIsEditMode(newVal)
    }

    const endEditMode = () => {
        dispatch(unsetEmergencyKitItemsUploadedFlag())
        setIsEditMode(false)
        navigate(`/notfallkoffer`)
    }

    const patchItems = (ev: MouseEvent, takeSortFromItems: number[] | undefined = undefined) => {
        let patchItems = { items: emergencyKitItems.map((item) => item.id) }

        if (takeSortFromItems) {
            patchItems.items = takeSortFromItems.filter((item) => patchItems.items.includes(item))
        }

        dispatch(patchEmergencyKitItems(patchItems)).then(endEditMode)

        ev.preventDefault()
    }

    return (
        <div className="page page--emergency-kit">
            <div className="page-header">
                <h1 className="title-black">Notfallkoffer</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>
            {isPending && <Loading />}
            {!isPending && (
                <>
                    <EmergencyKitList
                        isEditMode={isEditMode}
                        emergencyKitItems={emergencyKitItems}
                    />
                    <EmergencyKitEditContainer
                        isEditMode={isEditMode}
                        setIsEditMode={emitSetIsEditMode}
                        initialEmergencyKitItems={emergencyKitItems}
                        onSubmit={patchItems}
                    />
                </>
            )}
        </div>
    )
}

export default EmergencyKit
