import cn from 'classnames'
import React, { FC } from 'react'

import { ChatbotDialogueListAnswerOptionProps } from './chatbot-dialogue-list-answer-option.props'

// answerOption.id was removed from display as requested in #2014
const ChatbotDialogueListAnswerOption: FC<ChatbotDialogueListAnswerOptionProps> = ({
    item,
    answerOption,
    dispatchChatbotAction,
}) => {
    let hasAnswer = false

    if (item.answer && item.answer.label) {
        hasAnswer = true

        if (item.answer.value !== answerOption.value) {
            return null
        }
    }

    return (
        <div
            className={cn(
                'component--chatbot-dialogue-list-answer-option',
                hasAnswer ? 'has-answer' : null
            )}
            onClick={() => {
                dispatchChatbotAction(item, answerOption)
            }}
        >
            {/*answerOption.value*/} {answerOption.label}
        </div>
    )
}

export default ChatbotDialogueListAnswerOption
