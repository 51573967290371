import { ChatbotDialogueItem } from '../../../store/chatbot/chatbot.props'
import { logoutUser } from '../../../store/user/user.actions'
import { ChatbotDialogueItemActionType } from '.././chatbot.props'

// Eventually, apply changes to tags also to ./chatbot-home-object.ts
export const ChatbotWidgetDialogues: ChatbotDialogueItem[] = [
    {
        id: 0,
        type: 'left',
        active: true,
        content: 'Was möchtest Du als nächstes tun?',
        transitionTime: 0,
    },
    {
        id: 1,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.ROUTE,
        active: true,
        content: 'Chat',
        transitionTime: 0,
        navigationTarget: '/chat',
        tags: ['chat', 'reden', 'dialog', 'beitrag', 'beiträge', 'reaktion', 'forum', 'kommuni'],
    },
    {
        id: 2,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.ROUTE,
        active: true,
        content: 'Antworten finden',
        transitionTime: 0,
        navigationTarget: '/antworten',
        tags: ['allgemein', 'fragen', 'faq', 'erklärung'],
    },
    {
        id: 3,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.ROUTE,
        active: true,
        content: 'Aktuelles',
        transitionTime: 0,
        navigationTarget: '/aktuelles',
        tags: ['blog', 'neuigkeiten', 'artikel'],
    },
    {
        id: 4,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.ROUTE,
        active: true,
        content: 'Persönlicher Bereich',
        transitionTime: 0,
        navigationTarget: '/profil',
        tags: ['dashboard', 'profil', 'konto', 'einstellungen'],
    },
    {
        id: 5,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.ROUTE,
        active: true,
        content: 'Selbsttest',
        transitionTime: 0,
        navigationTarget: '/selbsttest',
        tags: ['check', 'analys', 'auswert', 'probe', 'zweifel'],
    },
    {
        id: 6,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.ROUTE,
        active: true, // Visibility becomes finally set in chatbot.view
        content: 'Login / Registrierung',
        transitionTime: 0,
        navigationTarget: '/auth',
        tags: ['auth', 'anmeld', 'einloggen'],
    },
    {
        id: 7,
        type: 'right',
        actionType: ChatbotDialogueItemActionType.CLICK,
        active: true,
        content: 'Ausloggen',
        transitionTime: 0,
        action: ({ closeWidget, dispatch, navigate }) => {
            if (!window.confirm(`Möchtest Du Dich wirklich ausloggen?`)) {
                return
            }

            dispatch(logoutUser())
                .then(() => {
                    /**
                     * Great would be here a logout-success-message followed by a short delay prior to redirecting.
                     * This, however, failed due to side effects brought in by a changed user state triggering re-renderings
                     * of surrounding views, e.g. chatbot itself and profiles-page (-> "You have to login first").
                     */
                    closeWidget()
                    navigate('/')
                })
                .catch(() => {
                    alert(
                        `Logout nicht möglich. Stelle sicher, dass Du mit dem Internet verbunden bist und probiere es erneut.`
                    )
                })
        },
        tags: ['logout'],
    },
]
