import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Adds SPA support for matomo tracking
 * @see https://developer.matomo.org/guides/spa-tracking
 */
const RouterObserver: FC = () => {
    const location = useLocation()

    useEffect(() => {
        const { pathname } = location
        const titlebar = document.querySelector('.titlebar__title') as HTMLElement
        const titlebarTitle = titlebar?.innerText

        var _paq = (window as any)._paq || []

        if (titlebarTitle && titlebarTitle !== '') {
            _paq.push(['setDocumentTitle', titlebarTitle])
        }
        _paq.push(['setCustomUrl', pathname])
        _paq.push(['trackPageView'])
    }, [location])

    return null
}

export default RouterObserver
