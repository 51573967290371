import { Captcha } from '../../hooks/use-captcha'
import { getApiUrl, handleFetchErrorAsObject } from '../../utils/api'

export interface SchoolboxFormRequest {
    firstName: string
    lastName: string
    email: string
    notes: string
    institution: string
    streetAndNumber: string
    zip: string
    city: string
    captcha: Captcha
}

export const postSchoolboxForm = (body: SchoolboxFormRequest): Promise<any> => {
    return fetch(`${getApiUrl()}/schoolbox-orders`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleFetchErrorAsObject)
        .then((json) => json)
        .catch((error) => {
            return Promise.reject(error)
        })
}
