import cn from 'classnames'
import moment from 'moment'
import React, { FC, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import {
    deleteChatNews,
    fetchChatThread,
    unmarkGroupMember,
} from '../../../store/chat-threads/chat-threads.actions'
import {
    ChatThread,
    GroupChatNewsType,
    GroupMembershipRole,
} from '../../../store/chat-threads/chat-threads.props'
import {
    getChatNewsById,
    getMarkedChatNewsTypes,
} from '../../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../../store/state.props'
import { calendarFormat } from '../../../utils/format'
import { getGroupMembership } from '../../../utils/group-memberships'
import ChatGroupIndicator from '../chat-group-indicator'
import ChatGroupMembershipSubmitter from '../chat-group-membership-submitter'
import { ReportChatThread } from './chat-thread-item.actions'

const ChatThreadItem: FC<ChatThread> = (chatThread) => {
    const dispatch = useFideoDispatch()
    const sessionUser = useSessionUser()
    const isGroupChat = chatThread.type === 'group'
    const selfGroupMembership =
        isGroupChat && sessionUser
            ? getGroupMembership(chatThread.groupMembers, sessionUser.username)
            : null
    const selfGroupMembershipRole = selfGroupMembership ? selfGroupMembership.state : null

    const isOwnThread = chatThread.creatorName === sessionUser?.username
    const isEditedByAdmin = chatThread.isEdited && chatThread.editedAt && chatThread.isEditedByAdmin
    const isEditedByUser = chatThread.isEdited && chatThread.editedAt && !chatThread.isEditedByAdmin
    const isUnread = useSelector(
        (state: GlobalState) => getChatNewsById(state, chatThread.id),
        shallowEqual
    )
    const threadEditLink = `/chat/${isGroupChat ? 'gruppe' : 'beitrag'}/${chatThread.id}/bearbeiten`
    // Only for group chats:
    const markedChatNews =
        isUnread && chatThread.type === 'group' && sessionUser
            ? getMarkedChatNewsTypes(isUnread, sessionUser?.username)
            : []

    useEffect(() => {
        if (isUnread) {
            if (isUnread.newPostsNum >= 1) {
                // Mark thread as read
                dispatch(deleteChatNews(chatThread.id))
            }
        }

        if (markedChatNews) {
            markedChatNews.forEach((newsType) => {
                if (newsType.type === GroupChatNewsType.MEMBER_JOINED) {
                    // Unmark news for this membership
                    dispatch(unmarkGroupMember(chatThread.id, newsType.membershipId)).then(() => {
                        // Get chat thread with updated membership
                        dispatch(fetchChatThread(chatThread.id))
                    })
                }
            })
        }
    }, [isUnread])

    const createdAt = useMemo(
        () =>
            chatThread.createdAt
                ? moment(chatThread.createdAt).calendar(undefined, calendarFormat)
                : '',
        [chatThread.createdAt]
    )

    return (
        <>
            <div
                className={cn(
                    'component--chat-article-item',
                    isOwnThread ? 'chat-article-user' : 'chat-card',
                    chatThread.creatorIsMod ? 'is-mod' : '',
                    isUnread && isUnread.newPostsNum >= 1 ? 'chat-card--unread' : '',
                    isGroupChat ? 'chat-card--group' : ''
                )}
            >
                <div className="chat-card-wrapper">
                    {isOwnThread ? (
                        <div className="user"></div>
                    ) : (
                        <p className="label-nickname">
                            {chatThread.creatorName}
                            {chatThread.creatorIsMod && (
                                <p className="meta-text label-mod">Fideo-Moderation</p>
                            )}
                        </p>
                    )}
                    <p className="meta-text">
                        {createdAt}
                        {isOwnThread || (isGroupChat && selfGroupMembershipRole === 'member') ? (
                            <Link
                                to={threadEditLink}
                                title={`${isGroupChat ? 'Gruppenchat' : 'Beitrag'} bearbeiten`}
                            />
                        ) : (
                            ''
                        )}
                    </p>
                </div>
                <h1 className="h2">{chatThread.title}</h1>
                <p>{chatThread.text}</p>
                {isEditedByAdmin && (
                    <p className="meta-text meta-edit-text">
                        Dieser Beitrag wurde am{' '}
                        {moment(chatThread.editedAt).format('DD.MM.YYYY, HH:mm')} redaktionell
                        bearbeitet.
                    </p>
                )}
                {isEditedByUser && (
                    <p className="meta-text meta-edit-text">
                        Dieser Beitrag wurde am{' '}
                        {moment(chatThread.editedAt).format('DD.MM.YYYY, HH:mm')} bearbeitet.
                    </p>
                )}
                {markedChatNews.length > 0 ? (
                    <>
                        <div className="border-bottom"></div>
                        <p
                            className="meta-text text-blue-mid"
                            dangerouslySetInnerHTML={{ __html: markedChatNews[0].type }}
                        ></p>
                    </>
                ) : sessionUser && !isOwnThread ? (
                    <>
                        <div className="border-bottom"></div>
                        <a
                            href="#"
                            className="meta-text text-blue-mid"
                            title="Beitrag dem Fideo-Team melden"
                            onClick={() => ReportChatThread(chatThread.id)}
                        >
                            Beitrag melden
                        </a>
                    </>
                ) : null}
                {isGroupChat && (
                    <div className="chat-card--group__footer">
                        {isOwnThread || (isGroupChat && selfGroupMembershipRole === 'member') ? (
                            <Link
                                to={threadEditLink}
                                title={`${isGroupChat ? 'Gruppenchat' : 'Beitrag'} bearbeiten`}
                            >
                                <ChatGroupIndicator
                                    chatThread={chatThread}
                                    sessionUsername={sessionUser?.username}
                                />
                            </Link>
                        ) : (
                            <ChatGroupIndicator
                                chatThread={chatThread}
                                sessionUsername={sessionUser?.username}
                            />
                        )}
                        <p className="group-info">
                            {selfGroupMembershipRole === 'member'
                                ? `Alle Beiträge in diesem Chat sind nur für Gruppenmitglieder einsehbar.`
                                : selfGroupMembershipRole === 'admin'
                                ? `Alle Beiträge in diesem Chat sind nur für Gruppenmitglieder einsehbar. Neue Mitglieder fügst Du über den blauen Stift hinzu.`
                                : `Um Beiträge in diesem Chat zu lesen oder zu schreiben, musst Du Mitglied in der Gruppe sein.`}
                        </p>
                    </div>
                )}
            </div>
            {isGroupChat &&
                selfGroupMembershipRole !== 'admin' &&
                selfGroupMembershipRole !== 'member' && (
                    <ChatGroupMembershipSubmitter
                        selfMembershipId={selfGroupMembership?.id}
                        selfMembershipRole={selfGroupMembershipRole as GroupMembershipRole}
                        chatThreadId={chatThread.id}
                    />
                )}
        </>
    )
}

export default ChatThreadItem
