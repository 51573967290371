import cn from 'classnames'
import React, { FC, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ButtonLoadMore from '../../components/button-load-more'
import ChatThreadListItem from '../../components/chat/chat-thread-list-item'
import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSessionUser from '../../hooks/use-session-user'
import {
    fetchGroupChatsWhereIAmMember,
    resetChatThreads,
} from '../../store/chat-threads/chat-threads.actions'
import {
    getChatNews,
    getChatThreadsByMemberUsername,
    getChatThreadsPagination,
    getMarkedChatNewsTypesFromChatThread,
} from '../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../utils/configs'

let fetchingInProgress = false

const UserChatGroups: FC = () => {
    const navigate = useNavigate()
    const dispatch = useFideoDispatch()
    const sessionUser = useSessionUser()

    const textBlock = useSelector(
        (state: GlobalState) => getTextBlockByPageCode(state, TextBlockCode.INTRO_USER_CHAT_GROUPS),
        shallowEqual
    )
    const threads = useSelector(
        (state: GlobalState) => getChatThreadsByMemberUsername(state, sessionUser?.username),
        shallowEqual
    )
    const threadsPagination = useSelector(
        (state: GlobalState) => getChatThreadsPagination(state),
        shallowEqual
    )

    const sortedThreads = useMemo(() => {
        if (threads && threads.length >= 1) {
            return threads.sort((a, b) => {
                const aIsMarked = getMarkedChatNewsTypesFromChatThread(a, sessionUser?.username)
                const bIsMarked = getMarkedChatNewsTypesFromChatThread(b, sessionUser?.username)

                return aIsMarked.length > bIsMarked.length ? -1 : 1
            })
        }

        return []
    }, [threads])

    const chatNews = useSelector((state: GlobalState) => getChatNews(state), shallowEqual)

    const loadMoreAction = () => () => {
        // Dispatch any, or all, of the fetch-next-page functions
        if (typeof threadsPagination.nextPage !== 'undefined' && sessionUser) {
            dispatch(fetchGroupChatsWhereIAmMember())
        }
    }

    useEffect(
        () => {
            // Once chatNews updates, refetch local posts to show accurate amount of numPosts
            // @todo Double-check if shallowEqual functions correctly here
            if (sessionUser && sessionUser.username && !fetchingInProgress) {
                fetchingInProgress = true
                Promise.all([
                    dispatch(resetChatThreads()),
                    dispatch(fetchGroupChatsWhereIAmMember(true)),
                ]).then(() => {
                    fetchingInProgress = false
                })
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [
            /*chatNews*/
        ]
    )

    if (!sessionUser) {
        navigate('/auth')
        return null
    }

    return (
        <div className="page page--user-chat-threads">
            <div className="page-header">
                <h1 className="title-black">Deine Gruppenchats</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <div className="search-result-list">
                {sortedThreads.map((thread) => (
                    <div
                        key={thread.id}
                        className={cn(
                            'search-result-list-item',
                            'full-width',
                            'search-result-list-item--thread'
                        )}
                    >
                        <ChatThreadListItem
                            chatThread={thread}
                            sessionUserName={sessionUser?.username}
                            showChatNumsForType="group"
                        />
                    </div>
                ))}

                {fetchingInProgress && !threads && <Loading />}
                {threads && threads.length === 0 && (
                    <p className="text-center" style={{ fontWeight: 'bold' }}>
                        Du bist noch kein Mitglied in einem Gruppenchat.
                    </p>
                )}

                {typeof threadsPagination.nextPage !== 'undefined' && (
                    <ButtonLoadMore dispatchAction={() => loadMoreAction()} />
                )}
            </div>
        </div>
    )
}

export default UserChatGroups
