import { ChatPost } from '../chat-posts/chat-posts.props'
import { Pagination, Payload } from '../state.props'

export type ChatThreadType = 'public' | 'private' | 'group'

export interface ChatThread {
    id: number
    title: string
    text: string
    chatTopicId: number
    chatPosts: ChatPost[]
    chatPostsNum: number
    creatorName: string
    creatorIsMod: boolean
    createdAt: string
    groupMembers?: GroupMembership[]
    type: ChatThreadType
    isLocked: boolean
    isEdited: boolean
    isEditedByAdmin: boolean
    editedAt: string
    latestUpdateAt: string
}

export interface ChatThreadRequest {
    title: string
    text: string
    type: ChatThreadType
    chatTopicId: number
}

export interface ChatThreadResponse {
    success: boolean
    data: ChatThread
}

export interface ChatNews {
    chatThreadId: number
    newPostsNum: number
    chatThread?: ChatThread
}

export interface ChatThreadsState {
    data: ChatThread[]
    chatNews: ChatNews[]
    privateChatThread: ChatThread | undefined
    isPending: boolean
    pagination: Pagination
}

export interface DeleteGroupMemberPayload extends Payload {
    threadId: number
    memberId: number
}

export interface PatchGroupMemberPayload extends Payload {
    threadId: number
    membership: GroupMembership
}

export type GroupMembershipRole = 'admin' | 'member' | 'requested' | 'invited'

interface GroupMembershipUser {
    username: string
    isMod?: boolean
}

export interface GroupMembership {
    id: number
    state: GroupMembershipRole
    member: GroupMembershipUser // || Partial<User>
    isMarked?: boolean
}

export enum GroupChatNewsType {
    MEMBER_REQUESTED = 'Bestätige eine Mitgliedschafts&shy;anfrage',
    MEMBER_INVITED = 'Bestätige Deine Einladung',
    MEMBER_JOINED = 'Du bist nun Mitglied',
}

export interface MarkedGroupChatNews {
    membershipId: number
    type: GroupChatNewsType
}
