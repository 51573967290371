import React, { FC, MouseEvent, useEffect } from 'react'

import Chatbot from '../chatbot-container'
import ChatbotVerticalDragArea from '../chatbot-vertical-drag-area'
import { ChatbotContextEnum } from '../chatbot.props'
import { ChatbotWidgetOverlayProps } from './chatbot-widget-overlay.props'

/**
 * ChatbotWidgetOverlay
 * Draggable container (y-axis) for Chatbot context="widget"
 */

const ChatbotWidgetOverlay: FC<ChatbotWidgetOverlayProps> = ({
    toggleChatbotWidgetIsVisible,
    showWebappInstallDialogue,
}) => {
    useEffect(() => {
        document.body.classList.add('chatbot-widget-active')

        return () => {
            document.body.classList.remove('chatbot-widget-active')
        }
    }, [])

    const onClickClose = (ev: MouseEvent) => {
        // @todo Smooth closeToBottom animation
        toggleChatbotWidgetIsVisible(false)
    }

    console.log('A', showWebappInstallDialogue)

    return (
        <div className="component--chatbot-widget-overlay modal-overlay">
            <ChatbotVerticalDragArea onClickClose={onClickClose} />
            <div className="component--chatbot-widget-overlay__inner">
                <button
                    className="button-close-blue"
                    title="Chatbot schliessen"
                    onClick={onClickClose}
                ></button>
                <Chatbot
                    context={
                        showWebappInstallDialogue
                            ? ChatbotContextEnum.WEBAPP_INSTALL
                            : ChatbotContextEnum.WIDGET
                    }
                    onClickClose={onClickClose}
                />
            </div>
        </div>
    )
}

export default ChatbotWidgetOverlay
