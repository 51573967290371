import { useLocation, useNavigate } from 'react-router-dom'

import {
    ChatbotDialogueItemActionType as ActionType,
    AddChatbotItems,
    ChatbotContextEnum,
    ChatbotSetInvalidAnswer,
    ChatbotSetUserAnswer,
    DispatchChatbotAction,
    UseChatbotDispatch,
} from '../components/chatbot/chatbot.props'
import { addItem, addItems, updateSelfTestAnswer } from '../store/chatbot/chatbot.actions'
import { ChatbotDialogueAnswer, ChatbotDialogueItem } from '../store/chatbot/chatbot.props'
import { noop } from '../utils/configs'
import { useFideoDispatch } from './use-fideo-dispatch'

export const defaultDelay: number = 700
let uniqueId: number = 111

/**
 * Collection of all chatbot-related building blocks.
 * Guarantees unified access to chatbot-store
 */
const useChatbotDispatch: UseChatbotDispatch = (context, onClickClose = noop) => {
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const addChatbotItems: AddChatbotItems = (items) => {
        dispatch(addItems(context, items))
    }

    const setUserAnswer: ChatbotSetUserAnswer = (content, type = 'center') => {
        dispatch(
            addItem(context, {
                id: uniqueId++,
                type,
                content: content,
                active: true,
            })
        )
    }

    const setInvalidAnswer: ChatbotSetInvalidAnswer = (
        itemIndex = null,
        content = `Das konnte ich leider nicht zuordnen...`
    ) => {
        dispatch(
            addItem(
                context,
                {
                    id: uniqueId++,
                    type: 'left',
                    content,
                    active: true,
                },
                itemIndex
            )
        )
    }

    const _onRouteItemClick = (item: ChatbotDialogueItem) => {
        setUserAnswer(item.content)

        window.setTimeout(() => {
            dispatch(
                addItem(context, {
                    id: uniqueId++,
                    type: 'left',
                    content: `Super, ich leite Dich direkt dorthin. Los geht's!`,
                    active: true,
                })
            )

            window.setTimeout(() => {
                onClickClose()
                navigate(item.navigationTarget ?? '/')
            }, 1800)
        }, defaultDelay)
    }

    const _onItemClick = (item: ChatbotDialogueItem, text: string | undefined = undefined) => {
        if (item.content !== '' && text === undefined) {
            setUserAnswer(item.content)
        }
        _dispatchChatbotActionWithFullArgs(item, text)
    }

    const _onAnswerClick = (item: ChatbotDialogueItem, answer: ChatbotDialogueAnswer) => {
        if (context === ChatbotContextEnum.SELF_TEST) {
            if (typeof item.answer === 'undefined') {
                dispatch(updateSelfTestAnswer(context, item.id, answer))
            }
        } else if (context === ChatbotContextEnum.AUTHENTICATION) {
            // "Do login, registration or password-reset?"
            // @todo To generalize, call via _dispatchChatbotActionWithFullArgs
            dispatch((answer as any).action(addItems))
        }
    }

    const _onTextInput = (item: ChatbotDialogueItem, answer: string) => {
        if (item.content.indexOf('asswor') === -1) {
            setUserAnswer(answer)
        } else {
            const maskedPassword = answer.split('').reduce((memo) => '*' + memo, '')
            setUserAnswer(maskedPassword)
        }

        _dispatchChatbotActionWithFullArgs(
            item,
            typeof answer === 'string' ? answer.trim() : answer
        )
    }

    const closeWidget = onClickClose

    const _dispatchChatbotActionWithFullArgs: DispatchChatbotAction = (item, args) => {
        item.action &&
            item.action(
                {
                    addChatbotItems,
                    closeWidget,
                    dispatch,
                    navigate,
                    location,
                    setInvalidAnswer,
                    setUserAnswer,
                },
                args
            )
    }

    /**
     * Ensures that an item- or item-answer-action/callback becomes dispatched
     * with all contextual required parameters.
     */
    const dispatchChatbotAction: DispatchChatbotAction = (item, args = null) => {
        if (item.actionType) {
            switch (item.actionType) {
                case ActionType.ROUTE:
                    _onRouteItemClick(item)
                    break
                case ActionType.ANSWER_SELECT:
                    _onAnswerClick(item, args as ChatbotDialogueAnswer)
                    break
                case ActionType.TEXT_INPUT:
                    _onTextInput(item, args as string)
                    break
                case ActionType.CLICK:
                    _onItemClick(item, args as string)
                    break
            }
        }
    }

    return {
        addChatbotItems,
        dispatchChatbotAction,
        setInvalidAnswer,
        setUserAnswer,
    }
}

export default useChatbotDispatch
