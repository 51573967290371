import React, { FC, useEffect, useState } from 'react'

import { chatbotFCSubscribers } from '../chatbot-dialogues/chatbot-authentication-dialogues'

/**
 * The communication between this FC and AuthDialogue
 * became more troublesome then expected (mainly due to
 * async processes). Time for a dedicated bridge!
 */
const ChatbotCounterItem: FC = () => {
    const [counter, setCounter] = useState<number>(59)

    const getCounter = () =>
        new Promise((resolve) => {
            setCounter((_c) => {
                resolve(_c)
                return _c
            })
        })

    useEffect(() => {
        const interval = window.setInterval(() => {
            setCounter((c) => --c)
        }, 1000)

        const clearInterval = () => {
            window.clearInterval(interval)
        }

        chatbotFCSubscribers.getCounter = () => getCounter()
        chatbotFCSubscribers.freezeCounter = () => {
            clearInterval()
            setCounter((_c) => 99)
        }

        return clearInterval
    }, [])

    return (
        <div className="chatbot-counter-item">
            Code erneut senden
            {counter > 0 && counter !== 99 && (
                <span
                    className="meta-text"
                    title="Nach Ablauf dieser Zeit kannst Du einen neuen SMS-Code anfordern..."
                >
                    0:{counter < 10 ? '0' : ''}
                    {counter}
                </span>
            )}
        </div>
    )
}

export default ChatbotCounterItem
