import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSeo from '../../../hooks/use-seo'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchFaqTopics } from '../../../store/faq-topics/faq-topics.actions'
import {
    getFaqQuestionById,
    getFaqQuestionBySlug,
    getFaqTopicById,
} from '../../../store/faq-topics/faq-topics.selectors'
import { GlobalState, ParamsSlug } from '../../../store/state.props'
import { getUnsanitizedPageText } from '../../../utils/configs'

const FaqQuestionDetail: FC = () => {
    const { setParentLink } = useSetParentLink('/antworten')
    const dispatch = useFideoDispatch()
    const params = useParams<ParamsSlug>()
    const param: any = params.slug
    const isSlug = isNaN(param)
    const faqQuestion = useSelector((state: GlobalState) =>
        isSlug
            ? getFaqQuestionBySlug(state, `/antworten/frage/${param}`)
            : getFaqQuestionById(state, Number(param))
    )
    const faqTopic = useSelector((state: GlobalState) =>
        faqQuestion && faqQuestion.faqTopicId
            ? getFaqTopicById(state, faqQuestion.faqTopicId)
            : null
    )

    useEffect(() => {
        if (!faqQuestion) {
            dispatch(fetchFaqTopics(true))
        } else {
            if (faqTopic && faqTopic.seo) {
                setParentLink(faqTopic.seo.slug)
            } else {
                setParentLink(`/antworten/thema/${faqQuestion.faqTopicId}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faqQuestion])

    useSeo(faqQuestion?.seo)

    return (
        <div className="page page--faq page--faq-question-detail bg-orange">
            <div className="faq-card-answer">
                <h1 className="h2">{faqQuestion?.question}</h1>
                <div className="border-bottom"></div>
                <div
                    className="inner-html"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(faqQuestion?.answer)}
                ></div>
            </div>
        </div>
    )
}

export default FaqQuestionDetail
