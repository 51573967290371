import React, { FC, MouseEvent, useEffect, useState } from 'react'

import useCaptcha from '../../../hooks/use-captcha'
import { chatbotFCSubscribers } from '../chatbot-dialogues/chatbot-authentication-dialogues'
import { ChatbotCaptchaItemProps } from './chatbot-captcha-item.props'

const ChatbotCaptchaItem: FC<ChatbotCaptchaItemProps> = ({ captcha }) => {
    const [active, setActive] = useState<boolean>(true)
    const [captchaToken, captchaImageUrl, fetchCaptcha] = useCaptcha()

    const reloadCaptcha = () => {
        if (!active) {
            // User has already passed captcha
            return
        }

        fetchCaptcha().then(() => {
            captcha.phrase = ''
        })
    }

    useEffect(() => {
        // Assign local functions global callbacks
        chatbotFCSubscribers.reloadCaptcha = reloadCaptcha
        chatbotFCSubscribers.freezeCaptcha = () => setActive(false)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        captcha.token = captchaToken || ''
        // eslint-disable-next-line
    }, [captchaToken])

    const onClickReloadCaptcha = (ev: MouseEvent) => {
        reloadCaptcha()
        ev.preventDefault()
    }

    const onLoadImage = () => {
        const chatbotContainer = document.querySelectorAll('.component--chatbot')
        chatbotContainer.forEach((container) => {
            // Container reached new height: Force scroll-to-bottom (fixes #3125)
            container.scrollTo(0, 9999)
        })
    }

    return (
        <div className="chatbot-captcha-item captcha-field">
            <img
                alt="Lade Captcha..."
                src={captchaImageUrl || ''}
                onLoad={onLoadImage}
                width="800"
                height="200"
            />
            <button
                title="Neues Captcha laden"
                onClick={onClickReloadCaptcha}
                type="button"
            ></button>
        </div>
    )
}

export default ChatbotCaptchaItem
