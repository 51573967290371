import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Save a components parent link temporarily
 * in location.state.parentLink
 */
const useSetParentLink = (parentLink: string) => {
    const location = useLocation()

    const setParentLink = (_parentLink: string) => {
        if (_parentLink) {
            if (!location.state) {
                location.state = {}
            }
            ;(location.state as any).parentLink = _parentLink
        }
    }

    useEffect(() => {
        setParentLink(parentLink)

        return () => {
            delete (location.state as any).parentLink
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        setParentLink,
    }
}

export default useSetParentLink
