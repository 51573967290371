import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'

import BlogArticleList from '../../../components/blog/blog-article-list'
import ButtonLoadMore from '../../../components/button-load-more'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSeo from '../../../hooks/use-seo'
import {
    fetchBlogArticles,
    flushBlogArticles,
} from '../../../store/blog-articles/blog-articles.actions'
import {
    getBlogArticles,
    getBlogPagination,
} from '../../../store/blog-articles/blog-articles.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../../utils/configs'

const BlogOverview: FC = () => {
    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_BLOG_OVERVIEW)
    )
    const blogArticles = useSelector((state: GlobalState) => getBlogArticles(state))
    const blogArticlesPagination = useSelector((state: GlobalState) => getBlogPagination(state))

    useEffect(() => {
        if (!blogArticles || blogArticles.length <= 1) {
            // blogArticles.length may be 1 when previous page was blog-detail.view
            dispatch(flushBlogArticles())
            dispatch(fetchBlogArticles())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Update canonical link
    useSeo({
        title: 'Neues von FIDEO',
        metaTags: [],
        slug: '/aktuelles',
    })

    return (
        <div className="page page--blog page--blog-overview bg-yellow fog-bg fog-bg--blog">
            <div className="page-header">
                <p className="title-white">Neuigkeiten und Wichtiges</p>
                <p
                    className="text-white"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            {(!blogArticles || blogArticles.length === 0) && <Loading />}
            <BlogArticleList articles={blogArticles} />

            {blogArticles && blogArticles.length >= 1 && blogArticlesPagination.nextPage && (
                <div>
                    <ButtonLoadMore dispatchAction={fetchBlogArticles} />
                </div>
            )}
        </div>
    )
}

export default BlogOverview
