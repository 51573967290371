import { GlobalState, Pagination } from '../state.props'
import { BlogArticle } from './blog-articles.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */
export const getBlogArticles = (state: GlobalState): BlogArticle[] | undefined =>
    state.blogArticles.data

export const getBlogArticleById = (state: GlobalState, id: number): BlogArticle | undefined =>
    state.blogArticles.data.find((blogArticle) => blogArticle.id === id)

export const getBlogArticleBySlug = (state: GlobalState, slug: string): BlogArticle | undefined =>
    state.blogArticles.data.find((blogArticle) => blogArticle.seo?.slug === slug)

export const getBlogPagination = (state: GlobalState): Pagination => state.blogArticles.pagination
