import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import FaqQuestionList from '../../../components/faq/faq-question-list'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSeo from '../../../hooks/use-seo'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchFaqTopics } from '../../../store/faq-topics/faq-topics.actions'
import {
    getFaqTopicById,
    getFaqTopicBySlug,
    getFaqTopicsIsPending,
} from '../../../store/faq-topics/faq-topics.selectors'
import { GlobalState, ParamsSlug } from '../../../store/state.props'
import { getUnsanitizedPageText } from '../../../utils/configs'

const FaqTopicDetail: FC = () => {
    useSetParentLink('/antworten')
    const dispatch = useFideoDispatch()
    const params = useParams<ParamsSlug>()
    const param: any = params.slug
    const isSlug = isNaN(param)
    const isPending = useSelector((state: GlobalState) => getFaqTopicsIsPending(state))
    const faqTopic = useSelector((state: GlobalState) =>
        isSlug
            ? getFaqTopicBySlug(state, `/antworten/thema/${param}`)
            : getFaqTopicById(state, Number(param))
    )

    useEffect(() => {
        if (!faqTopic) {
            dispatch(fetchFaqTopics(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useSeo(faqTopic?.seo)

    return (
        <div className="page page--faq page--chat-topic-detail bg-orange">
            <div className="page-header">
                <p className="title-white">{faqTopic?.title}</p>
                <p
                    className="text-white inner-html"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(faqTopic?.text)}
                ></p>
            </div>
            {isPending && <Loading />}
            <FaqQuestionList faqQuestions={faqTopic?.faqQuestions} includeJsonLd={true} />
        </div>
    )
}

export default FaqTopicDetail
