import React, { FC, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ButtonLoadMore from '../../../components/button-load-more'
import ChatThreadAddButton from '../../../components/chat/chat-thread-add-button'
import ChatThreadList from '../../../components/chat/chat-thread-list'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useNoIndex from '../../../hooks/use-no-index'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import {
    fetchChatThreadsByChatTopicId,
    resetChatThreads,
} from '../../../store/chat-threads/chat-threads.actions'
import {
    getChatThreadsByTopicId,
    getChatThreadsPagination,
} from '../../../store/chat-threads/chat-threads.selectors'
import { fetchChatTopic } from '../../../store/chat-topics/chat-topics.actions'
import { getChatTopicById } from '../../../store/chat-topics/chat-topics.selectors'
import { GlobalState } from '../../../store/state.props'
import { ChatTopicDetailParams } from './chat-topic-detail.props'

/**
 * Chat topic detail page
 * @route /chat/thema/:id
 */
const ChatTopicDetail: FC = () => {
    useNoIndex()
    useSetParentLink('/chat')
    const dispatch = useFideoDispatch()
    const { id } = useParams<ChatTopicDetailParams>()
    const navigate = useNavigate()
    const chatTopic = useSelector(
        (state: GlobalState) => getChatTopicById(state, Number(id)),
        shallowEqual
    )
    const chatThreads = useSelector(
        (state: GlobalState) => getChatThreadsByTopicId(state, Number(id)),
        shallowEqual
    )
    const pagination = useSelector(
        (state: GlobalState) => getChatThreadsPagination(state),
        shallowEqual
    )

    useEffect(() => {
        if (!chatTopic) {
            // Calls "fetchChatThreadsByChatTopicId" internally
            dispatch(fetchChatTopic(Number(id), true)).catch(() => {
                // 404 or blacklisted element
                navigate('/chat')
            })
        } else if (
            !chatThreads ||
            !chatThreads.length ||
            chatThreads.length < chatTopic.chatThreadsNum
        ) {
            // Only fetch sub threads
            dispatch(resetChatThreads())
            dispatch(fetchChatThreadsByChatTopicId(Number(id), true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!chatTopic) {
        return <Loading />
    }

    return (
        <div className="page page--chat page--chat-topic-detail bg-green">
            <div className="page-header">
                <h1 className="title-white">{chatTopic?.title}</h1>
                <p className="text-white">{chatTopic?.description}</p>
            </div>
            <div>
                {<ChatThreadList threads={chatThreads} />}

                {typeof pagination.nextPage !== 'undefined' && pagination.nextPage !== 0 && (
                    <div>
                        <ButtonLoadMore
                            dispatchAction={() => fetchChatThreadsByChatTopicId(Number(id))}
                        />
                    </div>
                )}

                <p className="text-white text-center">
                    Nichts für dich dabei?
                    <br />
                    Wir freuen uns auf deine Beteiligung!
                </p>

                <ChatThreadAddButton />
            </div>
        </div>
    )
}

export default ChatTopicDetail
