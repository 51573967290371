import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { IMenuPage, IMenuType } from '../../store/menu-pages/menu-pages.props'
import { getMenuByName } from '../../store/menu-pages/menu-pages.selectors'
import { GlobalState } from '../../store/state.props'
import { getMenuPageLink } from '../../utils/pages'

const Weiteres: FC = () => {
    const menuLinks = useSelector((state: GlobalState) => getMenuByName(state, IMenuType.FURTHER))

    return (
        <div className="page page--weiteres">
            <div className="flex-container-list">
                <div className="flex-item-list">
                    <Link to="/aktuelles">
                        <h2>Neues von FIDEO</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/flyer-bestellen">
                        <h2>Flyer bestellen</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/kontakt">
                        <h2>Kontakt</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                {menuLinks?.pages.map((page: IMenuPage, key: number) => (
                    <div className="flex-item-list" key={key}>
                        <Link
                            to={getMenuPageLink(page)}
                            title={`Zur Inhaltsseite "${page.linkTitle}"`}
                        >
                            <h2>{page.linkTitle}</h2>
                            <i className="flex-item-list--arrow"></i>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Weiteres
