import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import ChatThreadForm from '../../../components/chat/chat-thread-form'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchChatThread, patchChatThread } from '../../../store/chat-threads/chat-threads.actions'
import { ChatThreadRequest } from '../../../store/chat-threads/chat-threads.props'
import { getChatThreadById } from '../../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { ChatThreadEditFormParams } from './chat-thread-edit.props'

/**
 * @route /chat/beitrag/:id/bearbeiten
 */
const ChatThreadEditForm: FC = () => {
    const { setParentLink } = useSetParentLink('/chat')
    const sessionUser = useSessionUser()
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams<ChatThreadEditFormParams>()
    const chatThread = useSelector((state: GlobalState) => getChatThreadById(state, Number(id)))

    const linkTarget = location.pathname.replace('/bearbeiten', '')
    useEffect(() => {
        if (!chatThread) {
            dispatch(fetchChatThread(Number(id))).catch(() => {
                // Not found... or no connection...
                navigate('/chat')
            })
        } else {
            setParentLink('/chat/thema/' + chatThread.chatTopicId)
        }
    }, [chatThread])

    if (!chatThread || !sessionUser) {
        return <Loading />
    } else if (chatThread.creatorName !== sessionUser.username) {
        navigate('/chat')
        return null
    }

    return (
        <div className="page page--chat-thread-new">
            <ChatThreadForm
                threadTitle={chatThread.title}
                threadText={chatThread.text}
                threadChatTopicId={chatThread.chatTopicId}
                formTitle="Beitrag bearbeiten"
                textBlockKey={TextBlockCode.INTRO_CHAT_THREAD_EDIT}
                textBlockSuccessKey={TextBlockCode.INTRO_CHAT_THREAD_SUCCESS}
                submitAction={(body: ChatThreadRequest) =>
                    dispatch(patchChatThread(chatThread.id, body))
                }
                linkTarget={linkTarget}
                linkText={`Zum Beitrag`}
            />
        </div>
    )
}

export default ChatThreadEditForm
