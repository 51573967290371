import { Captcha } from '../../hooks/use-captcha'
import {
    APIError,
    getApiToken,
    getApiUrl,
    handleFetchError,
    handleFetchErrorAsObject,
} from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult } from '../state.props'
import {
    CheckUsernameExistsResponse,
    LoginStatus,
    SearchUsernameResponse,
    SearchUsernameResponseItem,
    User,
    VerificationTarget,
} from './user.props'

export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const RESET_SESSION = 'RESET_SESSION'
export const EDIT_USER = 'EDIT_USER'
export const SET_PHONE_NBR_PENDING = 'SET_PHONE_NBR_PENDING'
export const SET_SHOW_WEBAPP_INSTALL_DIALOGUE = 'SET_SHOW_WEBAPP_INSTALL_DIALOGUE'
export const HIDE_WEBAPP_INSTALL_DIALOGUE = 'HIDE_WEBAPP_INSTALL_DIALOGUE'

export const sessionStorage = window.localStorage

const loginUserPending = () => {
    return {
        type: LOGIN_USER_PENDING,
    }
}

const loginUserSuccess = (token: string, user: User) => {
    sessionStorage.setItem('token', token)

    return {
        type: LOGIN_USER_SUCCESS,
        token,
        user,
    }
}

const getUserProfileSuccess = (user: User) => {
    return {
        type: GET_USER_PROFILE_SUCCESS,
        user,
    }
}

const loginUserError = (loginStatus: LoginStatus) => {
    return {
        type: LOGIN_USER_ERROR,
        loginStatus,
    }
}

export const setShowWebappInstallDialogue = (show: boolean) => {
    return {
        type: SET_SHOW_WEBAPP_INSTALL_DIALOGUE,
        show,
    }
}

export const editUser = (user: User) => {
    return {
        type: EDIT_USER,
        user,
    }
}

export const resetSession = () => {
    sessionStorage.clear()

    return {
        type: RESET_SESSION,
    }
}

export const hideWebappInstallDialogue = () => {
    return {
        type: HIDE_WEBAPP_INSTALL_DIALOGUE,
    }
}

/**
 * POST /user/TYPE/verification
 *
 * This action does not mutate the store
 */
export const verifyUser =
    (target: VerificationTarget, username: string, token: string): FideoThunkResult<Promise<any>> =>
    () => {
        return fetch(`${getApiUrl()}/user/${target}/verification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                token,
            }),
        })
            .then(handleFetchError)
            .then((json) => json)
            .catch((error) => Promise.reject(error))
    }

/**
 * POST /user/phone-number
 */
export const editUserPhoneNumber =
    (phoneNumber: string, captcha: Captcha): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/user/phone-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify({
                phoneNumber,
                captcha: captcha,
            }),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => json)
            .catch((error: APIError) => Promise.reject(error))

/**
 * POST /user/profile
 */
export const editUserProfile =
    (email: string, residence: string): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        return fetch(`${getApiUrl()}/user/profile`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify({
                email,
                residence,
            }),
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(getUserProfileSuccess(json.data))
                return json
            })
            .catch((error) => Promise.reject(error))
    }

/**
 * POST /user/password
 *
 * This action does not mutate the store
 */
export const editUserPassword =
    (password: string, newPassword: string): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        return fetch(`${getApiUrl()}/user/password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getApiToken(),
            },
            body: JSON.stringify({
                password,
                newPassword,
                newPasswordConfirm: newPassword,
            }),
        })
            .then(handleFetchError)
            .then((json) => json)
            .catch((error) => Promise.reject(error))
    }

/**
 * POST /user/login
 */
export const loginUser =
    (username: string, password: string): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(loginUserPending())

        return fetch(`${getApiUrl()}/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
            }),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => json.data)
            .then((data) => {
                dispatch(loginUserSuccess(data.token, data.user))
                return data
            })
            .catch((error: APIError) => {
                const errorType =
                    error.error && error.error.code && error.error.code === 401
                        ? LoginStatus.CREDENTIALS_ERROR
                        : LoginStatus.NETWORK_ERROR

                dispatch(loginUserError(errorType))
                return Promise.reject(errorType)
            })
    }

/**
 * POST /user/register
 *
 * This action does not mutate the store
 */
export const registerUser =
    (
        username: string,
        password: string,
        phoneNumber: string,
        captcha: Captcha
    ): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
                passwordConfirm: password,
                phoneNumber,
                captcha,
            }),
        })
            .then(handleFetchErrorAsObject)
            .then((json) => json)
            .catch((error: APIError) => Promise.reject(error))

/**
 * GET /user/profile
 */
export const getUserProfile =
    (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/user/profile`, {
            headers: {
                'X-AUTH-TOKEN': getApiToken(),
            },
        })
            .then(handleFetchError)
            .then((json) => {
                dispatch(getUserProfileSuccess(json.data))
                return json
            })
            .catch((error) => Promise.reject(error))

/**
 * POST /user/logout
 *
 * Kill session: Send logout-request, reset store, flush localStorage
 */
export const logoutUser = (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) =>
    fetch(`${getApiUrl()}/user/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': getApiToken(),
        },
    })
        .then(handleFetchError)
        .then((json) => {
            dispatch(resetSession())
            return json
        })
        .catch((error) => Promise.reject(error))

/**
 * GET /infos/usernames/:name
 *
 * This action does not mutate the store
 */
export const checkUsernameForExistence =
    (name: string): FideoThunkResult<Promise<any>> =>
    () =>
        fetch(`${getApiUrl()}/infos/usernames/${name}`)
            .then(handleFetchError)
            .then((json: CheckUsernameExistsResponse) => json)
            .catch((error) => Promise.reject(error))

/**
 * GET /usernames?query=...
 *
 * This action does not mutate the store
 */
export const searchUsername = (searchTerm: string): Promise<SearchUsernameResponseItem[]> =>
    fetch(`${getApiUrl()}/usernames?query=${searchTerm}`, {
        headers: {
            'X-AUTH-TOKEN': getApiToken(),
        },
    })
        .then(handleFetchError)
        .then((data: SearchUsernameResponse) => data.data)
        .catch(() => {
            console.log('Error while fetching user suggestions')
            return []
        })

/**
 * GET /infos/phonenumbers/:number
 *
 * This action does not mutate the store
 */
export const checkPhonenumberForExistence =
    (phoneNbr: string): FideoThunkResult<Promise<any>> =>
    () =>
        fetch(`${getApiUrl()}/infos/phonenumbers/${phoneNbr}`)
            .then(handleFetchErrorAsObject)
            .then((json: CheckUsernameExistsResponse) => json)
            .catch((error) => Promise.reject(error))
