import React, { FC, useMemo, useState } from 'react'
import SmoothScroll from 'smooth-scroll'

import ChatPostAdd from '../../../components/chat/chat-post-add'
import ChatPostList from '../../../components/chat/chat-post-list'
import ChatThreadItem from '../../../components/chat/chat-thread-item'
import { isMobile } from '../../../utils/viewport'
import { ChatThreadDetailProps } from './chat-thread-detail.props'

const ChatThreadDetailView: FC<ChatThreadDetailProps> = ({
    chatThread,
    chatPosts = [],
    lastReactionSince,
    selfMembershipRole,
}) => {
    const isGroupChat = chatThread && chatThread.type === 'group'
    const [reactionsVisible, setReactionsVisible] = useState<boolean>(false)
    const [alreadyShownPosts] = useState(new Set())

    const filteredChatPosts = useMemo(() => {
        if (reactionsVisible) {
            return chatPosts
        }

        if (chatPosts.length <= 1) {
            setReactionsVisible(true)
            return chatPosts
        }

        // Show at least one item (initially: Last item in chatPosts). When a new one gets added at the bottom of list,
        // remember and show all previously shown items.
        return chatPosts
            .filter(
                (chatPost, index) =>
                    alreadyShownPosts.has(chatPost.id) || index === chatPosts.length - 1
            )
            .map((chatPost) => {
                alreadyShownPosts.add(chatPost.id)
                return chatPost
            })
    }, [chatPosts, reactionsVisible])

    const displayReactions = () => {
        setReactionsVisible(true)

        window.requestAnimationFrame(() => {
            const lastChatItem = document.querySelector(
                '.page--chat-thread-detail .component--chat-post-list-item:first-child'
            )
            const scroll = new SmoothScroll()
            scroll.animateScroll(lastChatItem, undefined, {
                offset: () => (isMobile() ? 60 : 10),
            })
        })
    }

    return (
        <div className="page page--chat page--chat-thread-detail bg-green">
            {chatThread && <ChatThreadItem {...chatThread} />}
            {chatPosts.length > 1 && !reactionsVisible && (
                <div className="page--chat-thread-detail__toggle-visibility">
                    <button className="button-hollow" onClick={displayReactions}>
                        Zeige alle {chatPosts.length} Reaktionen
                    </button>
                    <div className="chat-separator">
                        <p className="text-white">
                            Neueste Reaktion
                            <br /> {lastReactionSince}
                        </p>
                    </div>
                </div>
            )}
            {chatPosts.length >= 1 && <ChatPostList posts={filteredChatPosts} />}
            {!chatThread?.isLocked &&
                (isGroupChat
                    ? selfMembershipRole === 'admin' || selfMembershipRole === 'member'
                    : true) && <ChatPostAdd threadId={chatThread.id.toString()} />}
        </div>
    )
}

export { ChatThreadDetailView }
