import { FaqQuestion } from '../faq-topics/faq-topics.props'
import { GlobalState, Pagination } from '../state.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */
export const getFaqTopQuestions = (state: GlobalState): FaqQuestion[] | undefined =>
    state.faqTopQuestions.data.sort((a, b) => a.position - b.position)

export const getFaqTopQuestionsPagination = (state: GlobalState): Pagination =>
    state.faqTopQuestions.pagination
