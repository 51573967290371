import cn from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import useChatbotDispatch from '../../../hooks/use-chatbot-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import { getChatbotItems } from '../../../store/chatbot/chatbot.selectors'
import { GlobalState } from '../../../store/state.props'
import { noop } from '../../../utils/configs'
import ChatbotDialogueList from '../chatbot-dialogue-list'
import {
    ChatbotHomeDialogues,
    ChatbotSelfTestDialogues,
    ChatbotWebappInstallDialogues,
    ChatbotWidgetDialogues,
} from '../chatbot-dialogues'
import { ChatbotAuthenticationDialogues } from '../chatbot-dialogues/chatbot-authentication-dialogues'
import ChatbotTextInputWrapper from '../chatbot-text-input-wrapper'
import { ChatbotContextEnum } from '../chatbot.props'
import { ChatbotContainerProps } from './chatbot-container.props'

let latestItemsCount: number = 0

const Chatbot: FC<ChatbotContainerProps> = ({ context, onClickClose = noop }) => {
    const container = useRef<HTMLDivElement>(null)
    const sessionUser = useSessionUser()
    const { addChatbotItems, setUserAnswer, setInvalidAnswer, dispatchChatbotAction } =
        useChatbotDispatch(context, onClickClose)
    const items = useSelector((state: GlobalState) => getChatbotItems(state, context))
    const [maxHeight, setMaxHeight] = useState<string>('auto')
    const isSubpage =
        context === ChatbotContextEnum.SELF_TEST || context === ChatbotContextEnum.AUTHENTICATION

    useEffect(() => {
        latestItemsCount = 0

        switch (context) {
            case ChatbotContextEnum.HOME:
                // Toggle visibility of "Login / Register" dialogue item
                const HomeDialogues = [...ChatbotHomeDialogues]
                HomeDialogues[5].active = sessionUser === undefined
                addChatbotItems(HomeDialogues)
                break

            case ChatbotContextEnum.WIDGET:
                // Toggle visibility of "Login / Register" and "Logout" dialogue item
                const WidgetDialogues = [...ChatbotWidgetDialogues]
                WidgetDialogues[6].active = sessionUser === undefined
                WidgetDialogues[7].active = sessionUser !== undefined
                addChatbotItems(WidgetDialogues)
                break

            case ChatbotContextEnum.SELF_TEST:
                addChatbotItems(ChatbotSelfTestDialogues)
                break
            case ChatbotContextEnum.AUTHENTICATION:
                if (!sessionUser) {
                    addChatbotItems(ChatbotAuthenticationDialogues)
                }
                break
            case ChatbotContextEnum.WEBAPP_INSTALL:
                addChatbotItems(ChatbotWebappInstallDialogues)
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionUser])

    useEffect(() => {
        if (container.current && items?.length) {
            // The most sensitive part: Estimate container height
            // This varies on each page as different requirements and offsets come together
            const containerHeight: number = container.current.getBoundingClientRect().height

            if (maxHeight === 'auto' || items.length < latestItemsCount) {
                if (context === ChatbotContextEnum.HOME) {
                    // Never grow bigger than initial container height (as scoped in a fixed height container)
                    // Exception: userSession changed, thus once popping ChatbotHomeDialogues,
                    // thus changing height of container again
                    setMaxHeight(containerHeight + 'px')
                } else if (context === ChatbotContextEnum.WIDGET) {
                    let subtract = 80

                    if (window.innerWidth >= 768) {
                        subtract = 87
                    }

                    setMaxHeight(window.innerHeight - subtract + 'px')
                }

                latestItemsCount = items.length
            }

            if ((items.length > latestItemsCount || isSubpage) && container.current) {
                // Scroll bottom input field into viewport focus...
                container.current.scrollTo(0, container.current.scrollHeight)
            }

            if (
                context === ChatbotContextEnum.SELF_TEST &&
                ChatbotSelfTestDialogues.length === items.length
            ) {
                // ...except for initial call of "Selbsttest" (scroll to top)
                container.current.scrollTo(0, 0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    if (!items || !items.length) {
        return null
    }

    // For more information on alternative chatbot styling ".is-subpage", @see _chatbot.scss
    return (
        <div
            className={cn('component--chatbot', { 'is-subpage': isSubpage })}
            style={{ minHeight: maxHeight, maxHeight }}
            ref={!isSubpage ? container : null}
            data-context={context}
        >
            <div className="component--chatbot-dialogue-list" ref={isSubpage ? container : null}>
                <ChatbotDialogueList items={items} dispatchChatbotAction={dispatchChatbotAction} />
                {!isSubpage && (
                    <ChatbotTextInputWrapper
                        items={items}
                        dispatchChatbotAction={dispatchChatbotAction}
                        context={context}
                        setUserAnswer={setUserAnswer}
                        setInvalidAnswer={setInvalidAnswer}
                    />
                )}
            </div>
            {isSubpage && (
                <ChatbotTextInputWrapper
                    items={items}
                    dispatchChatbotAction={dispatchChatbotAction}
                    context={context}
                    setUserAnswer={setUserAnswer}
                    setInvalidAnswer={setInvalidAnswer}
                />
            )}
        </div>
    )
}

export default Chatbot
