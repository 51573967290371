import React, { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import {
    confirmGroupMember,
    deleteGroupMember,
    fetchChatNews,
    fetchChatThread,
} from '../../../store/chat-threads/chat-threads.actions'
import { GroupMembership } from '../../../store/chat-threads/chat-threads.props'
import { User } from '../../../store/user/user.props'
import {
    getGroupMembershipRole,
    getReadableGroupMembershipRole,
} from '../../../utils/group-memberships'
import MembersInviteDropdown from '../../dropdown/members-invite-dropdown'
import { ChatGroupMembersFormProps } from './chat-group-members-form.props'

const ChatGroupMembersForm: FC<ChatGroupMembersFormProps> = ({
    threadId,
    memberships,
    selfIsAdmin = false,
}) => {
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const [addUserOpen, setAddUserOpen] = useState<boolean>(false)
    const [actionInProgress, setActionInProgress] = useState<boolean>(false)
    const sessionUser = useSessionUser() as User
    const membershipRole = getGroupMembershipRole(memberships, sessionUser.username)
    const selfIsGroupAdmin = membershipRole === 'admin'

    // Sort memberships with logged in user at first place
    const sortedMemberships = useMemo(() => {
        const selfMembership = memberships.find(
            (membership) => membership.member.username === sessionUser.username
        )

        if (selfMembership) {
            const membershipsCopy = [...memberships]
            membershipsCopy.splice(membershipsCopy.indexOf(selfMembership), 1)
            membershipsCopy.unshift(selfMembership)

            return membershipsCopy
        }

        return memberships
    }, [memberships])

    const onClickMemberDeleteButton = (membership: GroupMembership) => {
        if (actionInProgress) return

        const selfIsMember = membership.member.username === sessionUser.username

        if (
            !window.confirm(
                selfIsMember
                    ? `Möchtest Du die Gruppe wirklich verlassen?`
                    : `Mitglied wirklich entfernen?`
            )
        ) {
            return
        }

        setActionInProgress(true)

        dispatch(deleteGroupMember(threadId, membership.id))
            .then(() => {
                setActionInProgress(false)

                if (selfIsGroupAdmin) {
                    // Unmark this thread, if it previously was
                    dispatch(fetchChatNews())
                }

                if (selfIsMember) {
                    dispatch(fetchChatThread(threadId))
                    navigate('/chat')
                }
            })
            .catch(() => {
                alert(
                    `Leider ist ein Fehler aufgetreten. Bitte überprüfe Deine Internetverbindung und probiere es erneut. Es ist auch möglich, dass Du bereits aus dem Gruppenchat entfernt wurdest.`
                )
            })
    }

    const onClickMemberConfirmButton = (member: GroupMembership) => {
        if (actionInProgress) return

        setActionInProgress(true)
        dispatch(confirmGroupMember(threadId, member.id))
            .then(() => {
                setActionInProgress(false)
                // Unmark this thread, if it previously was
                dispatch(fetchChatNews())
            })
            .catch(() => {
                alert(
                    `Leider ist ein Fehler aufgetreten. Bitte überprüfe Deine Internetverbindung und probiere es erneut.`
                )
            })
    }

    /**
     * Only allow deleting members for admins and the member itself
     */
    const isUserAllowedToDeleteMember = (membership: GroupMembership): boolean =>
        selfIsGroupAdmin
            ? membership.member.username !== sessionUser.username
            : membership.member.username === sessionUser.username

    /**
     * Only allow confirming members for the (invited) member itself
     */
    const isUserAllowedToConfirmMember = (membership: GroupMembership): boolean =>
        (membership.state === 'invited' && membership.member.username === sessionUser.username) ||
        (membership.state === 'requested' && selfIsGroupAdmin)

    if (!sessionUser) {
        return (
            <div className="input-wrapper">
                <label className="label-hashtag-link">Mitglieder</label>
            </div>
        )
    }

    return (
        <div className="component--chat-group-membership-form">
            <div className="input-wrapper">
                <label className="label-hashtag-link">Mitglieder</label>
                <ul className="component--chat-group-membership-form__list">
                    {sortedMemberships.map((membership) => (
                        <li key={membership.id}>
                            <div>
                                <p>
                                    {membership.member.username === sessionUser.username
                                        ? 'Du'
                                        : membership.member.username}
                                </p>
                                <span className="meta-text">
                                    {getReadableGroupMembershipRole(membership.state)}
                                </span>
                            </div>
                            <div className="component--chat-group-membership-form__list__actions">
                                {isUserAllowedToDeleteMember(membership) && (
                                    <div
                                        className="btn-delete"
                                        onClick={() => onClickMemberDeleteButton(membership)}
                                    ></div>
                                )}
                                {isUserAllowedToConfirmMember(membership) && (
                                    <div
                                        className="btn-accept"
                                        onClick={() => onClickMemberConfirmButton(membership)}
                                    ></div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {selfIsAdmin && (
                <div className="input-wrapper">
                    {!addUserOpen ? (
                        <button
                            className="button button-center"
                            onClick={() => setAddUserOpen(true)}
                        >
                            Nutzer:in einladen
                        </button>
                    ) : (
                        <MembersInviteDropdown threadId={threadId} excludeMembers={memberships} />
                    )}
                </div>
            )}
        </div>
    )
}

export default ChatGroupMembersForm
