import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import ChatGroupForm from '../../../components/chat/chat-group-form'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchChatThread, patchChatThread } from '../../../store/chat-threads/chat-threads.actions'
import { ChatThreadRequest } from '../../../store/chat-threads/chat-threads.props'
import { getChatThreadById } from '../../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getGroupMembership } from '../../../utils/group-memberships'
import { ChatGroupEditFormParams } from './chat-group-edit.props'

/**
 * @route /chat/gruppe/:id/bearbeiten
 */
const ChatGroupEditForm: FC = () => {
    const { setParentLink } = useSetParentLink('/chat')
    const sessionUser = useSessionUser()
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams<ChatGroupEditFormParams>()
    const chatThread = useSelector((state: GlobalState) => getChatThreadById(state, Number(id)))
    const selfGroupMembership =
        chatThread && sessionUser
            ? getGroupMembership(chatThread.groupMembers, sessionUser.username)
            : null
    const selfGroupMembershipRole = selfGroupMembership ? selfGroupMembership.state : null

    const linkTarget = location.pathname.replace('/bearbeiten', '').replace('/gruppe', '/beitrag')

    useEffect(() => {
        // Re-load thread to ensure we have the latest data
        dispatch(fetchChatThread(Number(id))).catch(() => {
            // Not found... or no connection...
            navigate('/chat')
        })
    }, [])

    if (!chatThread || !sessionUser) {
        return <Loading />
    }

    if (
        chatThread &&
        sessionUser &&
        selfGroupMembershipRole !== 'admin' &&
        selfGroupMembershipRole !== 'member'
    ) {
        navigate('/chat')
        return null
    }

    return (
        <div className="page page--chat-thread-new">
            <ChatGroupForm
                threadTitle={chatThread.title}
                threadText={chatThread.text}
                threadChatTopicId={chatThread.chatTopicId}
                formTitle="Gruppenchat bearbeiten"
                textBlockKey={TextBlockCode.INTRO_CHAT_GROUP_EDIT}
                submitAction={(body: ChatThreadRequest) =>
                    dispatch(patchChatThread(chatThread.id, body))
                }
                linkTarget={linkTarget}
                linkText={`Zum Gruppenchat`}
                thread={chatThread}
                selfIsAdmin={selfGroupMembershipRole === 'admin'}
            />
        </div>
    )
}

export default ChatGroupEditForm
