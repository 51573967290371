import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import EmergencyKitAddForm from '../../../components/emergency-kit/emergency-kit-add-form'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../../utils/configs'

const EmergencyKitNew: FC = () => {
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_EMERGENCY_KIT_NEW)
    )

    return (
        <div className="page page--emergency-kit page--emergency-kit-new">
            <div className="page-header">
                <h1 className="title-black">Element hinzufügen</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>
            <EmergencyKitAddForm />
        </div>
    )
}

export default EmergencyKitNew
