import { getApiUrl, handleFetchError } from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult, GlobalState, Pagination } from '../state.props'
import { BlogArticle, BlogArticlesState } from './blog-articles.props'
import { getBlogPagination } from './blog-articles.selectors'

export const FETCH_BLOG_ARTICLES_PENDING = 'FETCH_BLOG_ARTICLES_PENDING'
export const ADD_BLOG_ARTICLE = 'ADD_BLOG_ARTICLE'
export const ADD_BLOG_ARTICLES = 'ADD_BLOG_ARTICLES'
export const UPDATE_BLOG_PAGINATION = 'UPDATE_BLOG_PAGINATION'
export const FLUSH_BLOG_ARTICLES = 'FLUSH_BLOG_ARTICLES'

const fetchBlogArticlesSuccess = (blogArticles: BlogArticlesState) => {
    return {
        type: ADD_BLOG_ARTICLES,
        blogArticles,
    }
}

const fetchBlogArticleSuccess = (blogArticle: BlogArticle) => {
    return {
        type: ADD_BLOG_ARTICLE,
        blogArticle,
    }
}

const fetchBlogArticlesPending = () => {
    return {
        type: FETCH_BLOG_ARTICLES_PENDING,
    }
}

const updatePagination = (pagination: Pagination) => {
    return {
        type: UPDATE_BLOG_PAGINATION,
        pagination,
    }
}

export const flushBlogArticles = () => {
    return {
        type: FLUSH_BLOG_ARTICLES,
    }
}

// GET /blog-articles
export const fetchBlogArticles =
    (): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        dispatch(fetchBlogArticlesPending())

        let nextPage = getBlogPagination(getState()).nextPage || 0

        return fetch(`${getApiUrl()}/blog-articles?page=${nextPage}`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(updatePagination(json.pagination))
                dispatch(fetchBlogArticlesSuccess(json))
                return json
            })
            .catch((error: Error) => error)
    }

// GET /blog-articles?query={string}
// Does NOT mutate store
export const fetchBlogArticlesByQuery =
    (query: string, page: number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch, getState: () => GlobalState) => {
        return fetch(`${getApiUrl()}/blog-articles?page=${page}&query=${query}`)
            .then(handleFetchError)
            .then((json) => json)
    }

// GET /blog-articles/ID
export const fetchBlogArticle =
    (id: string | number): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(fetchBlogArticlesPending())

        return fetch(`${getApiUrl()}/blog-articles/${id}`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchBlogArticleSuccess(json))
            })
    }

// GET /blog-articles/ID
export const fetchBlogArticleBySlug =
    (slug: string): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) => {
        dispatch(fetchBlogArticlesPending())

        return fetch(`${getApiUrl()}/blog-articles?slug=${slug}`)
            .then(handleFetchError)
            .then((json) => {
                dispatch(fetchBlogArticleSuccess(json))
            })
    }
