import React, { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ChatPostAddButton from './chat-post-add-button'
import ChatPostAddForm from './chat-post-add-form'
import { ChatPostAddProps } from './chat-post-add.props'

const ChatPostAdd: FC<ChatPostAddProps> = ({ threadId }) => {
    const location = useLocation()
    // Visible when redirected from previous login step
    const [formVisible, toggleFormVisibility] = useState(location.search === '?_=1')

    return (
        <div className="component--chat-post-add">
            {formVisible ? (
                <ChatPostAddForm threadId={threadId} toggleFormVisibility={toggleFormVisibility} />
            ) : (
                <ChatPostAddButton
                    threadId={threadId}
                    toggleFormVisibility={toggleFormVisibility}
                />
            )}
        </div>
    )
}

export default ChatPostAdd
