import { AnyAction } from 'redux'

import { ChatbotContextEnum } from '../../components/chatbot/chatbot.props'
import {
    ADD_ITEM,
    ADD_ITEMS,
    ADD_ITEM_AFTER,
    APPEND_ITEMS,
    EVALUATE_ANSWERS,
    REPLACE_ITEM_CONTENT,
    SET_NEXT_ITEM_ACTIVE,
    UPDATE_ANSWER,
} from './chatbot.actions'
import { ChatbotDialogueItem, ChatbotState } from './chatbot.props'

/**
 * Each <Chatbot />-component is assigned a context (see lines below) which
 * stores its items. This way one can have multiple open chatbots in one
 * window and, eventually, store dialogues endlessly.
 */
const initialState: ChatbotState = {
    data: {
        [ChatbotContextEnum.AUTHENTICATION]: [],
        [ChatbotContextEnum.HOME]: [],
        [ChatbotContextEnum.SELF_TEST]: [],
        [ChatbotContextEnum.WIDGET]: [],
    },
}

const ChatbotReducer = (state: ChatbotState = initialState, action: AnyAction): ChatbotState => {
    const context: ChatbotContextEnum = action.context

    switch (action.type) {
        case ADD_ITEM:
            return {
                ...state,
                data: {
                    ...state.data,
                    [context]: [...state.data[context], action.item],
                },
            }
        case ADD_ITEM_AFTER:
            return {
                // Add item while keeping sort
                ...state,
                data: {
                    ...state.data,
                    [context]: [
                        ...state.data[context].slice(0, action.addAfterIndex + 1),
                        action.item,
                        ...state.data[context].slice(action.addAfterIndex + 1),
                    ],
                },
            }
        case ADD_ITEMS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [context]: action.data,
                },
            }
        case APPEND_ITEMS:
            // Whilst ADD_ITEMS replaces state.data,
            // this reducer attaches new items at the end of list
            return {
                ...state,
                data: {
                    ...state.data,
                    [context]: [...state.data[context], ...action.data],
                },
            }
        case REPLACE_ITEM_CONTENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    [context]: state.data[context].map((item: ChatbotDialogueItem) => {
                        if (item.id === action.id) {
                            item.content = item.content.replace('__X__', action.placeholder)
                        }
                        return item
                    }),
                },
            }
        case UPDATE_ANSWER:
            return {
                ...state,
                data: {
                    ...state.data,
                    [context]: state.data[context].map((item: ChatbotDialogueItem) => {
                        if (item.id === action.id) {
                            item.answer = action.data
                        }
                        return item
                    }),
                },
            }
        case EVALUATE_ANSWERS:
            const evaluationIndex: number = state.data[ChatbotContextEnum.SELF_TEST].findIndex(
                (item) => item.id === action.id + 1
            )

            if (
                /*evaluationIndex !== action.id ||*/ !state.data[ChatbotContextEnum.SELF_TEST][
                    evaluationIndex
                ].evaluationResults
            ) {
                return state
            }

            let yesCounter1To3 = 0
            let yesCounter4To10 = 0
            let suicideItem = false

            let evaluatedIds = new Set()

            state.data[ChatbotContextEnum.SELF_TEST].forEach((item) => {
                if (
                    !evaluatedIds.has(item.id) &&
                    item.id > 0 &&
                    item.id <= 3 &&
                    item.answer?.value === '1'
                ) {
                    yesCounter1To3++
                    evaluatedIds.add(item.id)
                } else if (
                    !evaluatedIds.has(item.id) &&
                    item.id > 3 &&
                    item.id <= 10 &&
                    item.answer?.value === '1'
                ) {
                    yesCounter4To10++
                    evaluatedIds.add(item.id)
                }

                if (item.id === 10 && item.answer?.value === '1') {
                    suicideItem = true
                }
            })

            let evaluationResultIndex = 0

            if (
                (yesCounter1To3 === 0 && yesCounter4To10 <= 2) ||
                (yesCounter1To3 === 1 && yesCounter4To10 <= 1)
            ) {
                evaluationResultIndex = 0
            } else if (
                (yesCounter1To3 === 0 && yesCounter4To10 >= 3 && yesCounter4To10 <= 4) ||
                (yesCounter1To3 === 1 && yesCounter4To10 >= 2 && yesCounter4To10 <= 3) ||
                (yesCounter1To3 === 2 && yesCounter4To10 >= 0 && yesCounter4To10 <= 1)
            ) {
                evaluationResultIndex = 1
            } else if (
                (yesCounter1To3 === 0 && yesCounter4To10 >= 5 && yesCounter4To10 <= 6) ||
                (yesCounter1To3 === 1 && yesCounter4To10 >= 4 && yesCounter4To10 <= 6) ||
                (yesCounter1To3 === 2 && yesCounter4To10 === 2) ||
                (yesCounter1To3 === 3 && yesCounter4To10 <= 1)
            ) {
                evaluationResultIndex = 2
            } else {
                evaluationResultIndex = 3
            }

            let evaluationResult: string =
                state.data[ChatbotContextEnum.SELF_TEST][evaluationIndex].evaluationResults![
                    evaluationResultIndex
                ]

            /**
             * check for "suicidal answer"
             * if answered with "yes" add another text fragment
             */
            if (suicideItem) {
                evaluationResult +=
                    state.data[ChatbotContextEnum.SELF_TEST][evaluationIndex].evaluationResults![4]
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [ChatbotContextEnum.SELF_TEST]: state.data[ChatbotContextEnum.SELF_TEST].map(
                        (item: ChatbotDialogueItem) => {
                            if (
                                item.id ===
                                state.data[ChatbotContextEnum.SELF_TEST][evaluationIndex].id
                            ) {
                                item.content = evaluationResult
                            }
                            return item
                        }
                    ),
                },
            }
        case SET_NEXT_ITEM_ACTIVE:
            // Find the next item and push it to the top of the stack.
            // Not ideal as items become duplicated that way.
            const itemIndex: number = state.data[context].findIndex(
                (item) => item.id === action.id + 1
            )

            return {
                ...state,
                data: {
                    ...state.data,
                    [context]: [
                        ...state.data[context].slice(0, itemIndex),
                        ...state.data[context].slice(itemIndex, state.data[context].length),
                        {
                            ...state.data[context][itemIndex],
                            active: true,
                        },
                    ],
                },
            }
    }

    return state
}

export default ChatbotReducer
