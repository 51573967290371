import { getApiUrl, handleFetchError } from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult } from '../state.props'
import { IMenu, IMenuType, IPage } from './menu-pages.props'

export const ADD_MENU = 'ADD_MENU'
export const ADD_MENUS = 'ADD_MENUS'
export const ADD_PAGE = 'ADD_PAGE'
export const ADD_PAGES = 'ADD_PAGES'

const addMenu = (menu: IMenu) => ({
    type: ADD_MENU,
    menu,
})

const addPage = (page: IPage) => ({
    type: ADD_PAGE,
    page,
})

export const fetchMenus = (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) =>
    fetch(`${getApiUrl()}/page-menus`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(handleFetchError)
        .then((json) => {
            const menus: IMenu[] = json.data
            menus.forEach((menu) => {
                dispatch(addMenu(menu))
            })

            return json
        })
        .catch((error) => Promise.reject(error))

/**
 * GET /page-menus/TYPE
 */
export const fetchMenu =
    (type: IMenuType): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/page-menus/${type}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(handleFetchError)
            .then((json) => {
                const menu: IMenu = json.data
                dispatch(addMenu(menu))
                return json
            })
            .catch((error) => Promise.reject(error))

export const fetchPage =
    (needle: number | string): FideoThunkResult<Promise<any>> =>
    (dispatch: FideoThunkDispatch) =>
        typeof needle === 'number'
            ? fetchPageById(needle, dispatch)
            : fetchPageBySlug(needle, dispatch)

export const fetchPageById = (id: number, dispatch: FideoThunkDispatch) =>
    fetch(`${getApiUrl()}/pages/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(handleFetchError)
        .then((json) => {
            dispatch(addPage(json.data))
            return json
        })
        .catch((error) => Promise.reject(error))

export const fetchPageBySlug = (slug: string, dispatch: FideoThunkDispatch) =>
    fetch(`${getApiUrl()}/pages?slug=${slug}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(handleFetchError)
        .then((json) => {
            dispatch(addPage(json.data[0]))
            return json
        })
        .catch((error) => Promise.reject(error))
