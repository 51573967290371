import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import Modal from '../../components/layout/modal'
import useSetParentLink from '../../hooks/use-set-parent-link'
import DeleteAccountModal from './delete-account-modal'

const Settings: FC = () => {
    useSetParentLink('/profil')
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false)

    return (
        <div className="page page--settings">
            <div className="flex-container-list">
                <div className="flex-item-list">
                    <Link to="/einstellungen/telefonnummer">
                        <h2>Telefonnummer</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/einstellungen/kontaktdetails">
                        <h2>Kontaktdetails</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/einstellungen/passwort">
                        <h2>Passwort</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <a
                        href="#"
                        onClick={() => {
                            setDeleteAccountModalOpen(true)
                        }}
                    >
                        <h2>Profil löschen</h2>
                        <i className="flex-item-list--arrow"></i>
                    </a>
                </div>
                {deleteAccountModalOpen && (
                    <Modal setModalOpen={setDeleteAccountModalOpen}>
                        <DeleteAccountModal setModalOpen={setDeleteAccountModalOpen} />
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default Settings
