import { useSelector } from 'react-redux'

import { getSessionUser } from '../store/user/user.selectors'

const useSessionUser = () => {
    const sessionUser = useSelector(getSessionUser)
    return sessionUser
}

export default useSessionUser
