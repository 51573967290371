import SmoothScroll from 'smooth-scroll'

import { IMenuPage } from '../store/menu-pages/menu-pages.props'

export const SLUG_ABOUT_PAGE = '/ueber-uns'
export const SLUG_DATENSCHUTZ = '/datenschutz'
export const SLUG_AGB = '/nutzungsbedingungen'

export const getMenuPageLink = (page: IMenuPage): string => page.slug ?? `/seiten/${page.pageId}`

export const scrollToTop = (): void => {
    const scroll = new SmoothScroll()
    const target = document.getElementById('root')
    scroll.animateScroll(target)
}
