import cn from 'classnames'
import React, { FC } from 'react'

import ChatbotDialogueListAnswerOption from '../chatbot-dialogue-list-answer-option'
import { ChatbotDialogueItemActionType } from '../chatbot.props'
import { ChatbotDialogueListItemProps } from './chatbot-dialogue-list-item.props'

const ChatbotDialogueListItem: FC<ChatbotDialogueListItemProps> = ({
    item,
    dispatchChatbotAction,
}) => {
    if (!item.active) {
        return null
    }

    const onClick = () => {
        ;(item.actionType === ChatbotDialogueItemActionType.CLICK ||
            item.actionType === ChatbotDialogueItemActionType.ROUTE) &&
            dispatchChatbotAction(item)
    }

    // item.id was removed from display as requested in #2014
    return (
        <div className="component--chatbot-dialogue-list-item">
            <div
                className={cn('list-item-content', item.renderWithoutMargin ? 'is-fc' : '')}
                data-dialogue-type={item.type}
                style={{
                    animationDelay: (item.transitionTime ? item.transitionTime : '0') + 's',
                }}
                onClick={onClick}
            >
                {!item.contentComponent && item.content}
                {item.contentComponent && item.contentComponent()}
            </div>

            <div className="answer-options">
                {item.answerOptions?.map((answerOption, index) => (
                    <ChatbotDialogueListAnswerOption
                        item={item}
                        answerOption={answerOption}
                        dispatchChatbotAction={dispatchChatbotAction}
                        key={index}
                    />
                ))}
            </div>
        </div>
    )
}

export default ChatbotDialogueListItem
