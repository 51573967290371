import { ChatbotDialogueItem } from '../../../store/chatbot/chatbot.props'
import { SLUG_ABOUT_PAGE } from '../../../utils/pages'
import { ChatbotDialogueItemActionType as ActionType } from '.././chatbot.props'

// Eventually, apply changes to tags also to ./chatbot-widget-object.ts
export const ChatbotHomeDialogues: ChatbotDialogueItem[] = [
    {
        id: 0,
        type: 'left',
        active: true,
        content: 'Hi, mein Name ist Fideo. Was möchtest Du tun?',
        transitionTime: 1,
    },
    {
        id: 1,
        type: 'right',
        actionType: ActionType.ROUTE,
        active: true,
        content: 'Was ist Fideo?',
        transitionTime: 1.5,
        navigationTarget: SLUG_ABOUT_PAGE,
        tags: ['app', 'kann', 'machen', 'info'],
    },
    {
        id: 2,
        type: 'right',
        actionType: ActionType.ROUTE,
        active: true,
        content: 'Antworten finden',
        transitionTime: 2,
        navigationTarget: '/antworten',
        tags: ['allgemein', 'fragen', 'faq', 'erklärung'],
    },
    {
        id: 3,
        type: 'right',
        actionType: ActionType.ROUTE,
        active: true,
        content: 'Austausch mit anderen',
        transitionTime: 2.5,
        navigationTarget: '/chat',
        tags: ['chat', 'reden', 'dialog', 'beitrag', 'beiträge', 'reaktion', 'forum', 'kommuni'],
    },
    {
        id: 4,
        type: 'right',
        actionType: ActionType.ROUTE,
        active: true,
        content: 'Selbsttest',
        transitionTime: 3,
        navigationTarget: '/selbsttest',
        tags: ['check', 'analys', 'auswert', 'probe', 'zweifel'],
    },
    {
        id: 5,
        type: 'right',
        actionType: ActionType.ROUTE,
        active: true,
        content: 'Login / Registrierung',
        transitionTime: 3.5,
        navigationTarget: '/auth',
        tags: ['auth', 'anmeld', 'einloggen'],
    },
]
