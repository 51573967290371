import React, { FC, MouseEvent, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { IEmergencyKitItem } from '../../../store/emergency-kit/emergency-kit.props'

interface IEmergencyKitEditContainer {
    isEditMode: boolean
    setIsEditMode: (onOff: boolean) => void
    onSubmit: (ev: MouseEvent, takeSortFrom?: number[] | undefined) => void
    initialEmergencyKitItems: IEmergencyKitItem[]
}

/**
 * initialItems [#2158]: Remember sorting of emergency kit items prior to editing.
 * Useful when an user deletes items in edit-mode but clicks on "Abbrechen":
 * Then, in terms of UX, we would still have to delete items that had been uploaded previously.
 */
let initialItems: IEmergencyKitItem[] = []

const EmergencyKitEditContainer: FC<IEmergencyKitEditContainer> = ({
    isEditMode,
    setIsEditMode,
    onSubmit,
    initialEmergencyKitItems,
}) => {
    useEffect(() => {
        initialItems = initialEmergencyKitItems
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAbort = (ev: MouseEvent) => {
        onSubmit(
            ev,
            initialItems.filter((item) => !item.justUploaded).map((item) => item.id)
        )
    }

    return (
        <div className="component--emergency-kit-edit">
            {isEditMode ? (
                <>
                    <div className="component--emergency-kit-edit-add">
                        <Link to="/notfallkoffer/hinzufuegen" className="button button-center">
                            Element hinzufügen
                        </Link>
                    </div>
                    <div className="component--emergency-kit-edit-buttons">
                        <button className="button button-secondary" onClick={onAbort}>
                            Abbrechen
                        </button>
                        <button className="button" onClick={onSubmit}>
                            Speichern
                        </button>
                    </div>
                </>
            ) : (
                <button className="button button-center" onClick={() => setIsEditMode(true)}>
                    Bearbeiten
                </button>
            )}
        </div>
    )
}

export default EmergencyKitEditContainer
