import React, { FC } from 'react'
import { AnyAction } from 'redux'

import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import { LoadMoreButtonProps } from './button-load-more.props'

const ButtonLoadMore: FC<LoadMoreButtonProps> = ({ dispatchAction, title = 'Mehr laden' }) => {
    const dispatch = useFideoDispatch()

    const onClick = () => {
        dispatch(dispatchAction() as unknown as AnyAction)
    }

    return (
        <button className="component--button-load-more button button-center" onClick={onClick}>
            {title}
        </button>
    )
}

export default ButtonLoadMore
