import cn from 'classnames'
import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    isValidCaptcha,
    isValidPhoneNbr,
} from '../../../components/chatbot/chatbot-dialogues/chatbot-authentication-dialogues'
import VerificationForm from '../../../components/verification-form'
import useCaptcha from '../../../hooks/use-captcha'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSessionUser from '../../../hooks/use-session-user'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import {
    SET_PHONE_NBR_PENDING,
    editUser,
    editUserPhoneNumber,
} from '../../../store/user/user.actions'
import { User, VerificationTarget } from '../../../store/user/user.props'
import { APIError } from '../../../utils/api'
import { defaultInputFieldAttributes, getUnsanitizedPageText } from '../../../utils/configs'
import { isInvalidCaptchaError } from '../../../utils/errors'

const SettingsPhoneNbr: FC = () => {
    const [captchaToken, captchaImageUrl, fetchCaptcha] = useCaptcha()
    const dispatch = useFideoDispatch()
    const sessionUser = useSessionUser()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_SETTINGS_PHONENBR)
    )
    const [formState, setFormState] = useState<string>('')
    const [phoneNbr, setPhoneNbr] = useState<string>('')
    const [captchaCode, setCaptchaCode] = useState<string>('')

    const isPending = (typeof sessionUser?.phoneNumberPending !== 'undefined' &&
        sessionUser.phoneNumberPending) as boolean

    useEffect(() => {
        if (sessionUser && sessionUser.phoneNumberPending) {
            setPhoneNbr(sessionUser.phoneNumberPending)
        }
    }, [sessionUser])

    const reloadCaptcha = () =>
        fetchCaptcha().then(() => {
            setCaptchaCode('')
        })

    const onChangePhoneNbr = (ev: ChangeEvent<HTMLInputElement>) => {
        if (isPending) {
            return
        }
        setPhoneNbr(ev.currentTarget.value)
    }

    const onClickReloadCaptcha = (ev: MouseEvent) => {
        reloadCaptcha()
        ev.preventDefault()
    }

    const onChangeCaptchaCode = (ev: ChangeEvent<HTMLInputElement>) => {
        setCaptchaCode(ev.target.value)
    }

    const onSubmit = (ev: MouseEvent) => {
        dispatch(
            editUserPhoneNumber(phoneNbr, {
                token: captchaToken as string,
                phrase: captchaCode as string,
            })
        )
            .then(() => {
                setFormState('')
                dispatch({
                    type: SET_PHONE_NBR_PENDING,
                    phoneNbr,
                })
            })
            .catch((error: APIError) => {
                reloadCaptcha()
                if (error.error && error.error.code === 400) {
                    if (isInvalidCaptchaError(error)) {
                        setFormState(
                            `Der eingegebene Sicherheitscode ist ungültig. Bitte erneut probieren.`
                        )
                    } else {
                        setFormState(
                            `Telefonnummer ist bereits vergeben oder ungültig. Um Dich zu verifizieren benötigen wir Deine deutsche Mobilnummer ohne Leerzeichen und Landesvorwahl (z.B. 0171123456)`
                        )
                    }
                } else {
                    setFormState(
                        `Telefonnummer konnte nicht geändert werden. Ist diese gültig? Um Dich zu verifizieren benötigen wir Deine deutsche Mobilnummer ohne Leerzeichen und Landesvorwahl (z.B. 0171123456)`
                    )
                }
            })

        ev.preventDefault()
    }

    const onVerificationSuccess = (user: User) => {
        dispatch(editUser(user))
        setPhoneNbr('')
        setFormState(`Telefonnummer erfolgreich geändert!`)
    }

    return (
        <div className="page page--settings-phone-nbr">
            <div className="page-header">
                <h1 className="title-black">Telefonnummer ändern</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <form className="phone-nbr-form page-header" method="POST" action="#">
                <div className="input-wrapper">
                    <label
                        className="label-hashtag-link"
                        htmlFor="phone-nbr-form__phone-nbr-current"
                    >
                        Deine aktuelle Telefonnummer
                    </label>
                    <input
                        type="text"
                        id="phone-nbr-form__phone-nbr-current"
                        name="phone-nbr-form__phone-nbr-current"
                        disabled
                        readOnly
                        value={sessionUser?.phoneNumber || ''}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="phone-nbr-form__phone-nbr">
                        Deine neue Telefonnummer
                    </label>
                    <input
                        type="text"
                        id="phone-nbr-form__phone-nbr"
                        name="phone-nbr-form__phone-nbr"
                        disabled={isPending}
                        value={phoneNbr}
                        onChange={onChangePhoneNbr}
                        placeholder={`z.B. 01711234567`}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper captcha-wrapper">
                    <label className="label-hashtag-link" htmlFor="contact-form__lastname">
                        Captcha*
                    </label>
                    <div className="captcha-field">
                        <img
                            alt="Lade Captcha..."
                            src={captchaImageUrl || ''}
                            width="800"
                            height="200"
                        />
                        <button
                            title="Neues Captcha laden"
                            onClick={onClickReloadCaptcha}
                            type="button"
                        ></button>
                    </div>

                    <input
                        type="text"
                        id="contact-form__captcha"
                        name="contact-form__captcha"
                        value={captchaCode}
                        required={true}
                        placeholder={`Code`}
                        onChange={onChangeCaptchaCode}
                    />
                </div>

                {formState !== '' && (
                    <p
                        className={cn(
                            'form-error input-wrapper',
                            formState.indexOf('erfolgreich') >= 0 ? 'form-error--success' : null
                        )}
                    >
                        {formState}
                    </p>
                )}

                <div className="form__buttons">
                    <Link to="/einstellungen" className="button button-secondary">
                        Abbrechen
                    </Link>

                    <button
                        type="submit"
                        disabled={
                            !(
                                !isPending &&
                                phoneNbr !== '' &&
                                phoneNbr !== sessionUser?.phoneNumber &&
                                isValidPhoneNbr(phoneNbr) &&
                                isValidCaptcha(captchaCode)
                            )
                        }
                        onClick={onSubmit}
                        className="button button-submit"
                    >
                        Speichern
                    </button>
                </div>
            </form>

            {isPending && (
                <VerificationForm
                    target={VerificationTarget.PHONE_NUMBER}
                    callback={onVerificationSuccess}
                />
            )}
        </div>
    )
}

export default SettingsPhoneNbr
