import cn from 'classnames'
import moment from 'moment'
import React, { FC, MouseEvent, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { calendarFormat } from '../../../utils/format'
import { reportChatPost } from './chat-post-list-item.actions'
import { ChatPostListItemProps } from './chat-post-list-item.props'

const ChatPostListItem: FC<ChatPostListItemProps> = ({
    chatPost,
    sessionUserName,
    newPostsNum = undefined,
}) => {
    const onClick = (ev: MouseEvent) => {
        reportChatPost(chatPost.id)
        ev.preventDefault()
    }

    const isOwnPost = chatPost.creatorName === sessionUserName
    const isEditedByAdmin = chatPost.isEdited && chatPost.editedAt && chatPost.isEditedByAdmin
    const isEditedByUser = chatPost.isEdited && chatPost.editedAt && !chatPost.isEditedByAdmin

    const createdAt = useMemo(
        () => moment(chatPost.createdAt).calendar(undefined, calendarFormat),
        [chatPost.createdAt]
    )

    return (
        <div
            id={`post-${chatPost.id}`}
            className={cn(
                'component--chat-post-list-item chat-card',
                isOwnPost ? 'chat-card-user' : '',
                chatPost.creatorIsMod ? 'is-mod' : ''
            )}
        >
            <div className="chat-card-wrapper">
                {isOwnPost ? (
                    <div className="user">
                        {newPostsNum && <div className="badge">{newPostsNum}</div>}
                    </div>
                ) : (
                    <p className="label-nickname">
                        {chatPost.creatorName}
                        {chatPost.creatorIsMod && (
                            <p className="meta-text label-mod">Fideo-Moderation</p>
                        )}
                    </p>
                )}
                <p className="meta-text">
                    {createdAt}
                    {isOwnPost ? (
                        <Link
                            to={`/chat/reaktion/${chatPost.id}/bearbeiten`}
                            title="Reaktion bearbeiten"
                        />
                    ) : (
                        ''
                    )}
                </p>
            </div>
            <p>{chatPost.text}</p>
            {isEditedByAdmin && (
                <p className="meta-text meta-edit-text">
                    Diese Reaktion wurde am {moment(chatPost.editedAt).format('DD.MM.YYYY, HH:mm')}{' '}
                    redaktionell bearbeitet.
                </p>
            )}
            {isEditedByUser && (
                <p className="meta-text meta-edit-text">
                    Diese Reaktion wurde am {moment(chatPost.editedAt).format('DD.MM.YYYY, HH:mm')}{' '}
                    bearbeitet.
                </p>
            )}
            {sessionUserName && !isOwnPost && (
                <>
                    <div className="border-bottom"></div>
                    <a
                        href="#"
                        className="meta-text text-blue-mid"
                        title="Reaktion dem Fideo-Team melden"
                        onClick={onClick}
                    >
                        Reaktion melden
                    </a>
                </>
            )}
        </div>
    )
}

export default ChatPostListItem
