import { GlobalState } from '../state.props'
import { ChatTopic } from './chat-topics.props'

/**
 * @attention Selectors relying heavily on props input or returning deeply nested
 * objects may need to be memoized, @see https://react-redux.js.org/next/api/hooks#useselector
 */

/**
 * @return ChatTopic[] Sorted by position asc
 */
export const getChatTopics = (state: GlobalState): ChatTopic[] | undefined =>
    state.chatTopics.data.sort((a, b) => a.position - b.position)

export const getChatTopicById = (state: GlobalState, id: number): ChatTopic | undefined =>
    state.chatTopics.data.find((chatTopic) => chatTopic.id === id)

export const getSelectableChatTopics = (state: GlobalState): ChatTopic[] =>
    state.chatTopics.data.filter((chatTopic) => chatTopic.isSelectable)
