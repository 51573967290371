import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'

import FaqQuestionList from '../../../components/faq/faq-question-list'
import FaqTopicList from '../../../components/faq/faq-topic-list'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useSeo from '../../../hooks/use-seo'
import { fetchFaqTopQuestions } from '../../../store/faq-top-questions/faq-top-questions.actions'
import { getFaqTopQuestions } from '../../../store/faq-top-questions/faq-top-questions.selectors'
import { fetchFaqTopics } from '../../../store/faq-topics/faq-topics.actions'
import { getFaqTopics } from '../../../store/faq-topics/faq-topics.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { getUnsanitizedPageText } from '../../../utils/configs'

const FaqTopicOverview: FC = () => {
    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_FAQ_TOPIC_OVERVIEW)
    )
    const faqTopics = useSelector((state: GlobalState) => getFaqTopics(state))
    const faqTopQuestions = useSelector((state: GlobalState) => getFaqTopQuestions(state))

    useEffect(() => {
        // Initial fetch from api
        dispatch(fetchFaqTopics(true))
        dispatch(fetchFaqTopQuestions(true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Update canonical link
    useSeo({
        title: 'Fragen und Antworten',
        metaTags: [],
        slug: '/antworten',
    })

    return (
        <div className="page page--faq page--faq-topic-overview bg-orange">
            <div className="page-header">
                <h1 className="title-white">Antworten finden</h1>
                <p
                    className="text-white"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>
            <div className="headline-section">Bereiche</div>
            {(!faqTopics || faqTopics.length === 0) && <Loading />}
            <FaqTopicList topics={faqTopics} />

            <div className="headline-section">Top Fragen</div>
            {(!faqTopQuestions || faqTopQuestions.length === 0) && <Loading />}
            <FaqQuestionList faqQuestions={faqTopQuestions} includeJsonLd={false} />
        </div>
    )
}

export default FaqTopicOverview
