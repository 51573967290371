import React, { FC } from 'react'

import Chatbot from '../../components/chatbot'
import { ChatbotContextEnum } from '../../components/chatbot/chatbot.props'
import useSeo from '../../hooks/use-seo'

const SelfTest: FC = () => {
    // Update canonical link
    useSeo({
        title: 'Selbsttest',
        metaTags: [],
        slug: '/selbsttest',
    })

    return (
        <div className="page page--profile page--self-test">
            <div className="page--self-test__header page-header">
                <Chatbot context={ChatbotContextEnum.SELF_TEST} />
                <br />
            </div>
        </div>
    )
}

export default SelfTest
