import { FC, useEffect } from 'react'
import { NewsArticle, WithContext } from 'schema-dts'

import { BlogDetailSchemaProps } from './blog-detail.props'

const BlogDetailSchema: FC<BlogDetailSchemaProps> = ({ article }) => {
    useEffect(() => {
        /**
         * "NewsArticle" JSON-LD Schema for Google Results
         * @see https://schema.org/NewsArticle
         * Tester: https://search.google.com/test/rich-results
         */
        const script = document.createElement('script')
        script.type = 'application/ld+json'
        const json: WithContext<NewsArticle> = {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: article.title,
            datePublished: article.createdAt,
        }

        if (article.image && article.image.url) {
            json.image = [article.image.url]
        }

        script.innerText = JSON.stringify(json)
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    })

    return null
}

export default BlogDetailSchema
