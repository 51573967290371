import { getApiUrl, handleFetchError } from '../../utils/api'
import { FideoThunkDispatch, FideoThunkResult } from '../state.props'
import { TextBlock } from './text-blocks.props'

export const ADD_TEXT_BLOCKS = 'ADD_TEXT_BLOCKS'

const addTextBlocks = (textBlocks: TextBlock[]) => {
    return {
        type: ADD_TEXT_BLOCKS,
        textBlocks,
    }
}

export const fetchTextBlocks =
    (): FideoThunkResult<Promise<any>> => (dispatch: FideoThunkDispatch) =>
        fetch(`${getApiUrl()}/text-blocks`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(handleFetchError)
            .then((json) => dispatch(addTextBlocks(json.data)))
            .catch((error) => Promise.reject(error))
