import React, { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useFideoDispatch } from '../hooks/use-fideo-dispatch'
import { setPageIs404 } from '../store/layout/layout.actions'

const PageNotFoundView: FC = () => {
    const dispatch = useFideoDispatch()

    useEffect(() => {
        dispatch(setPageIs404(true))

        return () => {
            dispatch(setPageIs404(false))
        }
    }, [])

    // const reload = (ev: MouseEvent) => {
    //     // eslint-disable-next-line no-restricted-globals
    //     location.reload()
    //     ev.preventDefault()
    // }

    return (
        <div className="page page--404">
            <div className="page-wrapper">
                <h1 className="title-black">Seite nicht gefunden (404)</h1>
                <p>Die aufgerufene Seite wurde nicht gefunden.</p>
                {/* <a onClick={reload} href="#">
                        {' '}
                        probiere es erneut
                    </a> */}
                <Link to="/" title="Zur Startseite" className="link-blue">
                    Zur Startseite
                </Link>
            </div>
        </div>
    )
}

export default PageNotFoundView
