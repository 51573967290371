import React, { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, RouteProps, Routes, useLocation } from 'react-router-dom'

import Loading from './components/loading'
// import {TransitionGroup,CSSTransition} from 'react-transition-group'
import PageNotFoundView from './routes/404'
import Authentication from './routes/authentication'
import BlogDetail from './routes/blog/blog-detail'
import BlogOverview from './routes/blog/blog-overview'
import ChatGroupAdd from './routes/chat/chat-group-add'
import ChatGroupEdit from './routes/chat/chat-group-edit'
import ChatPostEdit from './routes/chat/chat-post-edit'
import ChatThreadNew from './routes/chat/chat-thread-add'
import ChatThreadDetail from './routes/chat/chat-thread-detail'
import ChatThreadEdit from './routes/chat/chat-thread-edit'
import ChatTopicDetail from './routes/chat/chat-topic-detail'
import ChatTopicOverview from './routes/chat/chat-topic-overview'
import ContactForm from './routes/contact-form'
import ContentPage from './routes/content-page'
import EmergencyKit from './routes/emergency-kit'
import EmergencyKitNew from './routes/emergency-kit/emergency-kit-new'
import FaqQuestionDetail from './routes/faq/faq-question-detail'
import FaqTopicDetail from './routes/faq/faq-topic-detail'
import FaqTopicOverview from './routes/faq/faq-topic-overview'
import FlyerForm from './routes/flyer-form'
import Home from './routes/home'
import PrivateChatThread from './routes/private-chat-thread'
import Profile from './routes/profile'
import SchoolboxForm from './routes/schoolbox-form'
import Search from './routes/search'
import SelfTest from './routes/self-test'
import Settings from './routes/settings'
import ResetPassword from './routes/settings/reset-password'
import SettingsContactDetails from './routes/settings/settings-contact-details'
import SettingsPassword from './routes/settings/settings-password'
import SettingsPhoneNbr from './routes/settings/settings-phone-nbr'
import UserChatGroups from './routes/user-chat-groups'
import UserChatThreads from './routes/user-chat-threads'
import Weiteres from './routes/weiteres'
import { existsMenuPageBySlug, getMenusIsPending } from './store/menu-pages/menu-pages.selectors'
import { GlobalState } from './store/state.props'
import { LoginStatus } from './store/user/user.props'
import { getSessionUserLoginStatus } from './store/user/user.selectors'

/**
 * @todo It would have been nicer to extract the path-name into a const each and
 * make it available via fn. E.g. CONST CHAT_TOPIC_DETAIL = '/chat/thema/:id'
 * and getChatTopicDetailPath(id: string) => CHAT_TOPIC_DETAIL.replace(':id', id)
 *
 * Disabled page-transitions feature as requested in #1923
 */
const AppRouter = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/seiten/:id" element={<ContentPage />} />
        <Route path="/chat" element={<ChatTopicOverview />} />
        <Route path="/chat/thema/:id" element={<ChatTopicDetail />} />
        <Route
            path="/chat/beitrag/hinzufuegen"
            element={
                <PrivateRoute>
                    <ChatThreadNew />
                </PrivateRoute>
            }
        />
        <Route path="/chat/beitrag/:id" element={<ChatThreadDetail />} />
        <Route
            path="/chat/beitrag/:id/bearbeiten"
            element={
                <PrivateRoute>
                    <ChatThreadEdit />
                </PrivateRoute>
            }
        />
        <Route
            path="/chat/reaktion/:id/bearbeiten"
            element={
                <PrivateRoute>
                    <ChatPostEdit />
                </PrivateRoute>
            }
        />
        <Route
            path="/chat/gruppe/:id/bearbeiten"
            element={
                <PrivateRoute>
                    <ChatGroupEdit />
                </PrivateRoute>
            }
        />
        <Route
            path="/chat/gruppe/hinzufuegen"
            element={
                <PrivateRoute>
                    <ChatGroupAdd />
                </PrivateRoute>
            }
        />
        <Route path="/antworten" element={<FaqTopicOverview />} />
        <Route path="/antworten/thema/:slug" element={<FaqTopicDetail />} />
        <Route path="/antworten/frage/:slug" element={<FaqQuestionDetail />} />
        <Route path="/aktuelles" element={<BlogOverview />} />
        <Route path="/aktuelles/:slug" element={<BlogDetail />} />
        <Route
            path="/profil"
            element={
                <PrivateRoute>
                    <Profile />
                </PrivateRoute>
            }
        />
        <Route
            path="/profil/beitraege"
            element={
                <PrivateRoute>
                    <UserChatThreads />
                </PrivateRoute>
            }
        />
        <Route
            path="/profil/gruppenchats"
            element={
                <PrivateRoute>
                    <UserChatGroups />
                </PrivateRoute>
            }
        />
        <Route
            path="/einstellungen"
            element={
                <PrivateRoute>
                    <Settings />
                </PrivateRoute>
            }
        />
        <Route
            path="/einstellungen/telefonnummer"
            element={
                <PrivateRoute>
                    <SettingsPhoneNbr />
                </PrivateRoute>
            }
        />
        <Route
            path="/einstellungen/kontaktdetails"
            element={
                <PrivateRoute>
                    <SettingsContactDetails />
                </PrivateRoute>
            }
        />
        <Route
            path="/einstellungen/passwort"
            element={
                <PrivateRoute>
                    <SettingsPassword />
                </PrivateRoute>
            }
        />
        <Route
            path="/notfallkoffer"
            element={
                <PrivateRoute>
                    <EmergencyKit />
                </PrivateRoute>
            }
        />
        <Route
            path="/notfallkoffer/bearbeiten"
            element={
                <PrivateRoute>
                    <EmergencyKit />
                </PrivateRoute>
            }
        />
        <Route
            path="/notfallkoffer/hinzufuegen"
            element={
                <PrivateRoute>
                    <EmergencyKitNew />
                </PrivateRoute>
            }
        />
        <Route
            path="/private-nachrichten"
            element={
                <PrivateRoute>
                    <PrivateChatThread />
                </PrivateRoute>
            }
        />
        <Route path="/passwort-vergessen" element={<ResetPassword />} />
        <Route path="/selbsttest" element={<SelfTest />} />
        <Route path="/auth" element={<Authentication />} />
        <Route path="/suche" element={<Search />} />
        <Route path="/weiteres" element={<Weiteres />} />
        <Route path="/kontakt" element={<ContactForm />} />
        <Route path="/flyer-bestellen" element={<FlyerForm />} />
        <Route path="/schule/bestellung-schulbox" element={<SchoolboxForm />} />
        <Route path="*" element={<AsyncRoute />} />
    </Routes>
)

const LoadingScreen: FC = () => (
    <div className="page">
        <Loading />
    </div>
)

const AsyncRoute: FC = () => {
    const location = useLocation()
    const isPending = useSelector(getMenusIsPending)
    const pageExists = useSelector((state: GlobalState) =>
        existsMenuPageBySlug(state, location?.pathname || '')
    )

    return isPending ? <LoadingScreen /> : pageExists ? <ContentPage /> : <PageNotFoundView />
}

/**
 * A wrapper around <Route> that waits for the inital
 * app user login to happen (if sessionStorage.token !== null),
 * then redirects either to login-screen or requested FC
 *
 * @see UserSessionHandler
 */
const PrivateRoute: FC<RouteProps> = ({ children }) => {
    const userLoginStatus = useSelector(getSessionUserLoginStatus)

    return userLoginStatus === LoginStatus.PENDING ? (
        <LoadingScreen />
    ) : userLoginStatus === LoginStatus.LOGGED_IN ? (
        (children as ReactElement)
    ) : (
        <Navigate to="/auth" />
    )
}

export default AppRouter
