import React, { FC, MouseEvent, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import UnreadNotification from '../../components/unread-notification'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSessionUser from '../../hooks/use-session-user'
import { fetchPrivateChatThread } from '../../store/chat-threads/chat-threads.actions'
import { getPrivateChatThread } from '../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../store/state.props'
import { TextBlockCode } from '../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../store/text-blocks/text-blocks.selectors'
import { logoutUser } from '../../store/user/user.actions'
import { getUnsanitizedPageText } from '../../utils/configs'
import { SLUG_AGB } from '../../utils/pages'

const Profile: FC = () => {
    const dispatch = useFideoDispatch()
    const navigate = useNavigate()
    const sessionUser = useSessionUser()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_PROFILE)
    )
    const privateChatThread = useSelector(getPrivateChatThread, shallowEqual)

    useEffect(() => {
        // Regularly check whether a private chat thread (still) exists.
        dispatch(fetchPrivateChatThread()).catch((err) => {})
    }, [])

    const onClickLogout = (ev: MouseEvent) => {
        ev.preventDefault()

        if (!window.confirm(`Möchtest Du Dich wirklich ausloggen?`)) {
            return
        }

        dispatch(logoutUser())
            .then(() => {
                navigate('/')
            })
            .catch(() => {
                alert(
                    `Logout nicht möglich. Stelle sicher, dass Du mit dem Internet verbunden bist und probiere es erneut.`
                )
            })
    }

    if (sessionUser === undefined) {
        return null
    }

    return (
        <div className="page page--profile">
            <div className="page-header">
                <p className="title-black">Hallo {sessionUser?.username}</p>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>

                <div className="page--profile__numbers">
                    <h2 className="">Kinder- und Jugendtelefon</h2>
                    <p>
                        <a
                            href="https://www.nummergegenkummer.de/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.nummergegenkummer.de
                        </a>
                    </p>
                    <p>
                        <a href="tel:116111" className="link-blue-plain">
                            116 111
                        </a>
                    </p>

                    <h2 className="mt2">Telefonseelsorge</h2>
                    <p>
                        <a
                            href="https://www.telefonseelsorge.de/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.telefonseelsorge.de
                        </a>
                    </p>
                    <p>
                        <a href="tel:08001110111" className="link-blue-plain">
                            0800 111 0111
                        </a>
                        <span className="h3 h-p"> oder</span>
                        <br />
                        <a href="tel:08001110222" className="link-blue-plain">
                            0800 111 0222
                        </a>
                        <span className="h3 h-p"> oder</span>
                        <a href="tel:116123" className="link-blue-plain">
                            116 123
                        </a>
                    </p>
                </div>
            </div>

            <div className="flex-container-list">
                {privateChatThread && (
                    <div className="flex-item-list">
                        <Link to="/private-nachrichten">
                            <h2>Nachrichten von FIDEO</h2>
                            <UnreadNotification displayNumFor={'private'} />
                            <i className="flex-item-list--arrow"></i>
                        </Link>
                    </div>
                )}
                <div className="flex-item-list">
                    <Link to="/notfallkoffer">
                        <h2>Notfallkoffer</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/profil/beitraege">
                        <h2>Eigene Beiträge &amp; Reaktionen</h2>
                        <UnreadNotification displayInvert={true} displayNumFor={'public'} />
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/profil/gruppenchats">
                        <h2>Gruppenchats</h2>
                        <UnreadNotification displayInvert={true} displayNumFor={'group'} />
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/einstellungen">
                        <h2>Einstellungen</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <Link to="/kontakt">
                        <h2>Kontakt</h2>
                        <i className="flex-item-list--arrow"></i>
                    </Link>
                </div>
                <div className="flex-item-list">
                    <a href={`https://fideo.de${SLUG_AGB}`}>
                        <h2>Nutzungsbedingungen</h2>
                        <i className="flex-item-list--arrow"></i>
                    </a>
                </div>
                <div className="flex-item-list">
                    <a href="#" onClick={onClickLogout} title="Aus Fideo ausloggen">
                        <h2>Ausloggen</h2>
                        <i className="flex-item-list--arrow"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Profile
