import React, { FC } from 'react'

import FaqTopicListItem from '../faq-topic-list-item'
import { FaqTopicListProps } from './faq-topic-list.props'

const FaqTopicList: FC<FaqTopicListProps> = (props) => (
    <div className="component--faq-topic-list">
        {props.topics?.map((topic, key) => (
            <FaqTopicListItem topic={topic} key={topic.id} />
        ))}
    </div>
)

export default FaqTopicList
