import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import ChatbotTextInput from '../../components/chatbot/chatbot-text-input'
import Loading from '../../components/loading'
import { useFideoDispatch } from '../../hooks/use-fideo-dispatch'
import useSessionUser from '../../hooks/use-session-user'
import useSetParentLink from '../../hooks/use-set-parent-link'
import { fetchPrivateChatPosts, postChatPost } from '../../store/chat-posts/chat-posts.actions'
import { ChatPost } from '../../store/chat-posts/chat-posts.props'
import { getChatPostsByThreadId } from '../../store/chat-posts/chat-posts.selectors'
import {
    deleteChatNews,
    fetchPrivateChatThread,
} from '../../store/chat-threads/chat-threads.actions'
import { ChatThread } from '../../store/chat-threads/chat-threads.props'
import { getPrivateChatThread } from '../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../store/state.props'
import { User } from '../../store/user/user.props'
import { calendarFormat } from '../../utils/format'

const FETCH_POSTS_INTERVAL = 30000
// @todo Pagination not yet implemented

const PrivateChatThread: FC = () => {
    useSetParentLink('/profil')
    const dispatch = useFideoDispatch()
    const chatThread = useSelector(getPrivateChatThread, shallowEqual)
    const chatPosts = useSelector(
        (state: GlobalState) => getChatPostsByThreadId(state, chatThread?.id),
        shallowEqual
    )
    const [hasPrivateThread, setHasPrivateThread] = useState<boolean>(true)

    useEffect(() => {
        if (!chatThread) {
            dispatch(fetchPrivateChatThread()).then((data) => {
                if (data.data.length === 0) {
                    setHasPrivateThread(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        let interval = 0

        if (chatThread) {
            dispatch(fetchPrivateChatPosts(chatThread.id))

            interval = window.setInterval(() => {
                dispatch(fetchPrivateChatPosts(chatThread.id))
            }, FETCH_POSTS_INTERVAL)
        }

        return () => {
            window.clearInterval(interval)
        }
    }, [chatThread])

    useEffect(() => {
        // Mark private thread as read
        if (chatThread) {
            dispatch(deleteChatNews(chatThread.id))
        }
    }, [chatPosts])

    const submitAction = (text: string) => {
        if (!chatThread) {
            return
        }

        dispatch(
            postChatPost({
                chatThreadId: Number(chatThread.id),
                text,
            })
        ).catch(() => {
            alert(
                `Fehler beim absenden. Bitte stelle sicher, dass Du eine Verbindung zum Internet hast und probiere es erneut.`
            )
        })
    }

    return (
        <div className="page page--private-chat page--profile page--chat-thread-detail">
            <div className="page-header">
                <p className="title-black">Nachrichten von Fideo</p>
            </div>

            {!chatThread && hasPrivateThread && <Loading />}

            {!hasPrivateThread && (
                <p style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    Du hast noch keine privaten Nachrichten von Fideo erhalten.
                </p>
            )}

            {chatThread && (
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    {chatThread && chatPosts && (
                        <PrivateChatItemsList items={[chatThread, ...chatPosts]} />
                    )}
                    {chatThread && <ChatbotTextInput submitAction={submitAction} />}
                </div>
            )}
        </div>
    )
}

interface IPrivateChatItemsList {
    items: any[] // Actually ChatThread[]|ChatPost[]
}

const PrivateChatItemsList: FC<IPrivateChatItemsList> = ({ items }) => {
    const sessionUser = useSessionUser()

    return (
        <div className="component--chatbot-dialogue-list">
            {items.map((item) => (
                <PrivateChatItem item={item} key={item.id} sessionUser={sessionUser} />
            ))}
        </div>
    )
}

interface IPrivateChatItem {
    item: ChatThread | ChatPost
    sessionUser: User | undefined
}

const PrivateChatItem: FC<IPrivateChatItem> = ({ item, sessionUser }) => (
    <div className="component--chatbot-dialogue-list-item">
        <div
            className="list-item-content"
            data-dialogue-type={item.creatorName !== sessionUser?.username ? 'left' : 'center'}
        >
            {item.text}
            {item.createdAt && item.createdAt !== '' && (
                <p className="meta-text">
                    {moment(item.createdAt).calendar(undefined, calendarFormat)}
                </p>
            )}
        </div>
    </div>
)

export default PrivateChatThread
