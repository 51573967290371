import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import ChatThreadFormSuccess from '../../../components/chat/chat-thread-form-success'
import Loading from '../../../components/loading'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import useFideoNavigate from '../../../hooks/use-fideo-navigate'
import useSessionUser from '../../../hooks/use-session-user'
import useSetParentLink from '../../../hooks/use-set-parent-link'
import { fetchChatPost, patchChatPost } from '../../../store/chat-posts/chat-posts.actions'
import { getChatPostById } from '../../../store/chat-posts/chat-posts.selectors'
import { getChatThreadById } from '../../../store/chat-threads/chat-threads.selectors'
import { GlobalState } from '../../../store/state.props'
import { TextBlock, TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { APIError } from '../../../utils/api'
import { getUnsanitizedPageText } from '../../../utils/configs'
import { scrollToTop } from '../../../utils/pages'
import { ChatPostEditFormParams } from './chatp-post-edit-form.props'

/**
 * @route /chat/reaktion/:id/bearbeiten
 */
const ChatPostEditForm: FC = () => {
    useSetParentLink('/chat')
    const { id } = useParams<ChatPostEditFormParams>()
    const { gotoPrevPage } = useFideoNavigate()
    const sessionUser = useSessionUser()
    const navigate = useNavigate()
    const [formState, setFormState] = useState<string>('')
    const [text, setText] = useState<string>('')

    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_CHAT_POST_EDIT)
    )
    const textBlockSuccess = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_CHAT_POST_EDIT_SUCCESS)
    )
    const chatPost = useSelector((state: GlobalState) => getChatPostById(state, Number(id)))
    const chatThread = useSelector((state: GlobalState) =>
        getChatThreadById(state, Number(chatPost?.chatThreadId))
    )
    const linkTarget = `/chat/beitrag/${chatPost?.chatThreadId}#post-${chatPost?.id}`

    useEffect(() => {
        if (!chatPost) {
            dispatch(fetchChatPost(Number(id))).catch(() => {
                // 404 or no permission
                navigate('/chat')
            })
        } else {
            setText(chatPost.text)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatPost])

    if (!chatPost || !sessionUser) {
        return (
            <div className="page page--chat-thread-new">
                <Loading />
            </div>
        )
    } else if (chatPost.creatorName !== sessionUser.username) {
        navigate('/chat')
        return null
    }

    // Force black-coloured back-button
    document.body.setAttribute('data-page', 'chat-thread-add')

    const onSubmit = (ev: MouseEvent) => {
        if (text.trim() === '') {
            setFormState(`Bitte fülle alle Felder aus.`)
        } else {
            dispatch(
                patchChatPost(Number(chatPost.id), {
                    text: text.trim(),
                })
            )
                .then(() => {
                    setFormState('__DONE__')
                    scrollToTop()
                })
                .catch((error: APIError) => {
                    setFormState(
                        `Reaktion konnte nicht editiert werden. Besteht eine Netzwerkverbindung?`
                    )
                })
        }

        ev.preventDefault()
    }

    const onChangeText = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setText(ev.currentTarget.value)
    }

    if (formState === '__DONE__') {
        return (
            <div className="page page--chat-thread-new">
                <ChatThreadFormSuccess
                    textBlock={textBlockSuccess as TextBlock}
                    linkTarget={linkTarget}
                    linkText={`Zum Beitrag`}
                />
            </div>
        )
    }

    return (
        <div className="page page--chat-thread-new">
            <div className="component--chat-thread-add-form page-header">
                <h1 className="title-black">Reaktion bearbeiten</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
                <br />

                <form className="chat-thread-form" action="#" method="POST">
                    <div className="input-wrapper">
                        <label className="label-hashtag-link" htmlFor="chat-thread-form__title">
                            Beitrag
                        </label>
                        <p>
                            <strong>
                                {chatPost.chatThread
                                    ? chatPost.chatThread?.title
                                    : chatThread
                                    ? chatThread.title
                                    : ''}
                            </strong>
                        </p>
                    </div>

                    <div className="input-wrapper">
                        <label className="label-hashtag-link" htmlFor="chat-thread-form__text">
                            Deine Reaktion
                        </label>
                        <textarea
                            id="chat-thread-form__text"
                            name="chat-thread-form__text"
                            value={text}
                            onChange={onChangeText}
                        ></textarea>
                    </div>

                    {formState !== '' && <p className="form-error input-wrapper">{formState}</p>}

                    <div className="form__buttons">
                        <a
                            href="#"
                            className="button button-secondary"
                            title="Formular verlassen"
                            onClick={gotoPrevPage}
                        >
                            Abbrechen
                        </a>

                        <button type="submit" onClick={onSubmit} className="button button-submit">
                            Senden
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChatPostEditForm
