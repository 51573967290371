import { AnyAction } from 'redux'

import {
    ADD_FAQ_TOP_QUESTIONS,
    FETCH_FAQ_TOP_QUESTIONS_PENDING,
    RESET_FAQ_TOP_QUESTIONS,
    UPDATE_FAQ_TOP_QUESTIONS_PAGINATION,
} from './faq-top-questions.actions'
import { FaqTopQuestionsState } from './faq-top-questions.props'

const initialState: FaqTopQuestionsState = {
    data: [],
    isPending: false,
    pagination: {
        nextPage: 0,
    },
}

const FaqTopQuestionsReducer = (
    state: FaqTopQuestionsState = initialState,
    action: AnyAction
): FaqTopQuestionsState => {
    switch (action.type) {
        case FETCH_FAQ_TOP_QUESTIONS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case ADD_FAQ_TOP_QUESTIONS:
            return {
                ...state,
                isPending: false,
                data: [...state.data, ...action.faqTopQuestions.data],
            }
        case RESET_FAQ_TOP_QUESTIONS:
            return {
                ...state,
                isPending: false,
                data: action.faqTopQuestions.data,
            }
        case UPDATE_FAQ_TOP_QUESTIONS_PAGINATION:
            return {
                ...state,
                pagination: {
                    // nextPage may be undefined!
                    nextPage: action.pagination.next,
                },
            }
    }

    return state
}

export default FaqTopQuestionsReducer
