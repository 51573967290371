import cn from 'classnames'
import React, { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { isValidPassword } from '../../../components/chatbot/chatbot-dialogues/chatbot-authentication-dialogues'
import { useFideoDispatch } from '../../../hooks/use-fideo-dispatch'
import { GlobalState } from '../../../store/state.props'
import { TextBlockCode } from '../../../store/text-blocks/text-blocks.props'
import { getTextBlockByPageCode } from '../../../store/text-blocks/text-blocks.selectors'
import { editUserPassword } from '../../../store/user/user.actions'
import { defaultInputFieldAttributes, getUnsanitizedPageText } from '../../../utils/configs'

const SettingsContactDetails: FC = () => {
    const dispatch = useFideoDispatch()
    const textBlock = useSelector((state: GlobalState) =>
        getTextBlockByPageCode(state, TextBlockCode.INTRO_SETTINGS_PASSWORD)
    )
    const [formState, setFormState] = useState<string>('')
    const [passwordCurrent, setPasswordCurrent] = useState<string>('')
    const [passwordNew1, setPasswordNew1] = useState<string>('')
    const [passwordNew2, setPasswordNew2] = useState<string>('')

    const onChangePasswordCurrent = (ev: ChangeEvent<HTMLInputElement>) => {
        setPasswordCurrent(ev.currentTarget.value)
    }

    const onChangePasswordNew1 = (ev: ChangeEvent<HTMLInputElement>) => {
        setPasswordNew1(ev.currentTarget.value)
    }

    const onChangePasswordNew2 = (ev: ChangeEvent<HTMLInputElement>) => {
        setPasswordNew2(ev.currentTarget.value)
    }

    const onSubmit = (ev: MouseEvent) => {
        dispatch(editUserPassword(passwordCurrent, passwordNew1))
            .then(() => {
                setFormState('Passwort erfolgreich geändert!')
                setPasswordCurrent('')
                setPasswordNew1('')
                setPasswordNew2('')
            })
            .catch(() => {
                setFormState('Es ist ein Fehler aufgetreten. Ist das aktuelle Passwort gültig?')
            })
        ev.preventDefault()
    }

    return (
        <div className="page page--settings-phone-nbr">
            <div className="page-header">
                <h1 className="title-black">Passwort ändern</h1>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={getUnsanitizedPageText(textBlock?.text)}
                ></p>
            </div>

            <form className="password-form page-wrapper" method="POST" action="#" noValidate={true}>
                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="password-form__password_current">
                        Aktuelles Passwort
                    </label>
                    <input
                        type="password"
                        id="password-form__password_current"
                        name="password-form__password_current"
                        required
                        placeholder="******"
                        value={passwordCurrent}
                        onChange={onChangePasswordCurrent}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="password-form__password1">
                        Neues Passwort
                    </label>
                    <input
                        type="password"
                        id="password-form__password1"
                        name="password-form__password1"
                        required
                        placeholder="******"
                        value={passwordNew1}
                        onChange={onChangePasswordNew1}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                <div className="input-wrapper">
                    <label className="label-hashtag-link" htmlFor="password-form__password2">
                        Neues Passwort (Bestätigen)
                    </label>
                    <input
                        type="password"
                        id="password-form__password2"
                        name="password-form__password2"
                        required
                        placeholder="******"
                        value={passwordNew2}
                        onChange={onChangePasswordNew2}
                        {...defaultInputFieldAttributes}
                    />
                </div>

                {formState !== '' && (
                    <p
                        className={cn(
                            'form-error input-wrapper',
                            formState.indexOf('erfolgreich') >= 0 ? 'form-error--success' : null
                        )}
                    >
                        {formState}
                    </p>
                )}

                <div className="form__buttons">
                    <Link to="/einstellungen" className="button button-secondary">
                        Abbrechen
                    </Link>

                    <button
                        type="submit"
                        onClick={onSubmit}
                        disabled={
                            !(
                                passwordNew1 !== '' &&
                                passwordCurrent !== '' &&
                                passwordNew1 === passwordNew2 &&
                                isValidPassword(passwordNew1)
                            )
                        }
                        className="button button-submit"
                    >
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SettingsContactDetails
